export const useQuery = () =>
  // eslint-disable-next-line no-undef
  new URLSearchParams(location.search)

export const useQueryParams = () => {
  // eslint-disable-next-line no-undef
  const p = new URLSearchParams(location.search)
  const result = {}
  for (const param of p) {
    result[param[0]] = param[1]
  }

  return result
}

export const paramsToQueryString = inObj => {
  const qs = new URLSearchParams()
  // eslint-disable-next-line no-negated-condition
  Object.entries(inObj).forEach(([key, value]) => (value !== undefined ? qs.append(key, value) : null))
  return '?' + qs.toString()
}
