export const humanFileSize = bytes => {
  const list = ['B', 'kB', 'MB', 'GB']
  const factor = 1 / 1024
  let unit = list.shift()

  while (list.length > 0 && bytes * factor >= 1) {
    bytes *= factor
    unit = list.shift()
  }

  return `${bytes.toFixed(0)}${unit}`
}

export const objectToQuerystring = object => {
  let querystring = ''
  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const value = object[key]
      if (value !== undefined && value !== null) {
        querystring && (querystring += '&') // eslint-disable-line no-unused-expressions
        querystring += encodeURIComponent(key) + '=' + encodeURIComponent(value)
      }
    }
  }

  return querystring
}

export const generateInitials = name => {
  const arrayize = `${name || ''}`.trim().split(' ')
  return arrayize.length === 1
    ? `${arrayize[0].charAt(0)}${arrayize[0].charAt(1)}`.toUpperCase()
    : `${arrayize[0].charAt(0)}${arrayize[arrayize.length - 1].charAt(0)}`.toUpperCase()
}

export const generateDummyAvatarUrl = name => {
  if (!name) return null

  const initials = generateInitials(name)

  return `https://dummyimage.com/400x400/207AAB/ffffff.png&text=${initials}`
}

const toCamel = str => {
  return str.replace(/([-_][a-z\d])/gi, $1 => {
    return $1.toUpperCase().replace('-', '').replace('_', '')
  })
}

const camelToSnakeCase = str => str.replace(/[A-Z\d]/g, letter => `_${letter.toLowerCase()}`)

const isObject = obj => {
  return obj === new Object(obj) && !Array.isArray(obj) && typeof obj !== 'function' // eslint-disable-line no-new-object
}

export const keysToCamel = obj => {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach(k => {
      n[toCamel(k)] = keysToCamel(obj[k])
    })

    return n
  }

  if (Array.isArray(obj)) {
    return obj.map(i => {
      return keysToCamel(i)
    })
  }

  return obj
}

export const keysToSnake = obj => {
  if (isObject(obj)) {
    const n = {}

    Object.keys(obj).forEach(k => {
      n[camelToSnakeCase(k)] = obj[k]
    })

    return n
  }

  if (Array.isArray(obj)) {
    return obj.map(i => {
      return camelToSnakeCase(i)
    })
  }

  return obj
}

export const generateFormData = values => {
  if (!values) return null

  const snakeCaseValues = keysToSnake(values)

  // eslint-disable-next-line no-undef
  const formData = new FormData()
  Object.keys(snakeCaseValues).forEach(key => {
    if (snakeCaseValues[key]) {
      formData.append(key, snakeCaseValues[key])
    }
  })

  return formData
}
