const axios = require('axios')
import config from '../config'
import * as util from './util'

const callApi = async (url, method, body, headers = {}) => {
  // eslint-disable-next-line no-undef
  const thankfullyContext = JSON.parse(localStorage.getItem('thankfully:context'))

  const accessToken = thankfullyContext?.accessToken || null

  const commonHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    ...headers,
  }

  const apiUrl = config.api.host

  if (accessToken) commonHeaders.Authorization = `Bearer ${accessToken}`

  try {
    const {data} = await axios({
      method,
      url: `${apiUrl}${url}`,
      data: body,
      headers: commonHeaders,
    })

    return util.keysToCamel(data)
  } catch (error) {
    if (error?.response?.status === 401) {
      // eslint-disable-next-line no-undef
      location.assign('/invalid-user-session')
      throw {
        status: error?.response?.status,
        errors: [{code: 'invalid_login_details', detail: 'Invalid user session'}],
      }
    } else
      throw {
        status: error?.response?.status,
        errors: error?.response?.data?.errors || [],
      }
  }
}

export async function signIn(body) {
  return callApi('/sign-in', 'post', body)
}

export async function signWithFacebook() {
  return callApi('/users/auth/facebook', 'post', {})
}

export async function signUp(body) {
  return callApi('/sign-up', 'post', body)
}

export async function signOut(body) {
  return callApi('/sign-out', 'delete', body)
}

export async function sendPasswordReset(body) {
  return callApi('/password', 'post', body)
}

export async function changePassword(body) {
  return callApi('/password', 'put', body)
}

export async function meGet() {
  return callApi('/users/profile', 'get', {})
}

export async function mePost(body) {
  return callApi('/users/profile', 'post', body)
}

export async function walletsGet() {
  return callApi('/users/wallets', 'get', {})
}

export async function walletCreate(body) {
  return callApi('/users/wallets', 'post', util.keysToSnake(body))
}

export async function walletDelete(walletBrand) {
  return callApi(`/users/wallets/${walletBrand}`, 'delete', {})
}

export async function networksGet({page, getMutualFriends, friends}) {
  const query = util.keysToSnake({page, getMutualFriends, friends})
  return callApi(`/users/network/?${util.objectToQuerystring(query)}`, 'get', {})
}

export async function networkGetProfile(userId) {
  return callApi(`/users/network/${userId}?get_mutual_friends=true`, 'get', {})
}

export async function createFriendRequest(body) {
  return callApi(`/users/friend_requests`, 'post', util.keysToSnake(body))
}

export async function getFriendRequests() {
  return callApi('/users/friend_requests/pending_received', 'get', {})
}

export async function acceptFriendRequest(body, id) {
  return callApi(`/users/friend_requests/accept/${id}`, 'put', util.keysToSnake(body))
}

export async function ignoreFriendRequest(body, id) {
  return callApi(`/users/friend_requests/pending_received/${id}`, 'delete', util.keysToSnake(body))
}

export async function getFriendRequestsSent() {
  return callApi('/users/friend_requests/pending_sent', 'get', {})
}

export async function cancelFriendRequestSent(body, id) {
  return callApi(`/users/friend_requests/pending_sent/${id}`, 'delete', util.keysToSnake(body))
}

export async function initializePost(formData) {
  return callApi('/users/posts/initialize_post', 'post', formData)
}

export async function finalizePost({transactionId, orderId}) {
  const query = util.keysToSnake({transactionId, orderId})
  return callApi(`/users/posts/finalize_post?${util.objectToQuerystring(query)}`, 'get', {})
}

export async function postsGet() {
  return callApi('/users/posts', 'get', {})
}

export async function postLike(postId) {
  return callApi(`/posts/${postId}/likes`, 'post', {})
}

export async function postUnlike(postId, likeId) {
  return callApi(`/posts/${postId}/likes/${likeId}`, 'delete', {})
}

export async function commentsGet(postId) {
  return callApi(`/posts/${postId}/comments`, 'get')
}

export async function commentCreate(postId, body) {
  return callApi(`/posts/${postId}/comments`, 'post', util.keysToSnake(body))
}

export async function getTransactionJournals() {
  return callApi('/users/transaction_journals', 'get', {})
}
