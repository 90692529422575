import React from 'react'

const Search = (
  {placeholder, onChange, onIndexChange, onLostFocus, onEnter, value, isOpen, controlName, inputType},
  sRef
) => {
  const ref = React.useRef(null)
  const focusSearchInput = React.useCallback(() => {
    if (ref.current) {
      ref.current.focus({
        preventScroll: true,
      })
    }
  }, [ref])

  React.useEffect(() => {
    if (isOpen) focusSearchInput()
  }, [isOpen])

  React.useImperativeHandle(sRef, () => ({
    focus: () => {
      focusSearchInput()
    },
  }))

  React.useLayoutEffect(() => {
    focusSearchInput()
  }, [focusSearchInput])

  return (
    <div className="flex justify-between items-center mb-2">
      <input
        ref={ref}
        type={inputType}
        placeholder={placeholder}
        className="focus:outline-none w-full text-base rounded-md border border-gray-600 h-6 px-2 shadow-sm mr-1"
        value={value}
        onChange={onChange}
        onKeyDown={e => {
          switch (e.key) {
            case 'ArrowUp':
              e.preventDefault()
              onIndexChange(-1)
              break
            case 'ArrowDown':
              e.preventDefault()
              onIndexChange(1)
              break
            case 'Enter':
              e.preventDefault()
              onEnter()
              break
            default:
              break
          }
        }}
        onBlur={onLostFocus}
      />
      <span className="text-sm text-gray-600">
        <i className="fas fa-search" />
      </span>
    </div>
  )
}

export default React.forwardRef(Search)
