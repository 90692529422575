export const login = {
  status: 200,
  data: {
    accessToken: 'access_token_123123',
    user: {
      id: '123',
      firstName: 'Juana',
      lastName: 'Dela Cruz',
      avatarUrl: null,
      updatedAt: '2021-06-08T14:02:27.071Z',
    },
  },
}

export const userNotification = {
  notification: [
    {
      id: 1,
      user: 'Patrick Pascual',
      avatarUrl: 'https://dummyimage.com/400x400/B68E79/ffffff.png&text=PP',
      time: 'a few seconds ago',
      message: 'accepted your friend request.',
      type: 'fr',
      url: '',
    },
    {
      id: 2,
      user: 'Nosey Dosey',
      avatarUrl: 'https://dummyimage.com/400x400/A031CE/ffffff.png&text=ND',
      time: '1 day ago',
      message: 'commented on your shout-out.',
      type: 'comment',
      url: '',
    },
    {
      id: 3,
      user: 'Betty Niggabust',
      avatarUrl: 'https://dummyimage.com/400x400/8296C8/ffffff.png&text=BN',
      time: '1 day ago',
      message: 'liked your shout-out.',
      type: 'like',
      url: '',
    },
    {
      id: 4,
      user: 'Pripots Gideon',
      avatarUrl: 'https://dummyimage.com/400x400/984952/ffffff.png&text=PG',
      time: '1 day ago',
      message: 'sent you 50PHP with a shout-out',
      type: 'coin',
      amount: 50,
      currency: 'PHP',
      url: '',
    },
  ],
}

export const peopleYouMayKnow = {
  status: 200,
  data: {
    users: [
      {
        id: '1',
        firstName: 'Juan',
        lastName: 'Dela Cruz',
        avatarUrl: 'https://dummyimage.com/400x400/8296C8/ffffff.png&text=JD',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 8,
      },
      {
        id: '2',
        firstName: 'Kim',
        lastName: 'Santiago',
        avatarUrl: 'https://dummyimage.com/400x400/A031CE/ffffff.png&text=KM',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 4,
      },
      {
        id: '3',
        firstName: 'John Doe',
        lastName: 'Smith',
        avatarUrl: 'https://dummyimage.com/400x400/8296C8/ffffff.png&text=JD',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 2,
      },
      {
        id: '4',
        firstName: 'Mark',
        lastName: 'Gates',
        avatarUrl: 'https://dummyimage.com/400x400/984952/ffffff.png&text=MG',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 1,
      },
      {
        id: '5',
        firstName: 'Noel',
        lastName: 'Edison',
        avatarUrl: 'https://dummyimage.com/400x400/B68E79/ffffff.png&text=NE',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 12,
      },
      {
        id: '6',
        firstName: 'Joni',
        lastName: 'Mitchell',
        avatarUrl: 'https://dummyimage.com/400x400/2CAE43/ffffff.png&text=JM',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 5,
      },
      {
        id: '7',
        firstName: 'James',
        lastName: 'Michael',
        avatarUrl: 'https://dummyimage.com/400x400/A031CE/ffffff.png&text=JM',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 5,
      },
      {
        id: '8',
        firstName: 'Rafael',
        lastName: 'Garner',
        avatarUrl: 'https://dummyimage.com/400x400/B68E79/ffffff.png&text=RG',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 5,
      },
      {
        id: '9',
        firstName: 'Casey',
        lastName: 'Turner',
        avatarUrl: 'https://dummyimage.com/400x400/2CAE43/ffffff.png&text=CT',
        updatedAt: '2021-06-08T14:02:27.071Z',
        mutualFriendCount: 5,
      },
    ],
  },
}

export const friends = {
  status: 200,
  data: {
    users: [
      {
        id: '1',
        firstName: 'Juan',
        lastName: 'Dela Cruz',
        avatarUrl: 'https://dummyimage.com/400x400/8296C8/ffffff.png&text=JD',
        userName: 'juanDelaCruz',
        email: 'juanDelaCruz@email.com',
      },
      {
        id: '2',
        firstName: 'Kim',
        lastName: 'Santiago',
        avatarUrl: 'https://dummyimage.com/400x400/A031CE/ffffff.png&text=KM',
        userName: 'KimSantiago',
        email: 'KimSantiago@email.com',
      },
      {
        id: '3',
        firstName: 'John Doe',
        lastName: 'Smith',
        avatarUrl: 'https://dummyimage.com/400x400/8296C8/ffffff.png&text=JD',
        userName: 'johnDoeSmith',
        email: 'johnDoeSmith@email.com',
      },
      {
        id: '4',
        firstName: 'Mark',
        lastName: 'Gates',
        avatarUrl: 'https://dummyimage.com/400x400/984952/ffffff.png&text=MG',
        userName: 'markGates',
        email: 'markGates@email.com',
      },
      {
        id: '5',
        firstName: 'Noel',
        lastName: 'Edison',
        avatarUrl: 'https://dummyimage.com/400x400/B68E79/ffffff.png&text=NE',
        userName: 'noelEdison',
        email: 'noelEdison@email.com',
      },
      {
        id: '6',
        firstName: 'Joni',
        lastName: 'Mitchell',
        avatarUrl: 'https://dummyimage.com/400x400/2CAE43/ffffff.png&text=JM',
        userName: 'joniMitchell',
        email: 'joniMitchell@email.com',
      },
      {
        id: '7',
        firstName: 'James',
        lastName: 'Michael',
        avatarUrl: 'https://dummyimage.com/400x400/A031CE/ffffff.png&text=JM',
        userName: 'JamesMichael',
        email: 'jamesMichael@email.com',
      },
      {
        id: '8',
        firstName: 'Rafael',
        lastName: 'Garner',
        avatarUrl: 'https://dummyimage.com/400x400/B68E79/ffffff.png&text=RG',
        userName: 'rafaelGarner',
        email: 'rafaelGarner@email.com',
      },
      {
        id: '9',
        firstName: 'Casey',
        lastName: 'Turner',
        avatarUrl: 'https://dummyimage.com/400x400/2CAE43/ffffff.png&text=CT',
        userName: 'caseyTurner',
        email: 'caseyTurner@email.com',
      },
    ],
  },
}
