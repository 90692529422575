import React from 'react'
import * as PropTypes from 'prop-types'

const Loader = ({className}) => {
  return (
    <div className={`flex w-full h-full items-center justify-center ${className}`}>
      <i className="text-3xl text-primary fas fa-spinner fa-pulse" />
    </div>
  )
}

Loader.defaultProps = {
  className: '',
}

Loader.propTypes = {
  className: PropTypes.string,
}

export default Loader
