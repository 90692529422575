import React from 'react'
import Tag from './tag'

const TagList = ({list, onRemove, renderValue, isClearable}) => {
  return (
    <div className={`is-flex ${isClearable ? '' : 'pr-2'}`} style={{overflow: 'hidden'}}>
      <div className="ellipsis is-inline-block">
        {list.map((it, idx) => (
          <div key={idx} className="control is-inline-block">
            <Tag idx={idx} item={it} renderValue={renderValue} onRemove={onRemove} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default React.memo(TagList)
