import React from 'react'
import * as PropTypes from 'prop-types'
import LoadingScreen from '../views/landing-pages/loading-page'
import ErrorPage from '../views/landing-pages/error-page'

const PageContainer = ({isFetchingData, className, children, errorStatusCode}) => {
  if (isFetchingData) {
    return <LoadingScreen />
  }

  if (errorStatusCode) {
    return <ErrorPage errorStatusCode={errorStatusCode} />
  }

  return <div className={`flex flex-col h-full bg-gray-100 ${className} pt-20 px-52 overflow-auto `}>{children}</div>
}

PageContainer.defaultProps = {
  className: '',
  errorStatusCode: null,
  isFetchingData: false,
}

PageContainer.propTypes = {
  isFetchingData: PropTypes.bool,
  className: PropTypes.string,
  errorStatusCode: PropTypes.number,
}

export default PageContainer
