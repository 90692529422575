import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller, useWatch} from 'react-hook-form'
import * as constants from '../../../lib/constants'
import Select from '../../elements/select'
import Avatar from '../../elements/avatar'

const AmountSelector = ({control, errors, getValues, setValue}) => {
  const [amountOptions, setAmountOptions] = React.useState(
    new Array(15).fill(null).map((x, i) => ({
      text: `+${(i + 1) * 10}`,
      key: `${(i + 1) * 10}`,
    }))
  )

  const amount = useWatch({
    name: `amount`,
    control,
  })

  // If the added amount is custom, we must append it to amountOptions
  React.useEffect(() => {
    if (amount && !amountOptions.find(a => a.key === amount))
      setAmountOptions([{text: `+${amount}`, key: amount}, ...amountOptions])
  }, [amount])

  const appendPlusSign = value => {
    if (value.includes('+')) return value
    return `+${value}`
  }

  return (
    <Controller
      name="coinAmount"
      control={control}
      defaultValue=""
      render={({field: {onChange, value}}) => (
        <Select
          key={value}
          isCreatable
          isDropdownExpandable={false}
          width="75px"
          inputType="number"
          customContainerClassName={`h-6 border rounded-md px-1 mr-2 ${
            errors?.amount
              ? 'border-red-600 bg-gray-100'
              : `${value ? 'bg-primary text-white' : 'bg-gray-100'} border-primary`
          }`}
          placeholder="+Amount"
          options={amountOptions}
          value={value ? {text: `+${value}`, key: value} : null}
          customRenderCreateContent={option => {
            return <span className="text-sm">{appendPlusSign(option.text)}</span>
          }}
          onCreate={selectedOption => {
            return new Promise(resolve => {
              if (!value) {
                onChange(selectedOption)
                setValue('body', `${getValues('body') || ''} ${appendPlusSign(selectedOption)} `)
              }

              resolve()
            })
          }}
          onChange={selectedOption => {
            if (!value) {
              onChange(selectedOption.key)
              setValue('body', `${getValues('body') || ''} ${appendPlusSign(selectedOption.key)} `)
            }
          }}
        />
      )}
    />
  )
}

const CurrencySelector = ({control, errors, getValues, setValue}) => {
  return (
    <Controller
      name="currency"
      control={control}
      defaultValue=""
      render={({field: {onChange, value}}) => (
        <Select
          key={value}
          width="80px"
          isDropdownExpandable={false}
          customContainerClassName={`h-6 border rounded-md px-1 mr-2 ${
            errors?.currency
              ? 'border-red-600 bg-gray-100'
              : `${value ? 'bg-primary text-white' : 'bg-gray-100'} border-primary`
          }`}
          placeholder="$Currency"
          options={constants.supportedCurrencies.selectArrayOptions}
          customRenderOptionContent={option => (
            <p>
              {option.flag} {option.text}
            </p>
          )}
          value={value ? {key: value, text: constants.supportedCurrencies.enumMapping[value]} : null}
          onChange={selectedOption => {
            if (!value) {
              onChange(selectedOption.key)
              setValue('body', `${getValues('body') || ''} $${selectedOption.text} `)
            }
          }}
        />
      )}
    />
  )
}

const RecipientSelector = ({control, errors, getValues, setValue, recipients}) => {
  const recipientOptions = recipients.map(r => ({
    key: r.email,
    text: `${r.firstName || ''} ${r.lastName || ''}`,
    name: r.email,
    avatar: r.avatar,
  }))

  return (
    <Controller
      name="receiver"
      control={control}
      defaultValue=""
      render={({field: {onChange, value}}) => {
        return (
          <Select
            key={value}
            width="100px"
            customContainerClassName={`h-6 border rounded-md px-1 mr-2 ${
              errors?.receiver
                ? 'border-red-600 bg-gray-100'
                : `${value ? 'bg-primary text-white' : 'bg-gray-100'} border-primary`
            }`}
            placeholder="@Recipient"
            options={recipientOptions}
            customRenderOptionContent={option => (
              <div className="flex flex-1 items-center">
                <Avatar imageUrl={option.avatar} size="1x" />
                <div className="w-11/12 flex">
                  <p className="text-sm text-gray-600 font-semibold pl-2">{option.name} -</p>
                  <p className="text-sm text-gray-600 pl-2">{option.text}</p>
                </div>
              </div>
            )}
            value={value ? {key: value, text: `${recipientOptions.find(r => r.key === value)?.text}`} : null}
            onChange={selectedOption => {
              const selectedUser = recipients.find(r => r.email === selectedOption.key)
              if (!value && selectedUser) {
                onChange(selectedOption.key)
                setValue('body', `${getValues('body') || ''} ${selectedOption.key || ''} `)
              }
            }}
          />
        )
      }}
    />
  )
}

const OnClickOptions = ({control, errors, getValues, setValue, recipients}) => {
  return (
    <div className="flex">
      <AmountSelector control={control} errors={errors} getValues={getValues} setValue={setValue} />
      <CurrencySelector control={control} errors={errors} getValues={getValues} setValue={setValue} />
      <RecipientSelector
        control={control}
        errors={errors}
        getValues={getValues}
        setValue={setValue}
        recipients={recipients}
      />
    </div>
  )
}

OnClickOptions.defaultProps = {
  errors: {},
  recipients: [],
}

OnClickOptions.propTypes = {
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
  getValues: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  recipients: PropTypes.array,
}

export default OnClickOptions
