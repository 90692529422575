import React from 'react'
import * as PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import {useForm, Controller, useWatch} from 'react-hook-form'
import * as util from '../../lib/util'
import Avatar from '../elements/avatar'
import Button from '../elements/button'
import OnClickOptions from './partials/on-click-options'
import * as api from '../../lib/api'
import AutoCompletedInput from '../elements/auto-complete-input'
import * as constants from '../../lib/constants'
import FormItem from '../forms/form-item'
import {CreatePostSkeletonLoader} from '../skeleton-loaders/skeleton-loaders'
import PayPalModal from './partials/paypal-modal'

const validationSchema = Yup.object().shape({
  body: Yup.string().required('Post content is required.'),
  coinAmount: Yup.number().min(10).required(),
  receiver: Yup.string().required('Recipient content is required.'),
  currency: Yup.string().required('Currency content is required.'),
  coinType: Yup.string().required('Wallet type is required.'),
  providerId: Yup.string().nullable(''),
  images: Yup.mixed().nullable(),
  gifUrl: Yup.string().nullable(),
})

const WalletSelection = ({errors, control, setValue}) => {
  const currency = useWatch({
    name: `currency`,
    control,
  })

  // Reset wallet selection
  React.useEffect(() => {
    if (currency) {
      setValue('coinType', null)
    }
  }, [currency])

  return (
    <FormItem
      fieldType={constants.fieldType.mapping.SELECT}
      controlName="coinType"
      errors={errors}
      control={control}
      options={
        currency === 'AUD'
          ? constants.walletBrands.selectArrayOptions.filter(w => w.key === constants.walletBrands.enumMapping.paypal)
          : constants.walletBrands.selectArrayOptions
      }
      placeholder="Wallet selection"
      rules={{required: true}}
    />
  )
}

const Attachments = ({errors, control}) => {
  return (
    <>
      <div className="flex border-t border-gray-200 pt-3" />
      <div className="flex pb-2 items-start">
        <div className="flex">
          <FormItem
            fieldType={constants.fieldType.mapping.FILE_UPLOAD}
            controlName="image"
            errors={errors}
            control={control}
            defaultValue={null}
          />
        </div>
        <div className="cursor-pointer flex items-center ml-3">
          <p className="text-base font-bold">GIF</p>
        </div>
      </div>
    </>
  )
}

const BodyForm = ({control, errors, setValue, recipients, getValues, trigger}) => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  const body = useWatch({
    name: `body`,
    control,
  })

  // Remove all selected attributes when erased
  React.useEffect(() => {
    const currentFormValues = getValues()

    if (currentFormValues.coinAmount && !body.includes(currentFormValues.coinAmount)) setValue('coinAmount', null)
    if (currentFormValues.currency && !body.includes(currentFormValues.currency)) setValue('currency', null)
    if (currentFormValues.receiver) {
      const selectedUser = recipients.find(r => r.email === currentFormValues.receiver)
      if (selectedUser && !body.includes(selectedUser.email)) setValue('receiver', null)
    }
  }, [body])

  return (
    <Controller
      name="body"
      control={control}
      defaultValue=""
      render={({field: {onChange, value}}) => {
        return (
          <AutoCompletedInput
            value={value}
            options={[
              ...recipients.map(r => ({
                key: r.email,
                text: r.email,
                name: `${r.firstName || ''} ${r.lastName || ''}`,
                avatar: r.avatar,
                searchSymbol: '@',
                controlName: 'receiver',
              })),
              ...constants.supportedCurrencies.selectArrayOptions.map(c => ({
                ...c,
                searchSymbol: '$',
                controlName: 'currency',
              })),
              {
                text: '10',
                key: '10',
                searchSymbol: '+',
                controlName: 'coinAmount',
              },
            ]}
            searchSymbols={['+', '@', '$']}
            creatableSymbols={['+']}
            customRenderOptionContent={option => {
              if (option.controlName === 'receiver')
                return (
                  <div className="flex flex-1 items-center">
                    <Avatar imageUrl={option.avatar} size="1x" />
                    <p className="text-sm text-gray-600 font-semibold pl-2">{option.name} -</p>
                    <p className="text-sm text-gray-600 pl-2">{option.text}</p>
                  </div>
                )

              if (option.controlName === 'currency')
                return (
                  <p>
                    {option.flag} {option.text}
                  </p>
                )

              return <p>+{option.text}</p>
            }}
            placeholder={`Who do you want to give shout-out to, ${me.firstName}?`}
            onTextChange={value => onChange(value)}
            onSelect={selectedOption => {
              if (!selectedOption.searchSymbol) return null

              const fieldValue = getValues(selectedOption.controlName)
              if (!fieldValue) {
                setValue(selectedOption.controlName, selectedOption.key)
                trigger(selectedOption.controlName)
              }
            }}
          />
        )
      }}
    />
  )
}

const CreatePost = ({containerClassName}) => {
  const dispatch = useDispatch()
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  const [paypalData, setPaypalData] = React.useState(null)

  const [state, setState] = React.useState({
    isFetchingData: true,
    recipients: [],
  })

  const {recipients, isFetchingData} = state
  const userName = `${me.firstName || ''} ${me.lastName || ''}` // Temp

  const {formState, handleSubmit, control, getValues, setValue, trigger} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const recipients = await api.networksGet({page: 'all', friends: true})

      // Attach temp initial avatar png
      setState({
        isFetchingData: false,
        recipients: recipients.map(r => ({
          ...r,
          avatar: r.avatar ? r.avatar : util.generateDummyAvatarUrl(`${r.firstName || ''} ${r.lastName || ''}`),
        })),
      })
    } catch (error) {
      setState({isFetchingData: false, recipients: []})
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const {isSubmitting, errors} = formState

  const handleFormSubmit = async values => {
    if (['gcash', 'paymaya'].includes(values.coinType)) return handleCreateMobileWalletPost(values)
    return handlePaymayaWalletPost(values)
  }

  const handlePaymayaWalletPost = async values => {
    const formData = util.generateFormData(values)

    try {
      const paypalData = await api.initializePost(formData)
      setPaypalData({...values, ...paypalData})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const handleCreateMobileWalletPost = async values => {
    const formData = util.generateFormData(values)

    try {
      const {redirectUrl} = await api.initializePost(formData)
      window.location.replace(redirectUrl)
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  if (isFetchingData) return <CreatePostSkeletonLoader />

  return (
    <div className={`w-full max-3/4 flex bg-white rounded-md shadow-md py-4 px-4 ${containerClassName}`}>
      <Avatar imageUrl={me.avatar ? me.avatar : util.generateDummyAvatarUrl(userName)} size="2x" />
      <div className="flex flex-col flex-1 ml-3">
        <form onSubmit={handleSubmit(handleFormSubmit)}>
          <FormItem
            fieldType={constants.fieldType.mapping.HIDDEN_FORM}
            controlName="providerId"
            control={control}
            defaultValue="xendit"
          />

          <div className="bg-gray-100 rounded-md p-2">
            <div className="px-5 mx-auto">
              <OnClickOptions
                control={control}
                errors={errors}
                getValues={getValues}
                setValue={setValue}
                recipients={recipients}
              />

              <BodyForm
                control={control}
                errors={errors}
                setValue={setValue}
                recipients={recipients}
                getValues={getValues}
                trigger={trigger}
              />
            </div>

            <Attachments errors={errors} control={control} />
          </div>
          <WalletSelection errors={errors} control={control} setValue={setValue} />

          <Button
            isLoading={isSubmitting}
            type="button"
            title="Shout-out"
            className="mt-2 mb-5"
            color="primary"
            onClick={handleSubmit(handleFormSubmit)}
          />
        </form>
      </div>

      {paypalData && <PayPalModal paypalData={paypalData} onToggleClose={() => setPaypalData(null)} />}
    </div>
  )
}

CreatePost.defaultProps = {
  containerClassName: '',
}

CreatePost.propTypes = {
  containerClassName: PropTypes.string,
}

export default CreatePost
