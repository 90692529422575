import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import Select from '../../elements/select'

const SelectForm = ({
  control,
  errors,
  controlName,
  label,
  rules,
  placeholder,
  defaultValue,
  isDisabled,
  options,
  style,
}) => {
  return (
    <div className="flex-grow flex-col relative">
      {label && <p className="text-base font-semibold">{label}</p>}
      <Controller
        name={controlName}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {onChange, value}}) => (
          <>
            {value && <p className="absolute text-xs text-gray-500 top-1 z-10 left-1 top-1">{placeholder}</p>}
            <Select
              key={value}
              width="100%"
              className={`${errors[controlName] ? 'border border-red-600' : ''}`}
              placeholder={placeholder}
              options={options}
              value={value ? options.find(o => o.key === value) : null}
              onChange={selectedOption => onChange(selectedOption.key)}
            />
          </>
        )}
      />

      {errors?.[controlName] && <p className="text-xs text-red-600 pl-2">{errors?.[controlName]?.message}</p>}
    </div>
  )
}

SelectForm.defaultProps = {
  errors: {},
  label: null,
  rules: {},
  placeholder: '',
  defaultValue: '',
  isDisabled: false,
  style: '',
  options: [],
}

SelectForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  options: PropTypes.array,
  style: PropTypes.string,
}

export default SelectForm
