import * as redux from 'redux'

export const initialState = {
  accessToken: null,
  toasts: [],
  user: null,
  me: null,
  isSidebarOpen: true,
  imageViewerData: {},
}

const accessToken = (state = null, action = {}) => {
  switch (action.type) {
    case 'LOGOUT':
      return null
    case 'USER_AUTH':
      if (action.data) {
        return action.data
      }

      return null

    case 'REFRESH_AUTH':
      if (action.data) {
        return action.data
      }

      return null
    case 'ACCESS_TOKEN_RECEIVED':
      return action.data
    default:
      return state
  }
}

const me = (state = null, action = {}) => {
  switch (action.type) {
    case 'LOGOUT':
      return null
    case 'ME_RECEIVED':
      if (action.data) {
        return action.data
      }

      return state
    case 'REFRESH_AUTH':
      return null

    default:
      return state
  }
}

const toasts = (state = [], action = {}) => {
  switch (action.type) {
    case 'TOAST_RECEIVED': {
      const newToasts =
        action?.data?.length > 0 ? action?.data?.map(d => ({...d, id: Math.round(Math.random() * 1_000_000)})) : []
      return [...state, ...newToasts]
    }

    case 'TOAST_REMOVED': {
      const currentToasts = [...state]
      const idxToDelete = currentToasts.findIndex(t => t.id === action.data)
      currentToasts.splice(idxToDelete, 1)

      return currentToasts
    }

    default:
      return state
  }
}

const isSidebarOpen = (state = {}, action = {}) => {
  switch (action.type) {
    case 'SIDEBAR_TOGGLE':
      return action.data
    default:
      return state
  }
}

const defaultReceived = (baseAction, state, action) => {
  if (state === undefined) state = null
  if (action === undefined) action = {}

  switch (action.type) {
    case `${baseAction}_RECEIVED`:
      return action.data
    default:
      return state
  }
}

export const reducerComposition = () => {
  return redux.combineReducers({
    accessToken,
    toasts,
    me,
    isSidebarOpen,
    user: (state = null, action = {}) => defaultReceived('USER', state, action),
    imageViewerData: (state, action) => defaultReceived('IMAGE_VIEWER_DATA', state, action),
  })
}
