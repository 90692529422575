import React from 'react'
import {useParams} from 'react-router-dom'
import {useSelector, useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import PageContainer from '../../components/page-container'
import Header from './partials/header'
import Tabs from './partials/tabs'
import CustomIcon from '../../components/elements/custom-icon'
import * as constants from '../../lib/constants'
import * as api from '../../lib/api'
import FormItem from '../../components/forms/form-item'
import Button from '../../components/elements/button'
import {HeaderSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'

const About = () => {
  const dispatch = useDispatch()
  const {id: paramId} = useParams()
  const {me, user} = useSelector(state => ({
    me: state.me,
    user: state.user,
  }))
  const isOwnUser = me.id === paramId

  const {
    id,
    bio,
    birthdate,
    email,
    gender,
    firstname,
    lastname,
    hobbies,
    hometown,
    phone,
    workplace,
    relationshipStatus,
  } = isOwnUser ? me : user

  const [isEditing, setIsEditing] = React.useState(false)
  const {formState, handleSubmit, control} = useForm({
    reValidateMode: 'onChange',
  })
  const {isSubmitting, errors} = formState

  const handleFormSubmit = async values => {
    delete values.id
    delete values.email
    try {
      const updatedMe = await api.mePost({...values})
      dispatch({type: 'ME_RECEIVED', data: updatedMe})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Changes successully saved'}],
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }

    setIsEditing(false)
  }

  const renderContent = value => {
    if (value) return `${value}`
    return '-'
  }

  const userDetails = [
    {
      fieldName: 'Bio',
      icon: 'fas fa-quote-left',
      value: renderContent(bio),
      key: 'bio',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'First Name',
      icon: 'fas fa-user-alt',
      value: renderContent(firstname),
      key: 'firstname',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Last Name',
      icon: 'fas fa-user-alt',
      value: renderContent(lastname),
      key: 'lastname',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Email',
      icon: 'fas fa-envelope',
      value: renderContent(email),
      key: 'email',
      fieldType: 'SHORT_TEXT',
      disabled: true,
    },
    {
      fieldName: 'Username',
      icon: 'fas fa-at',
      value: renderContent(id),
      key: 'id',
      fieldType: 'SHORT_TEXT',
      disabled: true,
    },
    {
      fieldName: 'Hometown',
      icon: 'fas fa-map-marker-alt',
      value: renderContent(hometown),
      key: 'hometown',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Gender',
      icon: 'fas fa-users',
      value: renderContent(gender),
      key: 'gender',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Birthday',
      icon: 'fas fa-birthday-cake',
      value: renderContent(birthdate),
      key: 'birthdate',
      fieldType: 'DATE_PICKER',
    },
    {
      fieldName: 'Work',
      icon: 'fas fa-briefcase',
      value: renderContent(workplace),
      key: 'workplace',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Contact',
      icon: 'fas fa-address-book',
      value: renderContent(phone),
      key: 'phone',
      fieldType: 'PHONE_NUMBER',
    },
    {
      fieldName: 'Hobbies',
      icon: 'fas fa-bowling-ball',
      value: renderContent(hobbies),
      key: 'hobbies',
      fieldType: 'SHORT_TEXT',
    },
    {
      fieldName: 'Relationship',
      icon: 'fas fa-heart',
      value: renderContent(relationshipStatus),
      key: 'relationship_status',
    },
  ]

  return (
    <div className="w-full bg-white rounded-md shadow-md mt-14 h-screen flex flex-col mb-10">
      <div className="flex mt-5 justify-between px-8 h-12">
        <div className="text-xl font-bold">About</div>
        {!isEditing && isOwnUser && (
          <button
            type="button"
            className="focus:outline-none mb-5"
            onClick={() => {
              setIsEditing(true)
            }}
          >
            <CustomIcon icon="fa fa-pen" size="4x" bgColor="lgray" iconColor="secondarygray" />
          </button>
        )}
      </div>

      <div className="mb-10">
        <form className="h-full" onSubmit={handleSubmit(handleFormSubmit)}>
          {userDetails.map(user => {
            return (
              <div key={user.fieldName} className="ml-16 flex items-center h-12">
                <div className="flex w-44">
                  <div className="pt-0.5">
                    <i className={`mr-4 ml-1 ${user.icon}`} />
                  </div>
                  <div className="text-md">{user.fieldName}</div>
                </div>
                <div className={`w-full mr-5 text-base ${!isEditing && 'px-3'}`}>
                  {isEditing ? (
                    <FormItem
                      fieldType={constants.fieldType.mapping.SHORT_TEXT}
                      controlName={user.key}
                      errors={errors}
                      isDisabled={user.disabled}
                      control={control}
                      rules={{required: true}}
                      defaultValue={user.value}
                    />
                  ) : (
                    user.value
                  )}
                </div>
              </div>
            )
          })}
          {isEditing && isOwnUser && (
            <div className="w-full flex justify-end">
              <div className="w-20 mx-10">
                <Button
                  isLoading={isSubmitting}
                  type="submit"
                  title="Save"
                  className="mx-1"
                  size="small"
                  color="primary"
                  onClick={handleSubmit(handleFormSubmit)}
                />
              </div>
            </div>
          )}
        </form>
      </div>
    </div>
  )
}

const Activity = () => {
  return (
    <div className="w-full bg-white rounded-md shadow-md mt-4 h-screen flex flex-col mb-8">
      <div className="flex mt-5 justify-between px-8 h-12">
        <div className="text-xl font-bold">Activty</div>
      </div>
      <div className="flex justify-center">
        <div className="flex flex-col items-center mx-14">
          <div className="text-xl">Sent Shout-outs</div>
          <div className="text-8xl font-bold text-primary">20</div>
        </div>
        <div className="flex flex-col items-center mx-14">
          <div className="text-xl">Recevied Shout-outs</div>
          <div className="text-8xl font-bold text-primary">99+</div>
        </div>
      </div>
      <div className="w-full- mx-5 border-b-2 mt-8" />
      <div className="flex justify-center my-2 cursor-pointer"> View all transactions</div>
    </div>
  )
}

const UserProfile = () => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))
  const [isFetchingData, setIsFetchingData] = React.useState(true)
  const {id} = useParams()
  const dispatch = useDispatch()
  const isOwnUser = me.id === id

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    if (isOwnUser) return fetchOwnUser()
    fetchOther()
  }

  const fetchOwnUser = async () => {
    try {
      const me = await api.meGet()
      dispatch({type: 'ME_RECEIVED', data: me})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    } finally {
      setIsFetchingData(false)
    }
  }

  const fetchOther = async () => {
    try {
      const user = await api.networkGetProfile(id)
      dispatch({
        type: 'USER_RECEIVED',
        data: {
          ...user,
          ...user.bio,
        },
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    } finally {
      setIsFetchingData(false)
    }
  }

  if (isFetchingData)
    return (
      <PageContainer>
        <div className="w-full flex flex-col bg-white rounded-md shadow-md mt-7">
          <HeaderSkeletonLoader />
        </div>
      </PageContainer>
    )

  return (
    <PageContainer>
      <div className="w-full flex flex-col bg-white rounded-md shadow-md mt-7">
        <Header>
          <Tabs currentTab="about" />
        </Header>
      </div>
      <About />
      <Activity />
    </PageContainer>
  )
}

export default UserProfile
