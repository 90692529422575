import React from 'react'
import {useSelector} from 'react-redux'
import {createPopper} from '@popperjs/core'
import ReactDOM from 'react-dom'
import {Link} from 'react-router-dom'

const UserDropdown = ({isDropdownActive, triggerRef}) => {
  const popperRef = React.useRef()
  const popupRef = React.useRef()

  const {
    me: {id},
  } = useSelector(state => ({
    me: state.me,
  }))

  React.useEffect(() => {
    if (isDropdownActive) {
      if (!popperRef.current) {
        popperRef.current = createPopper(triggerRef.current, popupRef.current, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({state}) => {
                state.styles.popper.width = `${
                  state.rects.reference.width +
                  (state.elements.reference.clientWidth < 100 ? state.elements.reference.clientWidth * 2 : 0)
                }px`

                state.styles.popper.top = '20px'
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              effect: ({state}) => {
                state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`
              },
            },
          ],
        })
      }
    } else if (popperRef.current) {
      popperRef.current.destroy()
      popperRef.current = null
    }

    // If (onDropdownOpenStateChange) onDropdownOpenStateChange(state.open)

    return () => {
      if (popperRef.current) popperRef.current.destroy()
    }
  }, [isDropdownActive])

  if (!isDropdownActive) return null

  return ReactDOM.createPortal(
    <div className={`${isDropdownActive ? '' : 'hidden'}`}>
      <div
        ref={popupRef}
        key={isDropdownActive}
        className="bg-white w-full flex-1 rounded-md mt-26 p-2 shadow-md"
        style={{pointerEvents: 'auto', display: isDropdownActive ? 'auto' : 'none'}}
      >
        <div className="w-full">
          <Link to={`/users/${id}/profile`}>
            <button
              type="button"
              className="rounded-sm group focus:outline-none hover:bg-primary flex w-full text-gray-600 text-base p-2 items-center pl-3"
            >
              <i className="fas fa-user pr-2" />
              <p>Go to profile</p>
            </button>
          </Link>

          <Link to="/users/settings">
            <button
              type="button"
              className="rounded-sm  group focus:outline-none hover:bg-primary flex w-full text-gray-600 text-base p-2 items-center pl-3"
            >
              <i className="fas fa-cog pr-2" />
              <p>Manage profile</p>
            </button>
          </Link>

          <div className="my-4 border-t border-gray-300" />

          <Link to="/logout">
            <button
              type="button"
              className="rounded-sm group focus:outline-none hover:bg-primary flex w-full text-gray-600 text-base items-center p-2 pl-3"
            >
              <i className="fas fa-sign-out-alt pr-2" />
              <p>Logout</p>
            </button>
          </Link>
        </div>
      </div>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

export default UserDropdown
