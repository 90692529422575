import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import FileUploader from '../../elements/file-uploader'

const FileUploadForm = ({
  control,
  errors,
  controlName,
  label,
  rules,
  placeholder,
  defaultValue,
  isDisabled,
  inputProps,
  type,
  style,
}) => {
  return (
    <div className="flex-grow flex-col relative">
      {label && <p className="text-base font-semibold">{label}</p>}
      <Controller
        name={controlName}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {onChange, value}}) => (
          <FileUploader
            onUpload={file => {
              onChange(file)
            }}
          />
        )}
      />

      {errors?.[controlName] && <p className="text-xs text-red-600 pl-2">{errors?.[controlName]?.message}</p>}
    </div>
  )
}

FileUploadForm.defaultProps = {
  errors: {},
  label: null,
  rules: {},
  placeholder: '',
  defaultValue: '',
  isDisabled: false,
  type: null,
  style: '',
}

FileUploadForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.string,
}

export default FileUploadForm
