import React from 'react'

const Option = ({
  value,
  active,
  isSelected,
  onSelect,
  index,
  onMouseEnter,
  onMouseLeave,
  isMultiple,
  children,
  isLoading,
}) => {
  return (
    <div
      className={`text-sm cursor-pointer rounded-md p-1 ${active ? 'bg-primary' : ''} ${
        !isMultiple && isSelected ? 'bg-primary' : ''
      }
            ${value.disabled ? 'has-background-grey-light' : ''}
            ${value.type === 'header' ? 'is-option-header' : ''}
            `}
      title={typeof value.text === 'string' && value.text}
      onMouseDown={e => e.preventDefault()}
      onClick={e => {
        e.preventDefault()
        onSelect(value)
      }}
      onMouseEnter={() => !value.type && onMouseEnter(index)}
      onMouseLeave={() => !value.type && onMouseLeave(index)}
    >
      <div>
        <div style={{whiteSpace: 'nowrap'}}>
          {isMultiple && isSelected && <i className="text-black text-sm fas fa-check-square" />}
          {` `}
          {children}
        </div>
        {isLoading && <i className="text-sm fas fa-spinner fa-spin" />}
      </div>
    </div>
  )
}

export default React.memo(Option)
