import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory, Link} from 'react-router-dom'
import {useForm} from 'react-hook-form'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'

import FormItem from '../../components/forms/form-item'
import * as constants from '../../lib/constants'
import Button from '../../components/elements/button'
import * as api from '../../lib/api'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required.').email('Not a valid email.'),
  password: Yup.string().required('Password is required.'),
  firstname: Yup.string().required('First name is required.'),
  lastname: Yup.string().required('Last name is required.'),
  birthdate: Yup.string().nullable().required('Birthday is required.'),
  phone: Yup.object().shape({
    countryCode: Yup.string().required('Country code is required.'),
    number: Yup.string().required('Phone number is required'),
  }),
})

const SignUpForm = () => {
  const dispatch = useDispatch()

  const {formState, handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const {isSubmitting, errors} = formState

  const onSubmit = async values => {
    try {
      await api.signUp({user: {...values}})
    } catch (error) {
      const errorCode = error.errors[0].code
      const toastType = errorCode === 'unconfirmed_account' ? 'success' : 'danger'

      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: toastType, duration: 10000})),
      })
    }
  }

  return (
    <div className="items-center justify-center w-1/2">
      <p className="text-3xl font-bold text-white text-center">Create account</p>

      <div className="w-full mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex-1 flex flex-row">
            <div className="mr-1 flex-1">
              <FormItem
                fieldType={constants.fieldType.mapping.SHORT_TEXT}
                controlName="firstname"
                errors={errors}
                control={control}
                placeholder="First name"
                defaultValue=""
              />
            </div>
            <div className="ml-1 flex-1">
              <FormItem
                fieldType={constants.fieldType.mapping.SHORT_TEXT}
                controlName="lastname"
                errors={errors}
                control={control}
                placeholder="Last name"
                defaultValue=""
              />
            </div>
          </div>
          <FormItem
            fieldType={constants.fieldType.mapping.SHORT_TEXT}
            controlName="email"
            errors={errors}
            control={control}
            placeholder="Email"
            defaultValue=""
          />

          <FormItem
            fieldType={constants.fieldType.mapping.SHORT_TEXT}
            controlName="password"
            errors={errors}
            control={control}
            placeholder="New password"
            type="password"
            defaultValue=""
          />

          <FormItem
            fieldType={constants.fieldType.mapping.DATE_PICKER}
            controlName="birthdate"
            placeholder="Birthday"
            errors={errors}
            control={control}
          />

          <FormItem
            fieldType={constants.fieldType.mapping.PHONE_NUMBER}
            controlName="phone"
            placeholder="Phone"
            errors={errors}
            control={control}
          />
          <Button
            isLoading={isSubmitting}
            type="submit"
            title="Sign up"
            className="mt-2"
            onClick={handleSubmit(onSubmit)}
          />

          <Link to="/login">
            <p className="text-sm text-white">
              <span>Already have an account? </span>
              <span className="text-white underline cursor-pointer font-semibold">Login here.</span>
            </p>
          </Link>
        </form>
      </div>
    </div>
  )
}

const SignUp = () => {
  const history = useHistory()
  const {accessToken, me} = useSelector(state => ({
    accessToken: state.accessToken,
    me: state.me,
  }))

  const isPossiblyAuthenticated = Boolean(accessToken) || Boolean(me?.id)

  React.useEffect(() => {
    if (isPossiblyAuthenticated) history.push('/')
  }, [isPossiblyAuthenticated])

  if (isPossiblyAuthenticated) return null

  return (
    <div className="flex flex-row w-full h-full justify-between bg-gray-100">
      <div className="w-7/12 h-full bg-primary rounded-br-4xl flex justify-center items-center">
        <SignUpForm />
      </div>

      <div className="flex-grow flex-1 flex flex-col bg-gray-100 items-center justify-center p-10 text-center">
        <img src="https://thankfully.me/img/favicon.png" alt="thankfully logo" className="w-26 h-20 object-contain " />
        <p className="text-5xl font-bold mb-8 mt-2">
          <span className="text-gray-500">thankfully</span>
          <span className="text-primary">.me</span>
        </p>

        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">shout-outs.</span>
        </p>

        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">coins.</span>
        </p>
      </div>

      <p className="absolute bottom-10 left-10 text-xs text-gray-100">thankfully 2021</p>
    </div>
  )
}

export default SignUp
