import React, {useEffect, useState} from 'react'
// Import * as PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import * as util from '../../lib/util'
import Avatar from '../elements/avatar'
import * as api from '../../lib/api'
import {NetworkSkeletonLoader} from '../skeleton-loaders/skeleton-loaders'
import {Link} from 'react-router-dom'

const PeopleYouMayKnow = () => {
  const dispatch = useDispatch()
  const [state, setState] = useState({
    isFetchingData: true,
    users: [],
  })

  const [arrowVisibility, setArrowVisibility] = useState({
    isLeftArrowVisible: false,
    isRightArrowVisible: false,
  })

  const {users, isFetchingData} = state
  const usersContainerRef = React.useRef(null)

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    try {
      const users = await api.networksGet({page: 'all', getMutualFriends: true})
      setState({
        isFetchingData: false,
        users,
      })
    } catch (error) {
      setState({isFetchingData: false, users: []})
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  React.useEffect(() => {
    if (users.length > 0 && usersContainerRef && usersContainerRef.current) toggleCarouselArrowVisibility()
  }, [users?.length])

  const SCROLL_DURATION = 200
  const USERS_WIDTH = 112
  const usersContainerElement = usersContainerRef?.current

  const handleScrollLeft = () => {
    const currentScrollPosition = usersContainerElement.scrollLeft
    const usersContainerRect = usersContainerElement.getBoundingClientRect()

    let userElements = usersContainerElement.childNodes[0]?.childNodes || []
    userElements = [...userElements]

    const cutOffUser = userElements
      .slice(0, userElements.length)
      .reverse()
      .find(b => {
        const rect = b.getBoundingClientRect()
        if (rect.left < usersContainerRect.left) return b
        return null
      })

    if (cutOffUser) {
      const cutOffrect = cutOffUser.getBoundingClientRect()
      const newScrollPosition = currentScrollPosition - (usersContainerRect.left - cutOffrect.left)
      scrollCarousel(usersContainerElement, newScrollPosition, SCROLL_DURATION)
    }
  }

  const handleScrollRight = () => {
    const currentScrollPosition = usersContainerElement.scrollLeft
    const usersContainerRect = usersContainerElement.getBoundingClientRect()

    let userElements = usersContainerElement.childNodes[0]?.childNodes || []
    userElements = [...userElements]

    const cutOffUser = userElements.slice(0, userElements.length).find(b => {
      const rect = b.getBoundingClientRect()
      if (rect.right > usersContainerRect.right) return b
      return null
    })

    if (cutOffUser) {
      const cutOffrect = cutOffUser.getBoundingClientRect()
      const newScrollPosition = USERS_WIDTH - (usersContainerRect.right - cutOffrect.left) + currentScrollPosition
      scrollCarousel(usersContainerElement, newScrollPosition, SCROLL_DURATION)
    }
  }

  const scrollCarousel = (usersContainerElement, scrollPosition, duration) => {
    if (duration <= 0) return

    const diff = scrollPosition - usersContainerElement.scrollLeft
    const tick = (diff / duration) * 10

    window.setTimeout(() => {
      usersContainerElement.scrollLeft += tick
      if (usersContainerElement.scrollLeft === scrollPosition) return
      scrollCarousel(usersContainerElement, scrollPosition, duration - 10)
    }, 10)
  }

  const toggleCarouselArrowVisibility = () => {
    let isRightArrowVisible = false
    let isLeftArrowVisible = false
    const usersContainerElement = usersContainerRef?.current

    const usersContainerRect = usersContainerElement?.getBoundingClientRect()
    const usersContainerRectLeftBound = usersContainerRect.left

    let userElements = usersContainerElement.childNodes[0].childNodes || []
    userElements = [...userElements]

    const lastUser = userElements[userElements.length - 1]
    const lastUserRect = lastUser.getBoundingClientRect()
    const firstUser = userElements[0]
    const firstUserRect = firstUser.getBoundingClientRect()

    if (firstUserRect.left < usersContainerRectLeftBound) isLeftArrowVisible = true
    isRightArrowVisible = !(lastUserRect.right < usersContainerRect.right)

    setArrowVisibility({...arrowVisibility, isLeftArrowVisible, isRightArrowVisible})
  }

  if (isFetchingData) return <NetworkSkeletonLoader />

  return (
    <div className="w-full max-3/4 flex flex-col bg-white rounded-md shadow-md pt-2 pb-4 px-4 mt-6 mb-3">
      <p className="text-base font-semibold">People you may know</p>

      <div className="flex w-full my-2 self-center items-center justify-center">
        <button
          type="button"
          className={`rounded-full focus:outline-none text-white bg-primary opacity-${
            arrowVisibility.isLeftArrowVisible ? '100' : '0'
          }`}
          onClick={handleScrollLeft}
        >
          <div className="h-14 w-14 flex items-center justify-center text-xl">
            <i className="text-white fas fa-chevron-left" />
          </div>
        </button>

        <div
          ref={usersContainerRef}
          className="flex items-center overflow-auto no-scrollbar"
          onScroll={toggleCarouselArrowVisibility}
        >
          <div className="flex p-1">
            {users.map((user, index) => (
              <Link key={index} to={`/users/${user.id}/profile`}>
                <div className="w-28 flex flex-col items-center mx-3.5 hover:shadow-md cursor-pointer pt-1">
                  <Avatar
                    imageUrl={
                      user.avatar
                        ? user.avatar
                        : util.generateDummyAvatarUrl(`${user.firstName || ''} ${user.lastName || ''}`)
                    }
                    size="3x"
                  />
                  <div className="flex justify-center items-center w-5 h-5 bg-primary rounded-full relative bottom-7 left-7 text-white text-md font-bold">
                    {' '}
                    +{' '}
                  </div>
                  <div className="flex text-center text-sm font-semibold">
                    {user.firstName} {user.lastName}
                  </div>
                  <p className="text-sm text-gray-500">
                    {user.network.mutualFriends.length === 0
                      ? 'No Mutual Friends'
                      : `${user.network.mutualFriends.length} Mutual Friend${
                          user.network.mutualFriends.length > 1 ? 's' : ''
                        }`}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
        <button
          type="button"
          className={`rounded-full focus:outline-none text-white bg-primary opacity-${
            arrowVisibility.isRightArrowVisible ? '100' : '0'
          }`}
          onClick={handleScrollRight}
        >
          <div className="h-14 w-14 flex items-center justify-center text-xl">
            <i className="text-white fas fa-chevron-right" />
          </div>
        </button>
      </div>
    </div>
  )
}

// PeopleYouMayKnow.defaultProps = {
// }
//
// PeopleYouMayKnow.propTypes = {
// }

export default PeopleYouMayKnow
