import React from 'react'
import {createPopper} from '@popperjs/core'
import {useSelector} from 'react-redux'
import ReactDOM from 'react-dom'

const CoinDropdown = ({isCoinDropdownActive, triggerRef}) => {
  const popperRef = React.useRef()
  const popupRef = React.useRef()
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  React.useEffect(() => {
    if (isCoinDropdownActive) {
      if (!popperRef.current) {
        popperRef.current = createPopper(triggerRef.current, popupRef.current, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({state}) => {
                state.styles.popper.width = `${
                  state.rects.reference.width +
                  (state.elements.reference.clientWidth < 100 ? state.elements.reference.clientWidth * 7 : 0)
                }px`

                state.styles.popper.top = '20px'
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              effect: ({state}) => {
                state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`
              },
            },
          ],
        })
      }
    } else if (popperRef.current) {
      popperRef.current.destroy()
      popperRef.current = null
    }

    // If (onDropdownOpenStateChange) onDropdownOpenStateChange(state.open)

    return () => {
      if (popperRef.current) popperRef.current.destroy()
    }
  }, [isCoinDropdownActive])

  if (!isCoinDropdownActive) return null

  return ReactDOM.createPortal(
    <div className={`${isCoinDropdownActive ? '' : 'hidden'}`}>
      <div
        ref={popupRef}
        key={isCoinDropdownActive}
        className="bg-white w-full flex-1 rounded-md mt-26 p-2 shadow-md"
        style={{pointerEvents: 'auto', display: isCoinDropdownActive ? 'auto' : 'none'}}
      >
        <div className="w-full">
          <p className="text-sm mb-3">
            Hi {me.firstName}, glad you’re here in <span className="font-bold">thankfully.me!</span>
          </p>
          <p className="text-sm mb-3">
            We welcome all new users with thankfully bonus coins which you can give out to friends.
          </p>
          <p className="text-sm mb-3">
            You can also receive bonus coins from friends and redeem them for rewards! &nbsp;
            <a href="#" className="text-decoration: underline font-bold">
              Learn More
            </a>
          </p>

          <div className="my-4 border-t border-gray-300" />

          <div className="flex">
            <div>
              <div className="flex bg-primary w-7 h-7 rounded-full text-white text-xl justify-center items-center mx-5 mb-3">
                <i className="text-md fas fa-dollar-sign" />
                <div className="flex justify-center items-center w-3 h-3 bg-white mb-2 ml-0.5 absolute left-12 bottom-14 rounded-full">
                  <i className="text-md text-lgreen fas fa-arrow-circle-right" />
                </div>
              </div>
            </div>
            <span>Bonus coins to share: 10</span>
          </div>

          <div className="flex">
            <div>
              <div className="flex bg-primary w-7 h-7 rounded-full text-white text-xl justify-center items-center mx-5 mb-3">
                <i className="text-md fas fa-dollar-sign" />
                <div className="flex justify-center items-center w-3 h-3 bg-white mb-2 ml-0.5 absolute left-12 bottom-4 rounded-full">
                  <i className="text-md text-lgreen fas fa-arrow-circle-left" />
                </div>
              </div>
            </div>
            <span>Bonus coins received: 0</span>
          </div>
        </div>
      </div>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

export default CoinDropdown
