import React from 'react'
import * as PropTypes from 'prop-types'
import {useDispatch} from 'react-redux'
import {useForm} from 'react-hook-form'
import FormItem from '../../../components/forms/form-item'
import * as constants from '../../../lib/constants'
import Button from '../../../components/elements/button'
import * as api from '../../../lib/api'

const GcashForm = ({wallet, onRemoveWallet, onUpdateWallet}) => {
  const dispatch = useDispatch()
  const {formState, handleSubmit, control, reset} = useForm({
    reValidateMode: 'onChange',
    shouldUnregister: true,
  })

  const {isSubmitting, errors} = formState

  const handleFormSubmit = async values => {
    try {
      await api.walletCreate({...values})
      onUpdateWallet(onUpdateWallet)

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Wallet was successfully saved'}],
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const handleRemove = async () => {
    try {
      await api.walletDelete(wallet.walletBrand)
      reset({walletBrand: constants.walletBrands.enumMapping.gcash, country: 'PH'})
      onRemoveWallet(wallet.walletBrand)

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Wallet was successfully removed'}],
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  return (
    <form className="h-full" onSubmit={handleSubmit(handleFormSubmit)}>
      <div className="h-4/5 overflow-y-scroll">
        <FormItem
          fieldType={constants.fieldType.mapping.HIDDEN_FORM}
          controlName="walletBrand"
          control={control}
          defaultValue={wallet?.walletBrand || constants.walletBrands.enumMapping.gcash}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.NUMBER}
          controlName="accountNumber"
          errors={errors}
          control={control}
          placeholder="Mobile number (09)"
          rules={{required: true}}
          defaultValue={wallet?.accountNumber}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="firstName"
          errors={errors}
          control={control}
          placeholder="First name"
          rules={{required: true}}
          defaultValue={wallet?.firstName}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="lastName"
          errors={errors}
          control={control}
          placeholder="Last name"
          rules={{required: true}}
          defaultValue={wallet?.lastName}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="email"
          errors={errors}
          control={control}
          placeholder="Email"
          rules={{
            required: true,
            pattern: {
              value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[(?:\d{1,3}\.){3}\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/,
              message: 'Email is invalid',
            },
          }}
          defaultValue={wallet?.email}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="addressLine1"
          errors={errors}
          control={control}
          placeholder="Address line 1"
          rules={{required: true}}
          defaultValue={wallet?.addressLine1}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="addressLine2"
          errors={errors}
          control={control}
          placeholder="Address line 2"
          defaultValue={wallet?.addressLine2}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="city"
          errors={errors}
          control={control}
          placeholder="City"
          rules={{required: true}}
          defaultValue={wallet?.city}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SHORT_TEXT}
          controlName="state"
          errors={errors}
          control={control}
          placeholder="State"
          rules={{required: true}}
          defaultValue={wallet?.state}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.NUMBER}
          controlName="zipCode"
          errors={errors}
          control={control}
          placeholder="Zip code"
          rules={{required: true}}
          defaultValue={wallet?.zipCode}
        />

        <FormItem
          fieldType={constants.fieldType.mapping.SELECT}
          controlName="country"
          errors={errors}
          control={control}
          options={constants.allCountries.selectArrayOptions}
          placeholder="Country"
          rules={{required: true}}
          defaultValue={wallet?.country || 'PH'}
        />
      </div>

      <div className="flex justify-end mt-2">
        <div className="w-5/12 flex">
          <Button
            wal
            isLoading={isSubmitting}
            type="submit"
            title="Save"
            className="mx-1"
            size="small"
            onClick={handleSubmit(handleFormSubmit)}
          />
          <Button
            isLoading={isSubmitting}
            type="button"
            title="delete"
            color="red-900"
            className="mx-1"
            size="small"
            onClick={handleRemove}
          />
        </div>
      </div>
    </form>
  )
}

GcashForm.defaultProps = {
  wallet: {},
  onRemoveWallet: () => {},
  onUpdateWallet: () => {},
}

GcashForm.propTypes = {
  wallet: PropTypes.object,
  onRemoveWallet: PropTypes.func,
  onUpdateWallet: PropTypes.func,
}

export default GcashForm
