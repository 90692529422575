import React from 'react'
import {useForm} from 'react-hook-form'
import {useDispatch} from 'react-redux'
import {useHistory, Link} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormItem from '../../components/forms/form-item'
import * as constants from '../../lib/constants'
import Button from '../../components/elements/button'
import * as api from '../../lib/api'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required.').email('Not a valid email.'),
})

const ForgotPasswordForm = () => {
  const {formState, handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const dispatch = useDispatch()
  const history = useHistory()
  const {isSubmitting, errors} = formState

  const onSubmit = async values => {
    try {
      await api.sendPasswordReset({user: {...values}})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Password reset instruction sent to your email.'}],
      })
      history.push('/login')
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  return (
    <div className="items-center justify-center w-1/2">
      <p className="text-3xl font-bold text-white text-center">Forgot Password</p>

      <div className="flex-auto h-px bg-white my-2" />

      <div className="w-full mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            fieldType={constants.fieldType.mapping.SHORT_TEXT}
            controlName="email"
            errors={errors}
            control={control}
            placeholder="Email"
            rules={{required: true}}
            defaultValue=""
          />
          <Button
            isLoading={isSubmitting}
            type="submit"
            title="Send Reset Password Instructions"
            className="mt-2"
            onClick={handleSubmit(onSubmit)}
          />
          <Link to="/login">
            <p className="text-sm text-white">
              <span>Back to </span>
              <span className="text-white underline cursor-pointer font-semibold">Login.</span>
            </p>
          </Link>
        </form>
      </div>
    </div>
  )
}

const ForgotPassword = () => {
  return (
    <div className="flex flex-row w-full h-full justify-between bg-gray-100">
      <div className="w-7/12 h-full bg-primary rounded-br-4xl flex justify-center items-center">
        <ForgotPasswordForm />
      </div>
      <div className="flex-grow flex-1 flex flex-col bg-gray-100 items-center justify-center p-10 text-center">
        <img src="https://thankfully.me/img/favicon.png" alt="thankfully logo" className="w-26 h-20 object-contain " />
        <p className="text-5xl font-bold mb-8 mt-2">
          <span className="text-gray-500">thankfully</span>
          <span className="text-primary">.me</span>
        </p>
        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">shout-outs.</span>
        </p>
        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">coins.</span>
        </p>
      </div>
      <p className="absolute bottom-10 left-10 text-xs text-gray-100">thankfully 2021</p>
    </div>
  )
}

export default ForgotPassword
