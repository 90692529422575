import React from 'react'
import {Link} from 'react-router-dom'

const RedirectPage = ({title, icon, description, path}) => {
  return (
    <div className="flex flex-col w-full h-full bg-gray-100 justify-center items-center">
      <img src="https://thankfully.me/img/favicon.png" alt="thankfully logo" className="w-26 h-20 object-contain " />
      <p className="text-3xl font-bold mt-2">
        <span className="text-gray-500">{title}</span>
      </p>

      <p className="text-md mb-8 mt-2">
        <span className="text-gray-500">{description}</span>
      </p>

      <Link to={path}>
        <p className="text-sm text-white">
          <span className="underline cursor-pointer font-semibold text-primary">Login now</span>
        </p>
      </Link>
    </div>
  )
}

export default RedirectPage
