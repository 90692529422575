import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import DatePicker from '../../elements/date-picker'

const DatePickerForm = ({control, errors, controlName, label, rules, placeholder, minimumDate, defaultValue}) => {
  return (
    <div className="flex-grow flex-col relative">
      {label && <p className="text-base font-semibold">{label}</p>}
      <Controller
        name={controlName}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {onChange: onFormValueChange, value}}) => (
          <>
            {value && <p className="absolute text-base text-gray-500 top-0 z-10 left-1 top-2">{placeholder}</p>}

            <DatePicker
              value={value}
              placeholder={placeholder}
              style={errors?.[controlName] ? 'border border-red-600' : ''}
              onChange={date => {
                onFormValueChange(date)
              }}
            />
          </>
        )}
      />

      {errors?.[controlName] && <p className="text-xs text-red-600 pl-2">{errors?.[controlName]?.message}</p>}
    </div>
  )
}

DatePickerForm.defaultProps = {
  errors: {},
  label: null,
  rules: {},
  placeholder: '',
  minimumDate: null,
  defaultValue: null,
}

DatePickerForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  minimumDate: PropTypes.instanceOf(Date),
  defaultValue: PropTypes.instanceOf(Date),
}

export default DatePickerForm
