import React, {useEffect} from 'react'
import {createPopper} from '@popperjs/core'
import ReactDOM from 'react-dom'
import Avatar from '../../elements/avatar'

const Notification = ({notificationDetails}) => {
  const notificationIcons = {
    like: 'fas fa-thumbs-up',
    fr: 'fas fa-user-friends',
    comment: 'fas fa-comment-alt',
    coin: 'fas fa-dollar-sign',
  }

  return (
    <div className="flex my-2 w-full">
      <div className="flex py-2 rounded-md hover:bg-gray-100 cursor-pointer">
        <Avatar className="ml-5" imageUrl={notificationDetails.avatarUrl} size="2x" />
        <div className="flex items-center justify-center w-5 h-5 rounded-full bg-primary absolute ml-11 mt-5">
          <i className={`text-white text-xs ${notificationIcons[notificationDetails.type] || `fa-dot-circle`} `} />
        </div>

        <div className="flex flex-col  w-full ml-4 leading-4">
          <p className="text-base">
            <span className="font-bold">{notificationDetails.user} </span>
            {notificationDetails.message}
          </p>
          <div className="flex">
            <p className="text-sm text-gray-400">{notificationDetails.time}</p>
            {notificationDetails.amount && (
              <p className="text-base text-primary ml-1 font-bold">{` + ${notificationDetails.amount} ${notificationDetails.currency}`}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const NotificationDropdown = ({isNotificationDropdownActive, triggerRef, notificationData}) => {
  const popperRef = React.useRef()
  const popupRef = React.useRef()

  useEffect(() => {
    if (isNotificationDropdownActive) {
      if (!popperRef.current) {
        popperRef.current = createPopper(triggerRef.current, popupRef.current, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({state}) => {
                state.styles.popper.width = `${
                  state.rects.reference.width +
                  (state.elements.reference.clientWidth < 100 ? state.elements.reference.clientWidth * 6 : 0)
                }px`

                state.styles.popper.top = '20px'
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              effect: ({state}) => {
                state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`
              },
            },
          ],
        })
      }
    } else if (popperRef.current) {
      popperRef.current.destroy()
      popperRef.current = null
    }

    return () => {
      if (popperRef.current) popperRef.current.destroy()
    }
  }, [isNotificationDropdownActive])

  if (!isNotificationDropdownActive) return null

  return ReactDOM.createPortal(
    <div className={`${isNotificationDropdownActive ? '' : 'hidden'}`}>
      <div
        ref={popupRef}
        key={isNotificationDropdownActive}
        className="bg-white w-full flex-1 rounded-md mt-26 p-2 shadow-md"
        style={{pointerEvents: 'auto', display: isNotificationDropdownActive ? 'auto' : 'none'}}
      >
        <div className="w-full flex">
          <p className="text-base font-bold ml-3 mt-1">Notifications</p>
          {notificationData.length > 0 && (
            <div className="flex items-center justify-center bg-newred w-4 h-4 rounded-full mt-1 ml-1">
              <span className="text-base text-white">{notificationData.length}</span>
            </div>
          )}
        </div>

        <div>
          {notificationData.length >= 1 ? (
            notificationData.map(notification => {
              return <Notification key={notification.id} notificationDetails={notification} />
            })
          ) : (
            <div className="w-full flex items-center justify-center my-7">
              <p className="text-base">No new notification</p>
            </div>
          )}
        </div>
      </div>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

export default NotificationDropdown
