import React from 'react'
import {render} from 'react-dom'
import './styles/index.pcss' // eslint-disable-line import/no-unassigned-import
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom'
import {Provider, useSelector} from 'react-redux'
import * as Sentry from '../node_modules/@sentry/browser'
import {Integrations} from '../node_modules/@sentry/tracing'
import config from './config'
import * as logger from './logger'
import configureStore from './redux/configure-store'
import Toast from './components/toast'
import Navbar from './components/navbar'
import Sidebar from './components/sidebar'
import ImageViewer from './components/image-viewer'
import PageNotFound from './views/landing-pages/page-not-found'
import Logout from './views/landing-pages/logout'
import SomethingWentWrong from './views/something-went-wrong'
import FetchUserData from './views/landing-pages/fetch-user-data'
import Login from './views/authentication/login'
import SignUp from './views/authentication/sign-up'
import Authorize from './views/authentication/authorize'
import ForgotPassword from './views/authentication/forgot-password'
import ChangePassword from './views/authentication/change-password'
import Home from './views/dashboard'
import UserProfile from './views/users/profile'
import UserSettings from './views/users/settings'
import UserFriends from './views/users/friends-list'
import Friends from './views/users/friends'
import About from './views/users/about'
import WalletsOverview from './views/wallets/overview'
import TransactionsOverview from './views/transactions/overview'
import Help from './views/help'
import Redirecting from './views/landing-pages/redirect-page'
import InvalidUserSession from './views/landing-pages/invalid-user-session'

// Init window.console
logger.init()

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.sentry.environment,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1,
})

const reduxStore = configureStore()

const authorizedRoutes = [
  {path: '/', component: Home},
  {path: '/logout', component: Logout},
  // {path: '/invalid-user-session', component: InvalidUserSession},
  {path: '/users/:id/profile', component: UserProfile},
  {path: '/users/:id/about', component: About},
  {path: '/users/:id/friends-list', component: UserFriends},
  {path: '/users/settings', component: UserSettings},
  {path: '/users/:id/friends', component: Friends},
  {path: '/wallets/overview', component: WalletsOverview},
  {path: '/transactions/overview', component: TransactionsOverview},
  {path: '/help', component: Help},
]

const AuthorizedPage = ({component: Component, ...rest}) => {
  const {me, accessToken} = useSelector(state => ({
    me: state.me,
    accessToken: state.accessToken,
  }))

  // Fetch user details
  if (!me && accessToken) {
    return <FetchUserData />
  }

  // Redirect to login
  if (!me && !accessToken) {
    return (
      <Redirecting
        path="/login"
        title="No session found"
        description="You need to login first to access this page."
        icon="fa-user-clock"
      />
    )
  }

  return <Component {...rest} />
}

const AuthorizedRoutes = () => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  return (
    <div className="flex flex-grow h-full">
      {me && <Sidebar />}
      <div className="flex-1 flex mainContainer">
        <div className="w-full">
          <Switch>
            {authorizedRoutes.map(r => (
              <Route
                key={r.path}
                exact
                path={r.path}
                render={props => <AuthorizedPage path={r.path} {...props} component={r.component} />}
              />
            ))}
            <Route path="*">
              <PageNotFound />
            </Route>
          </Switch>
        </div>
      </div>
      <ImageViewer />
    </div>
  )
}

const Index = () => {
  return (
    <Router>
      <Provider store={reduxStore}>
        <div className="w-screen h-screen bg-gray-100 overflow-x-hidden">
          <SomethingWentWrong>
            <Navbar />
            <Switch>
              <Route exact path="/sign-up" component={SignUp} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/authorize" component={Authorize} />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <Route exact path="/change-password" component={ChangePassword} />
              <Route exact path="/invalid-user-session" component={InvalidUserSession} />
              <AuthorizedRoutes />
            </Switch>
            <Toast />
          </SomethingWentWrong>
        </div>
      </Provider>
    </Router>
  )
}

render(<Index />, document.querySelector('#root')) // eslint-disable-line no-undef
