import React, {useEffect, useState} from 'react'
import {useDispatch} from 'react-redux'
import PageContainer from '../../components/page-container'
import * as api from '../../lib/api'
import {FriendsListSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'

const TransactionJournal = () => {
  const [state, setState] = useState({
    transactionJournals: [],
    isFetchingData: true,
  })
  const {isFetchingData, transactionJournals} = state
  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const transactionJournals = await api.getTransactionJournals()
    try {
      setState({
        ...state,
        transactionJournals,
        isFetchingData: false,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  if (isFetchingData) return <FriendsListSkeletonLoader />

  const sentTransactions = transactionJournals.sent
  const receivedTransactions = transactionJournals.received

  const renderSentTransactions = () => {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-1">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr id="sent_transactions">
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Receiver
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Transaction Id
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sent Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {sentTransactions.map((value, fIdx) => {
                return <tr key={fIdx}>{sentTransactionEntry(value)}</tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const sentTransactionEntry = value => {
    return (
      <>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">{value.amount}</div>
              <div className="text-sm text-gray-500">{value.coinType}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.receiverName}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.transactionId}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.status}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.createdAt}</div>
        </td>
      </>
    )
  }

  const renderReceivedTransactions = () => {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-1">
          <table className="min-w-full divide-y divide-gray-200">
            <thead className="bg-gray-50">
              <tr id="received_transactions">
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Amount
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Sender
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Transaction Id
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Status
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  Received Date
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {receivedTransactions.map((value, fIdx) => {
                return <tr key={fIdx}>{receivedTransactionEntry(value)}</tr>
              })}
            </tbody>
          </table>
        </div>
      </div>
    )
  }

  const receivedTransactionEntry = value => {
    return (
      <>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="flex items-center">
            <div className="ml-4">
              <div className="text-sm font-medium text-gray-900">{value.amount}</div>
              <div className="text-sm text-gray-500">{value.coinType}</div>
            </div>
          </div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.senderName}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.transactionId}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.status}</div>
        </td>
        <td className="px-6 py-4 whitespace-nowrap">
          <div className="text-sm text-gray-900">{value.createdAt}</div>
        </td>
      </>
    )
  }

  return (
    <div>
      <div>
        <div className="text-base mt-5 font-bold">{`Sent Shout-outs (${sentTransactions.length})`}</div>
        <div className="px-8 py-10">{renderSentTransactions()}</div>
      </div>

      <div>
        <div className="text-base mt-5 font-bold">{`Received Shout-outs (${receivedTransactions.length})`}</div>
        <div className="px-8 py-10">{renderReceivedTransactions()}</div>
      </div>
    </div>
  )
}

const TransactionOverview = () => {
  return (
    <PageContainer>
      <TransactionJournal />
    </PageContainer>
  )
}

export default TransactionOverview
