{
  "name": "thankfully",
  "version": "0.1.0",
  "private": true,
  "main": "src/index.js",
  "scripts": {
    "open-browser": "npx opener http://localhost:3200",
    "build": "npm-run-all -s clean postcss:build parcel:build",
    "dev": "npm-run-all -s clear-cache -p postcss:watch parcel:serve open-browser",
    "parcel:serve": "parcel ./src/index.html -p 3200",
    "parcel:build": "parcel build ./src/index.html --no-source-maps",
    "clear-cache": "rm -rf .cache dist",
    "lint": "xo",
    "autofix": "xo --fix",
    "postcss:watch": "postcss ./src/styles/index.pcss -o ./tailwind.css --env development -w",
    "postcss:build": "postcss ./src/styles/index.pcss -o ./tailwind.css  --env production -w"
  },
  "husky": {
    "hooks": {
      "pre-commit": "npm run lint"
    }
  },
  "prettier": {
    "semi": false,
    "singleQuote": true,
    "bracketSpacing": false,
    "printWidth": 120,
    "useTabs": false,
    "arrowParens": "avoid",
    "trailingComma": "es5"
  },
  "xo": {
    "extends": "xo-react",
    "parser": "babel-eslint",
    "prettier": true,
    "globals": [
      "navigator",
      "window",
      "test",
      "fixture"
    ],
    "rules": {
      "unicorn/no-reduce": "off",
      "import/no-anonymous-default-export": "off",
      "complexity": "off",
      "react-hooks/exhaustive-deps": "off",
      "unicorn/string-content": "off",
      "unicorn/prevent-abbreviations": "off",
      "unicorn/no-abusive-eslint-disable": "off",
      "react/no-unescaped-entities": "off",
      "react/prop-types": "off",
      "react/state-in-constructor": "off",
      "react/no-array-index-key": "off",
      "no-irregular-whitespace": "off",
      "no-unreachable-loop": "off",
      "no-promise-executor-return": "off",
      "import/extensions": "off",
      "no-throw-literal": 0,
      "no-unused-vars": [
        "error",
        {
          "vars": "all",
          "args": "none",
          "ignoreRestSiblings": false
        }
      ]
    }
  },
  "babel": {
    "presets": [
      "@babel/preset-react"
    ],
    "plugins": [
      "add-react-displayname"
    ]
  },
  "dependencies": {
    "@babel/core": "7.11.6",
    "@babel/plugin-transform-runtime": "7.11.5",
    "@babel/preset-react": "7.10.4",
    "@fortawesome/fontawesome-free": "5.14.0",
    "@hookform/resolvers": "2.6.0",
    "@parcel/transformer-inline-string": "^2.0.0-rc.0",
    "@paypal/react-paypal-js": "7.3.3",
    "@popperjs/core": "^2.9.3",
    "@sentry/react": "6.11.0",
    "@sentry/tracing": "6.11.0",
    "autoprefixer": "10.3.1",
    "axios": "0.20.0",
    "babel-eslint": "10.1.0",
    "babel-plugin-add-react-displayname": "0.0.5",
    "date-fns": "2.23.0",
    "date-fns-tz": "1.1.6",
    "jwt-decode": "3.1.2",
    "moment": "2.28.0",
    "npm-run-all": "4.1.5",
    "parcel": "^2.0.0-rc.0",
    "react": "16.13.1",
    "react-datepicker": "4.2.0",
    "react-dom": "16.13.1",
    "react-dropzone": "11.3.4",
    "react-facebook-login": "^4.1.1",
    "react-hook-form": "7.9.0",
    "react-redux": "7.2.4",
    "react-router-dom": "5.2.0",
    "react-scripts": "3.4.3",
    "redux": "4.1.0",
    "redux-logger": "3.0.6",
    "redux-thunk": "2.3.0",
    "sass": "1.26.11",
    "tailwindcss": "2.2.7",
    "yup": "0.29.3"
  },
  "devDependencies": {
    "@parcel/transformer-image": "^2.0.0-rc.0",
    "env-cmd": "10.1.0",
    "eslint-config-xo": "0.32.1",
    "eslint-config-xo-react": "0.23.0",
    "eslint-plugin-react": "7.20.6",
    "eslint-plugin-react-hooks": "4.1.2",
    "husky": "4.3.0",
    "postcss": "8.3.6",
    "postcss-cli": "8.3.1",
    "postcss-modules": "4.2.2",
    "prettier": "2.1.1",
    "xo": "0.33.1"
  },
  "browserslist": {
    "production": [
      ">0.2%",
      "not dead",
      "not op_mini all"
    ],
    "development": [
      "last 1 chrome version",
      "last 1 firefox version",
      "last 1 safari version"
    ]
  }
}
