import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import * as util from '../../lib/util'
import UserDropdown from './partials/user-dropdown'
import CoinDropdown from './partials/coin-dropdown'
import NotificationDropdown from './partials/notification-dropdown'
import Avatar from '../../components/elements/avatar'
import * as fakeApi from '../../lib/fake-api'
import {Link} from 'react-router-dom'

const User = ({me}) => {
  const [isDropdownActive, setIsDropdownActive] = React.useState(false)
  const {firstname, lastname, avatar} = me
  const userName = `${firstname || ''} ${lastname || ''}`
  const triggerRef = React.useRef()

  return (
    <button
      ref={triggerRef}
      type="button"
      className={`group focus:outline-none hover:bg-primary ${
        isDropdownActive ? 'bg-primary' : 'bg-white'
      } rounded-md text-md font-extralight items-center flex p-1 mr-4`}
      onClick={() => setIsDropdownActive(!isDropdownActive)}
    >
      <Avatar imageUrl={avatar ? avatar : util.generateDummyAvatarUrl(userName)} size="2x" />
      <p className={`text-base font-semibold px-3 w-30 ${isDropdownActive ? 'text-white' : ' group-hover:text-white'}`}>
        {userName}
      </p>
      <p
        className={`text-2xl  fas fa-angle-${
          isDropdownActive ? 'up text-white' : 'down text-primary group-hover:text-white'
        }`}
      />

      <UserDropdown isDropdownActive={isDropdownActive} triggerRef={triggerRef} />
    </button>
  )
}

const Coin = () => {
  const triggerRef = React.useRef()
  const [isCoinDropdownActive, setIsCoinDropdownActive] = React.useState(false)

  return (
    <>
      <button
        ref={triggerRef}
        type="button"
        className={`group focus:outline-none hover:bg-primary w-10 h-10 rounded-full flex items-center justify-center mt-1
          ${isCoinDropdownActive ? 'bg-primary' : 'bg-white'}`}
        onClick={() => setIsCoinDropdownActive(!isCoinDropdownActive)}
      >
        <div
          className={`group-hover:bg-white flex rounded-full flex items-center justify-center w-6 h-6
        ${isCoinDropdownActive ? 'bg-white' : 'bg-primary'}`}
        >
          <span
            className={`group-hover:text-primary text-xl ${isCoinDropdownActive ? 'text-primary' : 'text-white'} font-`}
          >
            <i className="text-md fas fa-dollar-sign" />
          </span>
        </div>
      </button>
      <CoinDropdown isCoinDropdownActive={isCoinDropdownActive} triggerRef={triggerRef} />
    </>
  )
}

const Notification = () => {
  const triggerRef = React.useRef()
  const [isNotificationDropdownActive, setIsNotificationDropdownActive] = React.useState(false)
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    isFetchingData: true,
    errorStatusCode: null,
    notifications: [],
  })

  const {notifications} = state

  const fetchData = async () => {
    try {
      const response = await fakeApi.userNotification()

      if (response.status >= 400) {
        dispatch({
          type: 'TOAST_RECEIVED',
          data: {
            type: 'danger',
            message: response.error.message,
          },
        })
        setState({
          ...state,
          isFetchingData: false,
          errorStatusCode: response.error.statusCode,
        })
      } else {
        setState({
          ...state,
          isFetchingData: false,
          errorStatusCode: null,
          notifications: response.notification,
        })
      }
    } catch (error) {
      return error
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <button
        ref={triggerRef}
        type="button"
        className={`group hover:bg-primary ${
          isNotificationDropdownActive ? 'bg-primary' : 'bg-white'
        } focus:outline-none mx-7 mt-1 w-10 h-10 rounded-full`}
        onClick={() => setIsNotificationDropdownActive(!isNotificationDropdownActive)}
      >
        {notifications.length >= 1 ? (
          <div className="flex items-center justify-center bg-newred w-4 h-4 rounded-full absolute ml-5">
            <span className="text-base text-white">{notifications.length}</span>
          </div>
        ) : (
          ''
        )}
        <i
          className={`group-hover:text-white ${
            isNotificationDropdownActive ? 'text-white' : 'text-primary'
          } fas fa-bell text-2xl`}
        />
      </button>
      <NotificationDropdown
        notificationData={notifications}
        isNotificationDropdownActive={isNotificationDropdownActive}
        triggerRef={triggerRef}
      />
    </>
  )
}

const Navbar = () => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  if (!me) return null

  return (
    <div className="flex-1 flex bg-white shadow-md py-3 px-2 absolute top-0 z-20 w-full justify-between items-center">
      <div className="flex">
        <Link to="/">
          <button type="button" className="focus:outline-none">
            <img
              src="https://floower.herokuapp.com/img/avatar.png"
              alt="thankful.ly logo"
              className="w-26 h-10 object-contain "
            />
          </button>
        </Link>
      </div>

      <div className="flex">
        <Coin />
        <Notification />
        <User me={me} />
      </div>
    </div>
  )
}

export default Navbar
