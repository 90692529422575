export default [
  {
    countryNameEn: 'Andorra',
    countryNameLocal: 'Andorra',
    countryCode: 'AD',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ca',
    officialLanguageNameEn: 'Catalan, Valencian',
    officialLanguageNameLocal: 'Català, Valencià',
    countryCallingCode: '376',
    region: 'Europe',
    flag: '🇦🇩',
  },
  {
    countryNameEn: 'Afghanistan',
    countryNameLocal: 'د افغانستان اسلامي دولتدولت اسلامی افغانستان, جمهوری اسلامی افغانستان',
    countryCode: 'AF',
    currencyCode: 'AFN',
    currencyNameEn: 'Afghan afghani',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fa',
    officialLanguageNameEn: 'Persian',
    officialLanguageNameLocal: 'فارسی',
    countryCallingCode: '93',
    region: 'Asia & Pacific',
    flag: '🇦🇫',
  },
  {
    countryNameEn: 'Antigua and Barbuda',
    countryNameLocal: 'Antigua and Barbuda',
    countryCode: 'AG',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1268',
    region: 'South/Latin America',
    flag: '🇦🇬',
  },
  {
    countryNameEn: 'Anguilla',
    countryNameLocal: 'Anguilla',
    countryCode: 'AI',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1264',
    region: 'South/Latin America',
    flag: '🇦🇮',
  },
  {
    countryNameEn: 'Albania',
    countryNameLocal: 'Shqipëria',
    countryCode: 'AL',
    currencyCode: 'ALL',
    currencyNameEn: 'Albanian lek',
    tinType: 'NIPT',
    tinName: 'Numri i Identifikimit për Personin e Tatueshëm',
    officialLanguageCode: 'sq',
    officialLanguageNameEn: 'Albanian',
    officialLanguageNameLocal: 'Shqip',
    countryCallingCode: '355',
    region: 'Europe',
    flag: '🇦🇱',
  },
  {
    countryNameEn: 'Armenia',
    countryNameLocal: 'Հայաստան',
    countryCode: 'AM',
    currencyCode: 'AMD',
    currencyNameEn: 'Armenian dram',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'hy',
    officialLanguageNameEn: 'Armenian',
    officialLanguageNameLocal: 'Հայերեն',
    countryCallingCode: '374',
    region: 'Europe',
    flag: '🇦🇲',
  },
  {
    countryNameEn: 'Angola',
    countryNameLocal: 'Angola',
    countryCode: 'AO',
    currencyCode: 'AOA',
    currencyNameEn: 'Angolan kwanza',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '244',
    region: 'Africa',
    flag: '🇦🇴',
  },
  {
    countryNameEn: 'Antarctica',
    countryNameLocal: 'Antarctica, Antártico, Antarctique, Антарктике',
    countryCode: 'AQ',
    currencyCode: 'AAD',
    currencyNameEn: 'Antarctic Dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '',
    region: 'Asia & Pacific',
    flag: '🇦🇶',
  },
  {
    countryNameEn: 'Argentina',
    countryNameLocal: 'Argentina',
    countryCode: 'AR',
    currencyCode: 'ARS',
    currencyNameEn: 'Argentine peso',
    tinType: 'CUIT',
    tinName: 'Código Único de Identificación Tributaria',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '54',
    region: 'South/Latin America',
    flag: '🇦🇷',
  },
  {
    countryNameEn: 'American Samoa',
    countryNameLocal: 'American Samoa',
    countryCode: 'AS',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1684',
    region: 'Asia & Pacific',
    flag: '🇦🇸',
  },
  {
    countryNameEn: 'Austria',
    countryNameLocal: 'Österreich',
    countryCode: 'AT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'UID',
    tinName: 'Umsatzsteuer-Identifikationsnummer',
    officialLanguageCode: 'de',
    officialLanguageNameEn: 'German',
    officialLanguageNameLocal: 'Deutsch',
    countryCallingCode: '43',
    region: 'Europe',
    flag: '🇦🇹',
  },
  {
    countryNameEn: 'Australia',
    countryNameLocal: 'Australia',
    countryCode: 'AU',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: 'ABN',
    tinName: 'Australian Business Number',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '61',
    region: 'Asia & Pacific',
    flag: '🇦🇺',
  },
  {
    countryNameEn: 'Aruba',
    countryNameLocal: 'Aruba',
    countryCode: 'AW',
    currencyCode: 'AWG',
    currencyNameEn: 'Aruban florin',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '297',
    region: 'South/Latin America',
    flag: '🇦🇼',
  },
  {
    countryNameEn: 'Åland Islands',
    countryNameLocal: 'Åland',
    countryCode: 'AX',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'sv',
    officialLanguageNameEn: 'Swedish',
    officialLanguageNameLocal: 'Svenska',
    countryCallingCode: '358',
    region: 'Europe',
    flag: '🇦🇽',
  },
  {
    countryNameEn: 'Azerbaijan',
    countryNameLocal: 'Azərbaycan',
    countryCode: 'AZ',
    currencyCode: 'AZN',
    currencyNameEn: 'Azerbaijani manat',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'az',
    officialLanguageNameEn: 'Azerbaijani',
    officialLanguageNameLocal: 'azərbaycan dili',
    countryCallingCode: '994',
    region: 'Asia & Pacific',
    flag: '🇦🇿',
  },
  {
    countryNameEn: 'Bosnia and Herzegovina',
    countryNameLocal: 'Bosna i Hercegovina',
    countryCode: 'BA',
    currencyCode: 'BAM',
    currencyNameEn: 'Bosnia and Herzegovina convertible mark',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'bs',
    officialLanguageNameEn: 'Bosnian',
    officialLanguageNameLocal: 'bosanski jezik',
    countryCallingCode: '387',
    region: 'Europe',
    flag: '🇧🇦',
  },
  {
    countryNameEn: 'Barbados',
    countryNameLocal: 'Barbados',
    countryCode: 'BB',
    currencyCode: 'BBD',
    currencyNameEn: 'Barbados dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1246',
    region: 'South/Latin America',
    flag: '🇧🇧',
  },
  {
    countryNameEn: 'Bangladesh',
    countryNameLocal: 'গণপ্রজাতন্ত্রী বাংলাদেশ',
    countryCode: 'BD',
    currencyCode: 'BDT',
    currencyNameEn: 'Bangladeshi taka',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'bn',
    officialLanguageNameEn: 'Bengali',
    officialLanguageNameLocal: 'বাংলা',
    countryCallingCode: '880',
    region: 'Asia & Pacific',
    flag: '🇧🇩',
  },
  {
    countryNameEn: 'Belgium',
    countryNameLocal: 'België, Belgique, Belgien',
    countryCode: 'BE',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'n° TVABTW-nr Mwst-nr',
    tinName: 'BTW identificatienummer / Numéro de TVA',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '32',
    region: 'Europe',
    flag: '🇧🇪',
  },
  {
    countryNameEn: 'Burkina Faso',
    countryNameLocal: 'Burkina Faso',
    countryCode: 'BF',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '226',
    region: 'Africa',
    flag: '🇧🇫',
  },
  {
    countryNameEn: 'Bulgaria',
    countryNameLocal: 'България',
    countryCode: 'BG',
    currencyCode: 'BGN',
    currencyNameEn: 'Bulgarian lev',
    tinType: 'ДДС номер',
    tinName: 'Идентификационен номер по ДДС',
    officialLanguageCode: 'bg',
    officialLanguageNameEn: 'Bulgarian',
    officialLanguageNameLocal: 'български език',
    countryCallingCode: '359',
    region: 'Europe',
    flag: '🇧🇬',
  },
  {
    countryNameEn: 'Bahrain',
    countryNameLocal: 'البحرين',
    countryCode: 'BH',
    currencyCode: 'BHD',
    currencyNameEn: 'Bahraini dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '973',
    region: 'Arab States',
    flag: '🇧🇭',
  },
  {
    countryNameEn: 'Burundi',
    countryNameLocal: 'Burundi',
    countryCode: 'BI',
    currencyCode: 'BIF',
    currencyNameEn: 'Burundian franc',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '257',
    region: 'Africa',
    flag: '🇧🇮',
  },
  {
    countryNameEn: 'Benin',
    countryNameLocal: 'Bénin',
    countryCode: 'BJ',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '229',
    region: 'Africa',
    flag: '🇧🇯',
  },
  {
    countryNameEn: 'Saint Barthélemy',
    countryNameLocal: 'Saint-Barthélemy',
    countryCode: 'BL',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '590',
    region: 'South/Latin America',
    flag: '🇧🇱',
  },
  {
    countryNameEn: 'Bermuda',
    countryNameLocal: 'Bermuda',
    countryCode: 'BM',
    currencyCode: 'BMD',
    currencyNameEn: 'Bermudian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1441',
    region: 'North America',
    flag: '🇧🇲',
  },
  {
    countryNameEn: 'Brunei Darussalam',
    countryNameLocal: 'Brunei Darussalam',
    countryCode: 'BN',
    currencyCode: 'BND',
    currencyNameEn: 'Brunei dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ms',
    officialLanguageNameEn: 'Malay',
    officialLanguageNameLocal: 'Bahasa Melayu, بهاس ملايو‎',
    countryCallingCode: '673',
    region: 'Asia & Pacific',
    flag: '🇧🇳',
  },
  {
    countryNameEn: 'Bolivia (Plurinational State of)',
    countryNameLocal: 'Bolivia, Bulibiya, Volívia, Wuliwya',
    countryCode: 'BO',
    currencyCode: 'BOP',
    currencyNameEn: '',
    tinType: 'NIT',
    tinName: 'Número de Identificación Tributaria',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '591',
    region: 'South/Latin America',
    flag: '🇧🇴',
  },
  {
    countryNameEn: 'Bonaire, Sint Eustatius and Saba',
    countryNameLocal: 'Caribisch Nederland',
    countryCode: 'BQ',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '5997',
    region: 'Unknown',
    flag: '🇧🇶',
  },
  {
    countryNameEn: 'Brazil',
    countryNameLocal: 'Brasil',
    countryCode: 'BR',
    currencyCode: 'BRL',
    currencyNameEn: 'Brazilian real',
    tinType: 'CNPJ',
    tinName: 'Cadastro Nacional de Pessoa Jurídica',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '55',
    region: 'South/Latin America',
    flag: '🇧🇷',
  },
  {
    countryNameEn: 'Bhutan',
    countryNameLocal: 'འབྲུག་ཡུལ',
    countryCode: 'BT',
    currencyCode: 'BTN',
    currencyNameEn: 'Bhutanese ngultrum',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'dz',
    officialLanguageNameEn: 'Dzongkha',
    officialLanguageNameLocal: 'རྫོང་ཁ',
    countryCallingCode: '975',
    region: 'Asia & Pacific',
    flag: '🇧🇹',
  },
  {
    countryNameEn: 'Bouvet Island',
    countryNameLocal: 'Bouvetøya',
    countryCode: 'BV',
    currencyCode: 'NOK',
    currencyNameEn: 'Norwegian krone',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'no',
    officialLanguageNameEn: 'Norwegian',
    officialLanguageNameLocal: 'Norsk',
    countryCallingCode: '',
    region: 'South/Latin America',
    flag: '🇧🇻',
  },
  {
    countryNameEn: 'Botswana',
    countryNameLocal: 'Botswana',
    countryCode: 'BW',
    currencyCode: 'BWP',
    currencyNameEn: 'Botswana pula',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '267',
    region: 'Africa',
    flag: '🇧🇼',
  },
  {
    countryNameEn: 'Belarus',
    countryNameLocal: 'Беларусь',
    countryCode: 'BY',
    currencyCode: 'BYR',
    currencyNameEn: '',
    tinType: 'УНП (UNP)',
    tinName: 'Учетный номер плательщика',
    officialLanguageCode: 'be',
    officialLanguageNameEn: 'Belarusian',
    officialLanguageNameLocal: 'беларуская мова',
    countryCallingCode: '375',
    region: 'Europe',
    flag: '🇧🇾',
  },
  {
    countryNameEn: 'Belize',
    countryNameLocal: 'Belize',
    countryCode: 'BZ',
    currencyCode: 'BZD',
    currencyNameEn: 'Belize dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '501',
    region: 'South/Latin America',
    flag: '🇧🇿',
  },
  {
    countryNameEn: 'Canada',
    countryNameLocal: 'Canada',
    countryCode: 'CA',
    currencyCode: 'CAD',
    currencyNameEn: 'Canadian dollar',
    tinType: 'BN / NE',
    tinName: 'Business Number',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1',
    region: 'North America',
    flag: '🇨🇦',
  },
  {
    countryNameEn: 'Switzerland',
    countryNameLocal: 'Schweiz, Suisse, Svizzera, Svizra',
    countryCode: 'CH',
    currencyCode: 'CHE',
    currencyNameEn: 'WIR Euro (complementary currency)',
    tinType: 'MWST/TVA/IVA',
    tinName: 'Mehrwertsteuernummer',
    officialLanguageCode: 'de',
    officialLanguageNameEn: 'German',
    officialLanguageNameLocal: 'Deutsch',
    countryCallingCode: '41',
    region: 'Europe',
    flag: '🇨🇭',
  },
  {
    countryNameEn: "Côte d'Ivoire",
    countryNameLocal: "Côte d'Ivoire",
    countryCode: 'CI',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '',
    region: 'Africa',
    flag: '🇨🇮',
  },
  {
    countryNameEn: 'Chile',
    countryNameLocal: 'Chile',
    countryCode: 'CL',
    currencyCode: 'CLP',
    currencyNameEn: 'Chilean peso',
    tinType: 'RUT',
    tinName: 'Rol Único Tributario',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '56',
    region: 'South/Latin America',
    flag: '🇨🇱',
  },
  {
    countryNameEn: 'Cameroon',
    countryNameLocal: 'Cameroun, Cameroon',
    countryCode: 'CM',
    currencyCode: 'XAF',
    currencyNameEn: 'CFA franc BEAC',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '237',
    region: 'Africa',
    flag: '🇨🇲',
  },
  {
    countryNameEn: 'China',
    countryNameLocal: '中国',
    countryCode: 'CN',
    currencyCode: 'CNY',
    currencyNameEn: 'Renminbi (Chinese) yuan',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'zh-hans',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '86',
    region: 'Asia & Pacific',
    flag: '🇨🇳',
  },
  {
    countryNameEn: 'Colombia',
    countryNameLocal: 'Colombia',
    countryCode: 'CO',
    currencyCode: 'COP',
    currencyNameEn: 'Colombian peso',
    tinType: 'NIT',
    tinName: 'Número De Identificación Tributaria',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '57',
    region: 'South/Latin America',
    flag: '🇨🇴',
  },
  {
    countryNameEn: 'Costa Rica',
    countryNameLocal: 'Costa Rica',
    countryCode: 'CR',
    currencyCode: 'CRC',
    currencyNameEn: 'Costa Rican colon',
    tinType: '',
    tinName: 'Cédula Jurídica',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '506',
    region: 'South/Latin America',
    flag: '🇨🇷',
  },
  {
    countryNameEn: 'Cuba',
    countryNameLocal: 'Cuba',
    countryCode: 'CU',
    currencyCode: 'CUC',
    currencyNameEn: 'Cuban convertible peso',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '53',
    region: 'South/Latin America',
    flag: '🇨🇺',
  },
  {
    countryNameEn: 'Cabo Verde',
    countryNameLocal: 'Cabo Verde',
    countryCode: 'CV',
    currencyCode: 'CVE',
    currencyNameEn: 'Cape Verdean escudo',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '',
    region: 'Africa',
    flag: '🇨🇻',
  },
  {
    countryNameEn: 'Curaçao',
    countryNameLocal: 'Curaçao',
    countryCode: 'CW',
    currencyCode: 'ANG',
    currencyNameEn: 'Netherlands Antillean guilder',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '599',
    region: 'Unknown',
    flag: '🇨🇼',
  },
  {
    countryNameEn: 'Christmas Island',
    countryNameLocal: 'Christmas Island',
    countryCode: 'CX',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '61',
    region: 'Asia & Pacific',
    flag: '🇨🇽',
  },
  {
    countryNameEn: 'Cyprus',
    countryNameLocal: 'Κύπρος, Kibris',
    countryCode: 'CY',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'ΦΠΑ',
    tinName: 'Αριθμός Εγγραφής Φ.Π.Α.',
    officialLanguageCode: 'el',
    officialLanguageNameEn: 'Greek, Modern (1453-)',
    officialLanguageNameLocal: 'ελληνικά',
    countryCallingCode: '357',
    region: 'Europe',
    flag: '🇨🇾',
  },
  {
    countryNameEn: 'Germany',
    countryNameLocal: 'Deutschland',
    countryCode: 'DE',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'USt-IdNr.',
    tinName: 'Umsatzsteuer-Identifikationsnummer',
    officialLanguageCode: 'de',
    officialLanguageNameEn: 'German',
    officialLanguageNameLocal: 'Deutsch',
    countryCallingCode: '49',
    region: 'Europe',
    flag: '🇩🇪',
  },
  {
    countryNameEn: 'Djibouti',
    countryNameLocal: 'Djibouti, جيبوتي, Jabuuti, Gabuutih',
    countryCode: 'DJ',
    currencyCode: 'DJF',
    currencyNameEn: 'Djiboutian franc',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '253',
    region: 'Arab States',
    flag: '🇩🇯',
  },
  {
    countryNameEn: 'Denmark',
    countryNameLocal: 'Danmark',
    countryCode: 'DK',
    currencyCode: 'DKK',
    currencyNameEn: 'Danish krone',
    tinType: 'CVR',
    tinName: 'Momsregistreringsnummer',
    officialLanguageCode: 'da',
    officialLanguageNameEn: 'Danish',
    officialLanguageNameLocal: 'dansk',
    countryCallingCode: '45',
    region: 'Europe',
    flag: '🇩🇰',
  },
  {
    countryNameEn: 'Dominica',
    countryNameLocal: 'Dominica',
    countryCode: 'DM',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '767',
    region: 'South/Latin America',
    flag: '🇩🇲',
  },
  {
    countryNameEn: 'Algeria',
    countryNameLocal: 'الجزائر',
    countryCode: 'DZ',
    currencyCode: 'DZD',
    currencyNameEn: 'Algerian dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '213',
    region: 'Arab States',
    flag: '🇩🇿',
  },
  {
    countryNameEn: 'Ecuador',
    countryNameLocal: 'Ecuador',
    countryCode: 'EC',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: 'RUC',
    tinName: 'Número de Registro Unico de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '593',
    region: 'South/Latin America',
    flag: '🇪🇨',
  },
  {
    countryNameEn: 'Estonia',
    countryNameLocal: 'Eesti',
    countryCode: 'EE',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'KMKR',
    tinName: 'Käibemaksukohustuslase number',
    officialLanguageCode: 'et',
    officialLanguageNameEn: 'Estonian',
    officialLanguageNameLocal: 'eesti, eesti keel',
    countryCallingCode: '372',
    region: 'Europe',
    flag: '🇪🇪',
  },
  {
    countryNameEn: 'Egypt',
    countryNameLocal: 'مصر',
    countryCode: 'EG',
    currencyCode: 'EGP',
    currencyNameEn: 'Egyptian pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '20',
    region: 'Arab States',
    flag: '🇪🇬',
  },
  {
    countryNameEn: 'Western Sahara',
    countryNameLocal: 'Sahara Occidental',
    countryCode: 'EH',
    currencyCode: 'MAD',
    currencyNameEn: 'Moroccan dirham',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '',
    region: 'Africa',
    flag: '🇪🇭',
  },
  {
    countryNameEn: 'Eritrea',
    countryNameLocal: 'ኤርትራ, إرتريا, Eritrea',
    countryCode: 'ER',
    currencyCode: 'ERN',
    currencyNameEn: 'Eritrean nakfa',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ti',
    officialLanguageNameEn: 'Tigrinya',
    officialLanguageNameLocal: 'ትግርኛ',
    countryCallingCode: '291',
    region: 'Africa',
    flag: '🇪🇷',
  },
  {
    countryNameEn: 'Spain',
    countryNameLocal: 'España',
    countryCode: 'ES',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'NIF (CIF)',
    tinName: 'Número de Identificación Fiscal (formerly named Código de Identificación Fiscal)',
    officialLanguageCode: 'ast',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '34',
    region: 'Europe',
    flag: '🇪🇸',
  },
  {
    countryNameEn: 'Ethiopia',
    countryNameLocal: 'ኢትዮጵያ, Itoophiyaa',
    countryCode: 'ET',
    currencyCode: 'ETB',
    currencyNameEn: 'Ethiopian birr',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'am',
    officialLanguageNameEn: 'Amharic',
    officialLanguageNameLocal: 'አማርኛ',
    countryCallingCode: '251',
    region: 'Africa',
    flag: '🇪🇹',
  },
  {
    countryNameEn: 'Finland',
    countryNameLocal: 'Suomi',
    countryCode: 'FI',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'ALV nro',
    tinName: 'Arvonlisäveronumero',
    officialLanguageCode: 'fi',
    officialLanguageNameEn: 'Finnish',
    officialLanguageNameLocal: 'suomi, suomen kieli',
    countryCallingCode: '358',
    region: 'Europe',
    flag: '🇫🇮',
  },
  {
    countryNameEn: 'Fiji',
    countryNameLocal: 'Fiji',
    countryCode: 'FJ',
    currencyCode: 'FJD',
    currencyNameEn: 'Fiji dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '679',
    region: 'Asia & Pacific',
    flag: '🇫🇯',
  },
  {
    countryNameEn: 'Micronesia (Federated States of)',
    countryNameLocal: 'Micronesia',
    countryCode: 'FM',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '691',
    region: 'Asia & Pacific',
    flag: '🇫🇲',
  },
  {
    countryNameEn: 'France',
    countryNameLocal: 'France',
    countryCode: 'FR',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'n° TVA',
    tinName: "Numéro d'identification à la taxe sur la valeur ajoutée / Numéro de TVA intracommunautaire",
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '33',
    region: 'Europe',
    flag: '🇫🇷',
  },
  {
    countryNameEn: 'Gabon',
    countryNameLocal: 'Gabon',
    countryCode: 'GA',
    currencyCode: 'XAF',
    currencyNameEn: 'CFA franc BEAC',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '241',
    region: 'Africa',
    flag: '🇬🇦',
  },
  {
    countryNameEn: 'Grenada',
    countryNameLocal: 'Grenada',
    countryCode: 'GD',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1473',
    region: 'South/Latin America',
    flag: '🇬🇩',
  },
  {
    countryNameEn: 'Georgia',
    countryNameLocal: 'საქართველო',
    countryCode: 'GE',
    currencyCode: 'GEL',
    currencyNameEn: 'Georgian lari',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ka',
    officialLanguageNameEn: 'Georgian',
    officialLanguageNameLocal: 'ქართული',
    countryCallingCode: '995',
    region: 'Europe',
    flag: '🇬🇪',
  },
  {
    countryNameEn: 'French Guiana',
    countryNameLocal: 'Guyane française',
    countryCode: 'GF',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '594',
    region: 'South/Latin America',
    flag: '🇬🇫',
  },
  {
    countryNameEn: 'Guernsey',
    countryNameLocal: 'Guernsey',
    countryCode: 'GG',
    currencyCode: 'GBP',
    currencyNameEn: 'Pound sterling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '44',
    region: 'Europe',
    flag: '🇬🇬',
  },
  {
    countryNameEn: 'Ghana',
    countryNameLocal: 'Ghana',
    countryCode: 'GH',
    currencyCode: 'GHS',
    currencyNameEn: 'Ghanaian cedi',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '233',
    region: 'Africa',
    flag: '🇬🇭',
  },
  {
    countryNameEn: 'Gibraltar',
    countryNameLocal: 'Gibraltar',
    countryCode: 'GI',
    currencyCode: 'GIP',
    currencyNameEn: 'Gibraltar pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '350',
    region: 'Europe',
    flag: '🇬🇮',
  },
  {
    countryNameEn: 'Greenland',
    countryNameLocal: 'Kalaallit Nunaat, Grønland',
    countryCode: 'GL',
    currencyCode: 'DKK',
    currencyNameEn: 'Danish krone',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'kl',
    officialLanguageNameEn: 'Kalaallisut, Greenlandic',
    officialLanguageNameLocal: 'kalaallisut, kalaallit oqaasii',
    countryCallingCode: '299',
    region: 'Europe',
    flag: '🇬🇱',
  },
  {
    countryNameEn: 'Guinea',
    countryNameLocal: 'Guinée',
    countryCode: 'GN',
    currencyCode: 'GNF',
    currencyNameEn: 'Guinean franc',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '224',
    region: 'Africa',
    flag: '🇬🇳',
  },
  {
    countryNameEn: 'Guadeloupe',
    countryNameLocal: 'Guadeloupe',
    countryCode: 'GP',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '590',
    region: 'South/Latin America',
    flag: '🇬🇵',
  },
  {
    countryNameEn: 'Equatorial Guinea',
    countryNameLocal: 'Guiena ecuatorial, Guinée équatoriale, Guiné Equatorial',
    countryCode: 'GQ',
    currencyCode: 'XAF',
    currencyNameEn: 'CFA franc BEAC',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '240',
    region: 'Africa',
    flag: '🇬🇶',
  },
  {
    countryNameEn: 'Greece',
    countryNameLocal: 'Ελλάδα',
    countryCode: 'GR',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'el',
    officialLanguageNameEn: 'Greek, Modern (1453-)',
    officialLanguageNameLocal: 'ελληνικά',
    countryCallingCode: '30',
    region: 'Europe',
    flag: '🇬🇷',
  },
  {
    countryNameEn: 'South Georgia and the South Sandwich Islands',
    countryNameLocal: 'South Georgia and the South Sandwich Islands',
    countryCode: 'GS',
    currencyCode: 'FKP',
    currencyNameEn: 'Falkland Islands pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '500',
    region: 'South/Latin America',
    flag: '🇬🇸',
  },
  {
    countryNameEn: 'Guatemala',
    countryNameLocal: 'Guatemala',
    countryCode: 'GT',
    currencyCode: 'GTQ',
    currencyNameEn: 'Guatemalan quetzal',
    tinType: 'NIT',
    tinName: 'Número de Identificación Tributaria',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '502',
    region: 'South/Latin America',
    flag: '🇬🇹',
  },
  {
    countryNameEn: 'Guam',
    countryNameLocal: 'Guam, Guåhån',
    countryCode: 'GU',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1',
    region: 'Asia & Pacific',
    flag: '🇬🇺',
  },
  {
    countryNameEn: 'Guinea-Bissau',
    countryNameLocal: 'Guiné-Bissau',
    countryCode: 'GW',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '245',
    region: 'Africa',
    flag: '🇬🇼',
  },
  {
    countryNameEn: 'Guyana',
    countryNameLocal: 'Guyana',
    countryCode: 'GY',
    currencyCode: 'GYD',
    currencyNameEn: 'Guyanese dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '592',
    region: 'South/Latin America',
    flag: '🇬🇾',
  },
  {
    countryNameEn: 'Hong Kong',
    countryNameLocal: '香港, Hong Kong',
    countryCode: 'HK',
    currencyCode: 'HKD',
    currencyNameEn: 'Hong Kong dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'zh-hant',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '852',
    region: 'Asia & Pacific',
    flag: '🇭🇰',
  },
  {
    countryNameEn: 'Honduras',
    countryNameLocal: 'Honduras',
    countryCode: 'HN',
    currencyCode: 'HNL',
    currencyNameEn: 'Honduran lempira',
    tinType: 'RTN',
    tinName: 'Registro Tributario Nacional',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '504',
    region: 'South/Latin America',
    flag: '🇭🇳',
  },
  {
    countryNameEn: 'Croatia',
    countryNameLocal: 'Hrvatska',
    countryCode: 'HR',
    currencyCode: 'HRK',
    currencyNameEn: 'Croatian kuna',
    tinType: 'PDV-ID; OIB',
    tinName: 'PDV Id. Broj OIB',
    officialLanguageCode: 'hr',
    officialLanguageNameEn: 'Croatian',
    officialLanguageNameLocal: 'hrvatski jezik',
    countryCallingCode: '385',
    region: 'Europe',
    flag: '🇭🇷',
  },
  {
    countryNameEn: 'Haiti',
    countryNameLocal: 'Haïti, Ayiti',
    countryCode: 'HT',
    currencyCode: 'HTG',
    currencyNameEn: 'Haitian gourde',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '509',
    region: 'South/Latin America',
    flag: '🇭🇹',
  },
  {
    countryNameEn: 'Hungary',
    countryNameLocal: 'Magyarország',
    countryCode: 'HU',
    currencyCode: 'HUF',
    currencyNameEn: 'Hungarian forint',
    tinType: 'ANUM',
    tinName: 'Közösségi adószám',
    officialLanguageCode: 'hu',
    officialLanguageNameEn: 'Hungarian',
    officialLanguageNameLocal: 'magyar',
    countryCallingCode: '36',
    region: 'Europe',
    flag: '🇭🇺',
  },
  {
    countryNameEn: 'Indonesia',
    countryNameLocal: 'Indonesia',
    countryCode: 'ID',
    currencyCode: 'IDR',
    currencyNameEn: 'Indonesian rupiah',
    tinType: 'NPWP',
    tinName: 'Nomor Pokok Wajib Pajak',
    officialLanguageCode: 'id',
    officialLanguageNameEn: 'Indonesian',
    officialLanguageNameLocal: 'Bahasa Indonesia',
    countryCallingCode: '62',
    region: 'Asia & Pacific',
    flag: '🇮🇩',
  },
  {
    countryNameEn: 'Ireland',
    countryNameLocal: 'Ireland, Éire',
    countryCode: 'IE',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'VAT or CBL',
    tinName: 'Value added tax identification no.',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '353',
    region: 'Europe',
    flag: '🇮🇪',
  },
  {
    countryNameEn: 'Israel',
    countryNameLocal: 'ישראל',
    countryCode: 'IL',
    currencyCode: 'ILS',
    currencyNameEn: 'Israeli new shekel',
    tinType: '',
    tinName: 'מס\' עוסק מורשה / ח"פ',
    officialLanguageCode: 'he',
    officialLanguageNameEn: 'Hebrew',
    officialLanguageNameLocal: 'עברית',
    countryCallingCode: '972',
    region: 'Europe',
    flag: '🇮🇱',
  },
  {
    countryNameEn: 'Isle of Man',
    countryNameLocal: 'Isle of Man',
    countryCode: 'IM',
    currencyCode: 'GBP',
    currencyNameEn: 'Pound sterling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '44',
    region: 'Europe',
    flag: '🇮🇲',
  },
  {
    countryNameEn: 'India',
    countryNameLocal: 'भारत, India',
    countryCode: 'IN',
    currencyCode: 'INR',
    currencyNameEn: 'Indian rupee',
    tinType: 'VAT TIN / CST TIN',
    tinName:
      'Value Added Tax - Taxpayer Identification Number / Central Sales Tax - Taxpayer Identification Number (In most states)Not applicable',
    officialLanguageCode: 'hi',
    officialLanguageNameEn: 'Hindi',
    officialLanguageNameLocal: 'हिन्दी, हिंदी',
    countryCallingCode: '91',
    region: 'Asia & Pacific',
    flag: '🇮🇳',
  },
  {
    countryNameEn: 'Iraq',
    countryNameLocal: 'العراق, Iraq',
    countryCode: 'IQ',
    currencyCode: 'IQD',
    currencyNameEn: 'Iraqi dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '964',
    region: 'Arab States',
    flag: '🇮🇶',
  },
  {
    countryNameEn: 'Iran (Islamic Republic of)',
    countryNameLocal: 'ایران',
    countryCode: 'IR',
    currencyCode: 'IRR',
    currencyNameEn: 'Iranian rial',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fa',
    officialLanguageNameEn: 'Persian',
    officialLanguageNameLocal: 'فارسی',
    countryCallingCode: '98',
    region: 'Asia & Pacific',
    flag: '🇮🇷',
  },
  {
    countryNameEn: 'Iceland',
    countryNameLocal: 'Ísland',
    countryCode: 'IS',
    currencyCode: 'ISK',
    currencyNameEn: 'Icelandic króna',
    tinType: 'VSK / VASK',
    tinName: 'Virðisaukaskattsnúmer',
    officialLanguageCode: 'is',
    officialLanguageNameEn: 'Icelandic',
    officialLanguageNameLocal: 'Íslenska',
    countryCallingCode: '354',
    region: 'Europe',
    flag: '🇮🇸',
  },
  {
    countryNameEn: 'Italy',
    countryNameLocal: 'Italia',
    countryCode: 'IT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'P.IVA',
    tinName: 'Partita IVA(IVA = Imposta sul Valore Aggiunto)',
    officialLanguageCode: 'it',
    officialLanguageNameEn: 'Italian',
    officialLanguageNameLocal: 'Italiano',
    countryCallingCode: '39',
    region: 'Europe',
    flag: '🇮🇹',
  },
  {
    countryNameEn: 'Jersey',
    countryNameLocal: 'Jersey',
    countryCode: 'JE',
    currencyCode: 'GBP',
    currencyNameEn: 'Pound sterling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '44',
    region: 'Europe',
    flag: '🇯🇪',
  },
  {
    countryNameEn: 'Jamaica',
    countryNameLocal: 'Jamaica',
    countryCode: 'JM',
    currencyCode: 'JMD',
    currencyNameEn: 'Jamaican dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '876',
    region: 'South/Latin America',
    flag: '🇯🇲',
  },
  {
    countryNameEn: 'Jordan',
    countryNameLocal: 'الأُرْدُن',
    countryCode: 'JO',
    currencyCode: 'JOD',
    currencyNameEn: 'Jordanian dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '962',
    region: 'Arab States',
    flag: '🇯🇴',
  },
  {
    countryNameEn: 'Japan',
    countryNameLocal: '日本',
    countryCode: 'JP',
    currencyCode: 'JPY',
    currencyNameEn: 'Japanese yen',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ja',
    officialLanguageNameEn: 'Japanese',
    officialLanguageNameLocal: '日本語 (にほんご)',
    countryCallingCode: '81',
    region: 'Asia & Pacific',
    flag: '🇯🇵',
  },
  {
    countryNameEn: 'Kenya',
    countryNameLocal: 'Kenya',
    countryCode: 'KE',
    currencyCode: 'KES',
    currencyNameEn: 'Kenyan shilling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'sw',
    officialLanguageNameEn: 'Swahili',
    officialLanguageNameLocal: 'Kiswahili',
    countryCallingCode: '254',
    region: 'Africa',
    flag: '🇰🇪',
  },
  {
    countryNameEn: 'Kyrgyzstan',
    countryNameLocal: 'Кыргызстан, Киргизия',
    countryCode: 'KG',
    currencyCode: 'KGS',
    currencyNameEn: 'Kyrgyzstani som',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ky',
    officialLanguageNameEn: 'Kirghiz, Kyrgyz',
    officialLanguageNameLocal: 'Кыргызча, Кыргыз тили',
    countryCallingCode: '996',
    region: 'Asia & Pacific',
    flag: '🇰🇬',
  },
  {
    countryNameEn: 'Cambodia',
    countryNameLocal: 'កម្ពុជា',
    countryCode: 'KH',
    currencyCode: 'KHR',
    currencyNameEn: 'Cambodian riel',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'km',
    officialLanguageNameEn: 'Central Khmer',
    officialLanguageNameLocal: 'ខ្មែរ, ខេមរភាសា, ភាសាខ្មែរ',
    countryCallingCode: '855',
    region: 'Asia & Pacific',
    flag: '🇰🇭',
  },
  {
    countryNameEn: 'North Korea',
    countryNameLocal: '조선민주주의인민공화국',
    countryCode: 'KP',
    currencyCode: 'KPW',
    currencyNameEn: 'North Korean won',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ko',
    officialLanguageNameEn: 'Korean',
    officialLanguageNameLocal: '조선어',
    countryCallingCode: '850',
    region: 'Asia',
    flag: '🇰🇵',
  },
  {
    countryNameEn: 'South Korea',
    countryNameLocal: '대한민국',
    countryCode: 'KR',
    currencyCode: 'KRW',
    currencyNameEn: 'South Korean won',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ko',
    officialLanguageNameEn: 'Korean',
    officialLanguageNameLocal: '한국어',
    countryCallingCode: '82',
    region: 'Asia',
    flag: '🇰🇷',
  },
  {
    countryNameEn: 'Kiribati',
    countryNameLocal: 'Kiribati',
    countryCode: 'KI',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '686',
    region: 'Asia & Pacific',
    flag: '🇰🇮',
  },
  {
    countryNameEn: 'Saint Kitts and Nevis',
    countryNameLocal: 'Saint Kitts and Nevis',
    countryCode: 'KN',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1869',
    region: 'South/Latin America',
    flag: '🇰🇳',
  },
  {
    countryNameEn: 'Kuwait',
    countryNameLocal: 'الكويت',
    countryCode: 'KW',
    currencyCode: 'KWD',
    currencyNameEn: 'Kuwaiti dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '965',
    region: 'Arab States',
    flag: '🇰🇼',
  },
  {
    countryNameEn: 'Kazakhstan',
    countryNameLocal: 'Қазақстан, Казахстан',
    countryCode: 'KZ',
    currencyCode: 'KZT',
    currencyNameEn: 'Kazakhstani tenge',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'kk',
    officialLanguageNameEn: 'Kazakh',
    officialLanguageNameLocal: 'қазақ тілі',
    countryCallingCode: '7',
    region: 'Asia & Pacific',
    flag: '🇰🇿',
  },
  {
    countryNameEn: 'Lebanon',
    countryNameLocal: 'لبنان, Liban',
    countryCode: 'LB',
    currencyCode: 'LBP',
    currencyNameEn: 'Lebanese pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '961',
    region: 'Arab States',
    flag: '🇱🇧',
  },
  {
    countryNameEn: 'Saint Lucia',
    countryNameLocal: 'Saint Lucia',
    countryCode: 'LC',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1758',
    region: 'South/Latin America',
    flag: '🇱🇨',
  },
  {
    countryNameEn: 'Liechtenstein',
    countryNameLocal: 'Liechtenstein',
    countryCode: 'LI',
    currencyCode: 'CHF',
    currencyNameEn: 'Swiss franc',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'de',
    officialLanguageNameEn: 'German',
    officialLanguageNameLocal: 'Deutsch',
    countryCallingCode: '423',
    region: 'Europe',
    flag: '🇱🇮',
  },
  {
    countryNameEn: 'Sri Lanka',
    countryNameLocal: 'ශ්‍රී ලංකා, இலங்கை',
    countryCode: 'LK',
    currencyCode: 'LKR',
    currencyNameEn: 'Sri Lankan rupee',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'si',
    officialLanguageNameEn: 'Sinhala, Sinhalese',
    officialLanguageNameLocal: 'සිංහල',
    countryCallingCode: '94',
    region: 'Asia & Pacific',
    flag: '🇱🇰',
  },
  {
    countryNameEn: 'Liberia',
    countryNameLocal: 'Liberia',
    countryCode: 'LR',
    currencyCode: 'LRD',
    currencyNameEn: 'Liberian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '231',
    region: 'Africa',
    flag: '🇱🇷',
  },
  {
    countryNameEn: 'Lesotho',
    countryNameLocal: 'Lesotho',
    countryCode: 'LS',
    currencyCode: 'LSL',
    currencyNameEn: 'Lesotho loti',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '266',
    region: 'Africa',
    flag: '🇱🇸',
  },
  {
    countryNameEn: 'Lithuania',
    countryNameLocal: 'Lietuva',
    countryCode: 'LT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'PVM kodas',
    tinName: 'PVM (abbrev. Pridėtinės vertės mokestis) mokėtojo kodas',
    officialLanguageCode: 'lt',
    officialLanguageNameEn: 'Lithuanian',
    officialLanguageNameLocal: 'lietuvių kalba',
    countryCallingCode: '370',
    region: 'Europe',
    flag: '🇱🇹',
  },
  {
    countryNameEn: 'Luxembourg',
    countryNameLocal: 'Lëtzebuerg, Luxembourg, Luxemburg',
    countryCode: 'LU',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'No. TVA',
    tinName: "Numéro d'identification à la taxe sur la valeur ajoutée",
    officialLanguageCode: 'lb',
    officialLanguageNameEn: 'Luxembourgish, Letzeburgesch',
    officialLanguageNameLocal: 'Lëtzebuergesch',
    countryCallingCode: '352',
    region: 'Europe',
    flag: '🇱🇺',
  },
  {
    countryNameEn: 'Latvia',
    countryNameLocal: 'Latvija',
    countryCode: 'LV',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'PVN',
    tinName: 'Pievienotās vērtības nodokļa (PVN) reģistrācijas numurs',
    officialLanguageCode: 'lv',
    officialLanguageNameEn: 'Latvian',
    officialLanguageNameLocal: 'latviešu valoda',
    countryCallingCode: '371',
    region: 'Europe',
    flag: '🇱🇻',
  },
  {
    countryNameEn: 'Libya',
    countryNameLocal: 'ليبيا',
    countryCode: 'LY',
    currencyCode: 'LYD',
    currencyNameEn: 'Libyan dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '218',
    region: 'Arab States',
    flag: '🇱🇾',
  },
  {
    countryNameEn: 'Morocco',
    countryNameLocal: 'Maroc, ⵍⵎⵖⵔⵉⴱ, المغرب',
    countryCode: 'MA',
    currencyCode: 'MAD',
    currencyNameEn: 'Moroccan dirham',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '212',
    region: 'Arab States',
    flag: '🇲🇦',
  },
  {
    countryNameEn: 'Monaco',
    countryNameLocal: 'Monaco',
    countryCode: 'MC',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '377',
    region: 'Europe',
    flag: '🇲🇨',
  },
  {
    countryNameEn: 'Montenegro',
    countryNameLocal: 'Crna Gora, Црна Гора',
    countryCode: 'ME',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'srp',
    officialLanguageNameEn: 'српски језик',
    officialLanguageNameLocal: '',
    countryCallingCode: '382',
    region: 'Europe',
    flag: '🇲🇪',
  },
  {
    countryNameEn: 'Saint Martin (French part)',
    countryNameLocal: 'Saint-Martin',
    countryCode: 'MF',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '590',
    region: 'South/Latin America',
    flag: '🇲🇫',
  },
  {
    countryNameEn: 'Madagascar',
    countryNameLocal: 'Madagasikara, Madagascar',
    countryCode: 'MG',
    currencyCode: 'MGA',
    currencyNameEn: 'Malagasy ariary',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'mg',
    officialLanguageNameEn: 'Malagasy',
    officialLanguageNameLocal: 'fiteny malagasy',
    countryCallingCode: '261',
    region: 'Africa',
    flag: '🇲🇬',
  },
  {
    countryNameEn: 'Mali',
    countryNameLocal: 'Mali',
    countryCode: 'ML',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '223',
    region: 'Africa',
    flag: '🇲🇱',
  },
  {
    countryNameEn: 'Myanmar',
    countryNameLocal: 'မြန်မာ',
    countryCode: 'MM',
    currencyCode: 'MMK',
    currencyNameEn: 'Myanmar kyat',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'my',
    officialLanguageNameEn: 'Burmese',
    officialLanguageNameLocal: 'ဗမာစာ',
    countryCallingCode: '95',
    region: 'Asia & Pacific',
    flag: '🇲🇲',
  },
  {
    countryNameEn: 'Mongolia',
    countryNameLocal: 'Монгол Улс',
    countryCode: 'MN',
    currencyCode: 'MNT',
    currencyNameEn: 'Mongolian tögrög',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'mn',
    officialLanguageNameEn: 'Mongolian',
    officialLanguageNameLocal: 'Монгол хэл',
    countryCallingCode: '976',
    region: 'Asia & Pacific',
    flag: '🇲🇳',
  },
  {
    countryNameEn: 'Macao',
    countryNameLocal: '澳門, Macau',
    countryCode: 'MO',
    currencyCode: 'MOP',
    currencyNameEn: 'Macanese pataca',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'zh-hant',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '',
    region: 'Asia & Pacific',
    flag: '🇲🇴',
  },
  {
    countryNameEn: 'Martinique',
    countryNameLocal: 'Martinique',
    countryCode: 'MQ',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '596',
    region: 'South/Latin America',
    flag: '🇲🇶',
  },
  {
    countryNameEn: 'Mauritania',
    countryNameLocal: 'موريتانيا, Mauritanie',
    countryCode: 'MR',
    currencyCode: 'MRU',
    currencyNameEn: '',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '222',
    region: 'Arab States',
    flag: '🇲🇷',
  },
  {
    countryNameEn: 'Montserrat',
    countryNameLocal: 'Montserrat',
    countryCode: 'MS',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1664',
    region: 'South/Latin America',
    flag: '🇲🇸',
  },
  {
    countryNameEn: 'Malta',
    countryNameLocal: 'Malta',
    countryCode: 'MT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'Vat No.',
    tinName: 'Vat reg. no.',
    officialLanguageCode: 'mt',
    officialLanguageNameEn: 'Maltese',
    officialLanguageNameLocal: 'Malti',
    countryCallingCode: '356',
    region: 'Europe',
    flag: '🇲🇹',
  },
  {
    countryNameEn: 'Mauritius',
    countryNameLocal: 'Maurice, Mauritius',
    countryCode: 'MU',
    currencyCode: 'MUR',
    currencyNameEn: 'Mauritian rupee',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'mfe',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '230',
    region: 'Africa',
    flag: '🇲🇺',
  },
  {
    countryNameEn: 'Maldives',
    countryNameLocal: '',
    countryCode: 'MV',
    currencyCode: 'MVR',
    currencyNameEn: 'Maldivian rufiyaa',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'dv',
    officialLanguageNameEn: 'Divehi, Dhivehi, Maldivian',
    officialLanguageNameLocal: 'ދިވެހި',
    countryCallingCode: '960',
    region: 'Asia & Pacific',
    flag: '🇲🇻',
  },
  {
    countryNameEn: 'Malawi',
    countryNameLocal: 'Malawi',
    countryCode: 'MW',
    currencyCode: 'MWK',
    currencyNameEn: 'Malawian kwacha',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '265',
    region: 'Africa',
    flag: '🇲🇼',
  },
  {
    countryNameEn: 'Mexico',
    countryNameLocal: 'México',
    countryCode: 'MX',
    currencyCode: 'MXN',
    currencyNameEn: 'Mexican peso',
    tinType: 'RFC',
    tinName: 'Registro Federal de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '52',
    region: 'South/Latin America',
    flag: '🇲🇽',
  },
  {
    countryNameEn: 'Malaysia',
    countryNameLocal: '',
    countryCode: 'MY',
    currencyCode: 'MYR',
    currencyNameEn: 'Malaysian ringgit',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ms',
    officialLanguageNameEn: 'Malay',
    officialLanguageNameLocal: 'Bahasa Melayu, بهاس ملايو‎',
    countryCallingCode: '60',
    region: 'Asia & Pacific',
    flag: '🇲🇾',
  },
  {
    countryNameEn: 'Mozambique',
    countryNameLocal: 'Mozambique',
    countryCode: 'MZ',
    currencyCode: 'MZN',
    currencyNameEn: 'Mozambican metical',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '258',
    region: 'Africa',
    flag: '🇲🇿',
  },
  {
    countryNameEn: 'Namibia',
    countryNameLocal: 'Namibia',
    countryCode: 'NA',
    currencyCode: 'NAD',
    currencyNameEn: 'Namibian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '264',
    region: 'Africa',
    flag: '🇳🇦',
  },
  {
    countryNameEn: 'New Caledonia',
    countryNameLocal: 'Nouvelle-Calédonie',
    countryCode: 'NC',
    currencyCode: 'XPF',
    currencyNameEn: 'CFP franc (franc Pacifique)',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '687',
    region: 'Asia & Pacific',
    flag: '🇳🇨',
  },
  {
    countryNameEn: 'Norfolk Island',
    countryNameLocal: 'Norfolk Island',
    countryCode: 'NF',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '672',
    region: 'Asia & Pacific',
    flag: '🇳🇫',
  },
  {
    countryNameEn: 'Nigeria',
    countryNameLocal: 'Nigeria',
    countryCode: 'NG',
    currencyCode: 'NGN',
    currencyNameEn: 'Nigerian naira',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '234',
    region: 'Africa',
    flag: '🇳🇬',
  },
  {
    countryNameEn: 'Nicaragua',
    countryNameLocal: 'Nicaragua',
    countryCode: 'NI',
    currencyCode: 'NIO',
    currencyNameEn: 'Nicaraguan córdoba',
    tinType: 'RUC',
    tinName: 'Registro Unico de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '505',
    region: 'South/Latin America',
    flag: '🇳🇮',
  },
  {
    countryNameEn: 'Norway',
    countryNameLocal: 'Norge, Noreg',
    countryCode: 'NO',
    currencyCode: 'NOK',
    currencyNameEn: 'Norwegian krone',
    tinType: 'Orgnr',
    tinName: 'Organisasjonsnummer',
    officialLanguageCode: 'nb',
    officialLanguageNameEn: 'Norwegian Bokmål',
    officialLanguageNameLocal: 'Norsk Bokmål',
    countryCallingCode: '47',
    region: 'Europe',
    flag: '🇳🇴',
  },
  {
    countryNameEn: 'Nepal',
    countryNameLocal: '',
    countryCode: 'NP',
    currencyCode: 'NPR',
    currencyNameEn: 'Nepalese rupee',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ne',
    officialLanguageNameEn: 'Nepali',
    officialLanguageNameLocal: 'नेपाली',
    countryCallingCode: '977',
    region: 'Asia & Pacific',
    flag: '🇳🇵',
  },
  {
    countryNameEn: 'Nauru',
    countryNameLocal: 'Nauru',
    countryCode: 'NR',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'na',
    officialLanguageNameEn: 'Nauru',
    officialLanguageNameLocal: 'Dorerin Naoero',
    countryCallingCode: '674',
    region: 'Asia & Pacific',
    flag: '🇳🇷',
  },
  {
    countryNameEn: 'Niue',
    countryNameLocal: 'Niue',
    countryCode: 'NU',
    currencyCode: 'NZD',
    currencyNameEn: 'New Zealand dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'niu',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '683',
    region: 'Asia & Pacific',
    flag: '🇳🇺',
  },
  {
    countryNameEn: 'New Zealand',
    countryNameLocal: 'New Zealand',
    countryCode: 'NZ',
    currencyCode: 'NZD',
    currencyNameEn: 'New Zealand dollar',
    tinType: 'NZBN',
    tinName: 'NZ Business Number',
    officialLanguageCode: 'mi',
    officialLanguageNameEn: 'Maori',
    officialLanguageNameLocal: 'te reo Māori',
    countryCallingCode: '64',
    region: 'Asia & Pacific',
    flag: '🇳🇿',
  },
  {
    countryNameEn: 'Oman',
    countryNameLocal: 'سلطنة عُمان',
    countryCode: 'OM',
    currencyCode: 'OMR',
    currencyNameEn: 'Omani rial',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '968',
    region: 'Arab States',
    flag: '🇴🇲',
  },
  {
    countryNameEn: 'Panama',
    countryNameLocal: 'Panama',
    countryCode: 'PA',
    currencyCode: 'PAB',
    currencyNameEn: 'Panamanian balboa',
    tinType: 'RUC',
    tinName: 'Registro Unico de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '507',
    region: 'South/Latin America',
    flag: '🇵🇦',
  },
  {
    countryNameEn: 'Peru',
    countryNameLocal: 'Perú',
    countryCode: 'PE',
    currencyCode: 'PEN',
    currencyNameEn: 'Peruvian sol',
    tinType: 'RUC',
    tinName: 'Registro Unico de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '51',
    region: 'South/Latin America',
    flag: '🇵🇪',
  },
  {
    countryNameEn: 'French Polynesia',
    countryNameLocal: 'Polynésie française',
    countryCode: 'PF',
    currencyCode: 'XPF',
    currencyNameEn: 'CFP franc (franc Pacifique)',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '689',
    region: 'Asia & Pacific',
    flag: '🇵🇫',
  },
  {
    countryNameEn: 'Papua New Guinea',
    countryNameLocal: 'Papua New Guinea',
    countryCode: 'PG',
    currencyCode: 'PGK',
    currencyNameEn: 'Papua New Guinean kina',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '675',
    region: 'Asia & Pacific',
    flag: '🇵🇬',
  },
  {
    countryNameEn: 'Pakistan',
    countryNameLocal: 'پاکستان',
    countryCode: 'PK',
    currencyCode: 'PKR',
    currencyNameEn: 'Pakistani rupee',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '92',
    region: 'Asia & Pacific',
    flag: '🇵🇰',
  },
  {
    countryNameEn: 'Poland',
    countryNameLocal: 'Polska',
    countryCode: 'PL',
    currencyCode: 'PLN',
    currencyNameEn: 'Polish złoty',
    tinType: 'NIP',
    tinName: 'numer identyfikacji podatkowej',
    officialLanguageCode: 'pl',
    officialLanguageNameEn: 'Polish',
    officialLanguageNameLocal: 'język polski, polszczyzna',
    countryCallingCode: '48',
    region: 'Europe',
    flag: '🇵🇱',
  },
  {
    countryNameEn: 'Saint Pierre and Miquelon',
    countryNameLocal: 'Saint-Pierre-et-Miquelon',
    countryCode: 'PM',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '508',
    region: 'North America',
    flag: '🇵🇲',
  },
  {
    countryNameEn: 'Pitcairn',
    countryNameLocal: 'Pitcairn',
    countryCode: 'PN',
    currencyCode: 'NZD',
    currencyNameEn: 'New Zealand dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '64',
    region: 'Asia & Pacific',
    flag: '🇵🇳',
  },
  {
    countryNameEn: 'Puerto Rico',
    countryNameLocal: 'Puerto Rico',
    countryCode: 'PR',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '1',
    region: 'South/Latin America',
    flag: '🇵🇷',
  },
  {
    countryNameEn: 'State of Palestine',
    countryNameLocal: 'Palestinian Territory',
    countryCode: 'PS',
    currencyCode: 'ILS',
    currencyNameEn: 'Israeli new shekel',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '970',
    region: 'Arab States',
    flag: '🇵🇸',
  },
  {
    countryNameEn: 'Portugal',
    countryNameLocal: 'Portugal',
    countryCode: 'PT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'NIPC',
    tinName: 'Número de Identificação de Pessoa Coletiva (NIPC)',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '351',
    region: 'Europe',
    flag: '🇵🇹',
  },
  {
    countryNameEn: 'Palau',
    countryNameLocal: 'Palau',
    countryCode: 'PW',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '680',
    region: 'Asia & Pacific',
    flag: '🇵🇼',
  },
  {
    countryNameEn: 'Paraguay',
    countryNameLocal: 'Paraguay',
    countryCode: 'PY',
    currencyCode: 'PYG',
    currencyNameEn: 'Paraguayan guaraní',
    tinType: 'RUC',
    tinName: 'Registro Unico de Contribuyentes',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '595',
    region: 'South/Latin America',
    flag: '🇵🇾',
  },
  {
    countryNameEn: 'Qatar',
    countryNameLocal: 'قطر',
    countryCode: 'QA',
    currencyCode: 'QAR',
    currencyNameEn: 'Qatari riyal',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '974',
    region: 'Arab States',
    flag: '🇶🇦',
  },
  {
    countryNameEn: 'Réunion',
    countryNameLocal: 'La Réunion',
    countryCode: 'RE',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '262',
    region: 'Asia & Pacific',
    flag: '🇷🇪',
  },
  {
    countryNameEn: 'Romania',
    countryNameLocal: 'România',
    countryCode: 'RO',
    currencyCode: 'RON',
    currencyNameEn: 'Romanian leu',
    tinType: 'CIF',
    tinName: 'Codul de identificare fiscală',
    officialLanguageCode: 'ro',
    officialLanguageNameEn: 'Romanian, Moldavian, Moldovan',
    officialLanguageNameLocal: 'Română',
    countryCallingCode: '40',
    region: 'Europe',
    flag: '🇷🇴',
  },
  {
    countryNameEn: 'Serbia',
    countryNameLocal: 'Србија',
    countryCode: 'RS',
    currencyCode: 'RSD',
    currencyNameEn: 'Serbian dinar',
    tinType: 'PIB',
    tinName: 'Poreski identifikacioni broj',
    officialLanguageCode: 'sr',
    officialLanguageNameEn: 'Serbian',
    officialLanguageNameLocal: 'српски језик',
    countryCallingCode: '381',
    region: 'Europe',
    flag: '🇷🇸',
  },
  {
    countryNameEn: 'Russia',
    countryNameLocal: 'Россия',
    countryCode: 'RU',
    currencyCode: 'RUB',
    currencyNameEn: 'Russian ruble',
    tinType: 'ИНН',
    tinName: 'Идентификационный номер налогоплательщика',
    officialLanguageCode: 'ru',
    officialLanguageNameEn: 'Russian',
    officialLanguageNameLocal: 'русский',
    countryCallingCode: '7',
    region: 'Europe',
    flag: '🇷🇺',
  },
  {
    countryNameEn: 'Rwanda',
    countryNameLocal: 'Rwanda',
    countryCode: 'RW',
    currencyCode: 'RWF',
    currencyNameEn: 'Rwandan franc',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'rw',
    officialLanguageNameEn: 'Kinyarwanda',
    officialLanguageNameLocal: 'Ikinyarwanda',
    countryCallingCode: '250',
    region: 'Africa',
    flag: '🇷🇼',
  },
  {
    countryNameEn: 'Saudi Arabia',
    countryNameLocal: 'السعودية',
    countryCode: 'SA',
    currencyCode: 'SAR',
    currencyNameEn: 'Saudi riyal',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '966',
    region: 'Arab States',
    flag: '🇸🇦',
  },
  {
    countryNameEn: 'Solomon Islands',
    countryNameLocal: 'Solomon Islands',
    countryCode: 'SB',
    currencyCode: 'SBD',
    currencyNameEn: 'Solomon Islands dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '677',
    region: 'Asia & Pacific',
    flag: '🇸🇧',
  },
  {
    countryNameEn: 'Seychelles',
    countryNameLocal: 'Seychelles',
    countryCode: 'SC',
    currencyCode: 'SCR',
    currencyNameEn: 'Seychelles rupee',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '248',
    region: 'Africa',
    flag: '🇸🇨',
  },
  {
    countryNameEn: 'Sweden',
    countryNameLocal: 'Sverige',
    countryCode: 'SE',
    currencyCode: 'SEK',
    currencyNameEn: 'Swedish krona/kronor',
    tinType: 'Momsnr.',
    tinName: 'VAT-nummer',
    officialLanguageCode: 'sv',
    officialLanguageNameEn: 'Swedish',
    officialLanguageNameLocal: 'Svenska',
    countryCallingCode: '46',
    region: 'Europe',
    flag: '🇸🇪',
  },
  {
    countryNameEn: 'Singapore',
    countryNameLocal: 'Singapore',
    countryCode: 'SG',
    currencyCode: 'SGD',
    currencyNameEn: 'Singapore dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'zh-hans',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '65',
    region: 'Asia & Pacific',
    flag: '🇸🇬',
  },
  {
    countryNameEn: 'Saint Helena, Ascension and Tristan da Cunha',
    countryNameLocal: 'Saint Helena',
    countryCode: 'SH',
    currencyCode: 'SHP',
    currencyNameEn: 'Saint Helena pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '290',
    region: 'Africa',
    flag: '🇸🇭',
  },
  {
    countryNameEn: 'Slovenia',
    countryNameLocal: 'Slovenija',
    countryCode: 'SI',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'ID za DDV',
    tinName: 'Davčna številka',
    officialLanguageCode: 'sl',
    officialLanguageNameEn: 'Slovenian',
    officialLanguageNameLocal: 'Slovenski Jezik, Slovenščina',
    countryCallingCode: '386',
    region: 'Europe',
    flag: '🇸🇮',
  },
  {
    countryNameEn: 'Svalbard and Jan Mayen',
    countryNameLocal: 'Svalbard and Jan Mayen',
    countryCode: 'SJ',
    currencyCode: 'NOK',
    currencyNameEn: 'Norwegian krone',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'no',
    officialLanguageNameEn: 'Norwegian',
    officialLanguageNameLocal: 'Norsk',
    countryCallingCode: '4779',
    region: 'Europe',
    flag: '🇸🇯',
  },
  {
    countryNameEn: 'Slovakia',
    countryNameLocal: 'Slovensko',
    countryCode: 'SK',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'IČ DPH',
    tinName: 'Identifikačné číslo pre daň z pridanej hodnoty',
    officialLanguageCode: 'sk',
    officialLanguageNameEn: 'Slovak',
    officialLanguageNameLocal: 'Slovenčina, Slovenský Jazyk',
    countryCallingCode: '421',
    region: 'Europe',
    flag: '🇸🇰',
  },
  {
    countryNameEn: 'Sierra Leone',
    countryNameLocal: 'Sierra Leone',
    countryCode: 'SL',
    currencyCode: 'SLL',
    currencyNameEn: 'Sierra Leonean leone',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '232',
    region: 'Africa',
    flag: '🇸🇱',
  },
  {
    countryNameEn: 'San Marino',
    countryNameLocal: 'San Marino',
    countryCode: 'SM',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'C.O.E.',
    tinName: 'Codice operatore economico',
    officialLanguageCode: 'it',
    officialLanguageNameEn: 'Italian',
    officialLanguageNameLocal: 'Italiano',
    countryCallingCode: '378',
    region: 'Europe',
    flag: '🇸🇲',
  },
  {
    countryNameEn: 'Senegal',
    countryNameLocal: 'Sénégal',
    countryCode: 'SN',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '221',
    region: 'Africa',
    flag: '🇸🇳',
  },
  {
    countryNameEn: 'Somalia',
    countryNameLocal: 'Somalia, الصومال',
    countryCode: 'SO',
    currencyCode: 'SOS',
    currencyNameEn: 'Somali shilling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'so',
    officialLanguageNameEn: 'Somali',
    officialLanguageNameLocal: 'Soomaaliga, af Soomaali',
    countryCallingCode: '252',
    region: 'Arab States',
    flag: '🇸🇴',
  },
  {
    countryNameEn: 'Suriname',
    countryNameLocal: 'Suriname',
    countryCode: 'SR',
    currencyCode: 'SRD',
    currencyNameEn: 'Surinamese dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '597',
    region: 'South/Latin America',
    flag: '🇸🇷',
  },
  {
    countryNameEn: 'South Sudan',
    countryNameLocal: 'South Sudan',
    countryCode: 'SS',
    currencyCode: 'SSP',
    currencyNameEn: 'South Sudanese pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '211',
    region: 'Africa',
    flag: '🇸🇸',
  },
  {
    countryNameEn: 'Sao Tome and Principe',
    countryNameLocal: 'São Tomé e Príncipe',
    countryCode: 'ST',
    currencyCode: 'STN',
    currencyNameEn: '',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '239',
    region: 'Africa',
    flag: '🇸🇹',
  },
  {
    countryNameEn: 'El Salvador',
    countryNameLocal: 'El Salvador',
    countryCode: 'SV',
    currencyCode: 'SVC',
    currencyNameEn: 'Salvadoran colón',
    tinType: 'NIT',
    tinName: 'Número de Identificación Tributaria',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '503',
    region: 'South/Latin America',
    flag: '🇸🇻',
  },
  {
    countryNameEn: 'Sint Maarten (Dutch part)',
    countryNameLocal: 'Sint Maarten',
    countryCode: 'SX',
    currencyCode: 'ANG',
    currencyNameEn: 'Netherlands Antillean guilder',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '1721',
    region: 'Unknown',
    flag: '🇸🇽',
  },
  {
    countryNameEn: 'Syrian Arab Republic',
    countryNameLocal: 'سوريا, Sūriyya',
    countryCode: 'SY',
    currencyCode: 'SYP',
    currencyNameEn: 'Syrian pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '',
    region: 'Asia & Pacific',
    flag: '🇸🇾',
  },
  {
    countryNameEn: 'Chad',
    countryNameLocal: 'Tchad, تشاد',
    countryCode: 'TD',
    currencyCode: 'XAF',
    currencyNameEn: 'CFA franc BEAC',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '235',
    region: 'Africa',
    flag: '🇹🇩',
  },
  {
    countryNameEn: 'Togo',
    countryNameLocal: 'Togo',
    countryCode: 'TG',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '228',
    region: 'Africa',
    flag: '🇹🇬',
  },
  {
    countryNameEn: 'Thailand',
    countryNameLocal: 'ประเทศไทย',
    countryCode: 'TH',
    currencyCode: 'THB',
    currencyNameEn: 'Thai baht',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'th',
    officialLanguageNameEn: 'Thai',
    officialLanguageNameLocal: 'ไทย',
    countryCallingCode: '66',
    region: 'Asia & Pacific',
    flag: '🇹🇭',
  },
  {
    countryNameEn: 'Tajikistan',
    countryNameLocal: ',',
    countryCode: 'TJ',
    currencyCode: 'TJS',
    currencyNameEn: 'Tajikistani somoni',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'tg',
    officialLanguageNameEn: 'Tajik',
    officialLanguageNameLocal: 'тоҷикӣ, toçikī, تاجیکی‎',
    countryCallingCode: '992',
    region: 'Asia & Pacific',
    flag: '🇹🇯',
  },
  {
    countryNameEn: 'Tokelau',
    countryNameLocal: 'Tokelau',
    countryCode: 'TK',
    currencyCode: 'NZD',
    currencyNameEn: 'New Zealand dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'tkl',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '690',
    region: 'Asia & Pacific',
    flag: '🇹🇰',
  },
  {
    countryNameEn: 'Timor-Leste',
    countryNameLocal: "Timor-Leste, Timor Lorosa'e",
    countryCode: 'TL',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'pt',
    officialLanguageNameEn: 'Portuguese',
    officialLanguageNameLocal: 'Português',
    countryCallingCode: '',
    region: 'Asia & Pacific',
    flag: '🇹🇱',
  },
  {
    countryNameEn: 'Turkmenistan',
    countryNameLocal: 'Türkmenistan',
    countryCode: 'TM',
    currencyCode: 'TMT',
    currencyNameEn: 'Turkmenistan manat',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'tk',
    officialLanguageNameEn: 'Turkmen',
    officialLanguageNameLocal: 'Türkmen, Түркмен',
    countryCallingCode: '993',
    region: 'Asia & Pacific',
    flag: '🇹🇲',
  },
  {
    countryNameEn: 'Tunisia',
    countryNameLocal: 'تونس, Tunisie',
    countryCode: 'TN',
    currencyCode: 'TND',
    currencyNameEn: 'Tunisian dinar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '216',
    region: 'Arab States',
    flag: '🇹🇳',
  },
  {
    countryNameEn: 'Tonga',
    countryNameLocal: 'Tonga',
    countryCode: 'TO',
    currencyCode: 'TOP',
    currencyNameEn: 'Tongan paʻanga',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '676',
    region: 'Asia & Pacific',
    flag: '🇹🇴',
  },
  {
    countryNameEn: 'Turkey',
    countryNameLocal: 'Türkiye',
    countryCode: 'TR',
    currencyCode: 'TRY',
    currencyNameEn: 'Turkish lira',
    tinType: 'KDV',
    tinName: 'Vergi Kimlik Numarası',
    officialLanguageCode: 'tr',
    officialLanguageNameEn: 'Turkish',
    officialLanguageNameLocal: 'Türkçe',
    countryCallingCode: '90',
    region: 'Europe',
    flag: '🇹🇷',
  },
  {
    countryNameEn: 'Trinidad and Tobago',
    countryNameLocal: 'Trinidad and Tobago',
    countryCode: 'TT',
    currencyCode: 'TTD',
    currencyNameEn: 'Trinidad and Tobago dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '868',
    region: 'South/Latin America',
    flag: '🇹🇹',
  },
  {
    countryNameEn: 'Tuvalu',
    countryNameLocal: 'Tuvalu',
    countryCode: 'TV',
    currencyCode: 'AUD',
    currencyNameEn: 'Australian dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '688',
    region: 'Asia & Pacific',
    flag: '🇹🇻',
  },
  {
    countryNameEn: 'United Republic of Tanzania',
    countryNameLocal: 'Tanzania',
    countryCode: 'TZ',
    currencyCode: 'TZS',
    currencyNameEn: 'Tanzanian shilling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'sw',
    officialLanguageNameEn: 'Swahili',
    officialLanguageNameLocal: 'Kiswahili',
    countryCallingCode: '255',
    region: 'Africa',
    flag: '🇹🇿',
  },
  {
    countryNameEn: 'Ukraine',
    countryNameLocal: 'Україна',
    countryCode: 'UA',
    currencyCode: 'UAH',
    currencyNameEn: 'Ukrainian hryvnia',
    tinType: 'ІНПП',
    tinName: 'Ідентифікаційний номер платника податків',
    officialLanguageCode: 'uk',
    officialLanguageNameEn: 'Ukrainian',
    officialLanguageNameLocal: 'Українська',
    countryCallingCode: '380',
    region: 'Europe',
    flag: '🇺🇦',
  },
  {
    countryNameEn: 'Uganda',
    countryNameLocal: 'Uganda',
    countryCode: 'UG',
    currencyCode: 'UGX',
    currencyNameEn: 'Ugandan shilling',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '256',
    region: 'Africa',
    flag: '🇺🇬',
  },
  {
    countryNameEn: 'United States of America',
    countryNameLocal: 'United States of America',
    countryCode: 'US',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: 'TIN',
    tinName: 'Tax Identification Number',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1',
    region: 'North America',
    flag: '🇺🇸',
  },
  {
    countryNameEn: 'Uruguay',
    countryNameLocal: 'Uruguay',
    countryCode: 'UY',
    currencyCode: 'UYU',
    currencyNameEn: 'Uruguayan peso',
    tinType: 'RUT',
    tinName: 'Registro Único Tributario',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '598',
    region: 'South/Latin America',
    flag: '🇺🇾',
  },
  {
    countryNameEn: 'Uzbekistan',
    countryNameLocal: '',
    countryCode: 'UZ',
    currencyCode: 'UZS',
    currencyNameEn: 'Uzbekistan som',
    tinType: 'СТИР',
    tinName: 'Солиқ тўловчиларнинг идентификация рақами',
    officialLanguageCode: 'uz',
    officialLanguageNameEn: 'Uzbek',
    officialLanguageNameLocal: 'Oʻzbek, Ўзбек, أۇزبېك‎',
    countryCallingCode: '998',
    region: 'Asia & Pacific',
    flag: '🇺🇿',
  },
  {
    countryNameEn: 'Saint Vincent and the Grenadines',
    countryNameLocal: 'Saint Vincent and the Grenadines',
    countryCode: 'VC',
    currencyCode: 'XCD',
    currencyNameEn: 'East Caribbean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1784',
    region: 'South/Latin America',
    flag: '🇻🇨',
  },
  {
    countryNameEn: 'Venezuela (Bolivarian Republic of)',
    countryNameLocal: 'Venezuela',
    countryCode: 'VE',
    currencyCode: 'VES',
    currencyNameEn: '',
    tinType: 'RIF',
    tinName: 'Registro de Informacion Fiscal',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '58',
    region: 'South/Latin America',
    flag: '🇻🇪',
  },
  {
    countryNameEn: 'Virgin Islands (British)',
    countryNameLocal: 'British Virgin Islands',
    countryCode: 'VG',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1284',
    region: 'South/Latin America',
    flag: '🇻🇬',
  },
  {
    countryNameEn: 'Virgin Islands (U.S.)',
    countryNameLocal: 'United States Virgin Islands',
    countryCode: 'VI',
    currencyCode: 'USD',
    currencyNameEn: 'United States dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '1340',
    region: 'South/Latin America',
    flag: '🇻🇮',
  },
  {
    countryNameEn: 'Vietnam',
    countryNameLocal: 'Việt Nam',
    countryCode: 'VN',
    currencyCode: 'VND',
    currencyNameEn: 'Vietnamese đồng',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'vi',
    officialLanguageNameEn: 'Vietnamese',
    officialLanguageNameLocal: 'Tiếng Việt',
    countryCallingCode: '84',
    region: 'Asia & Pacific',
    flag: '🇻🇳',
  },
  {
    countryNameEn: 'Vanuatu',
    countryNameLocal: 'Vanuatu',
    countryCode: 'VU',
    currencyCode: 'VUV',
    currencyNameEn: 'Vanuatu vatu',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'bi',
    officialLanguageNameEn: 'Bislama',
    officialLanguageNameLocal: 'Bislama',
    countryCallingCode: '678',
    region: 'Asia & Pacific',
    flag: '🇻🇺',
  },
  {
    countryNameEn: 'Wallis and Futuna',
    countryNameLocal: 'Wallis-et-Futuna',
    countryCode: 'WF',
    currencyCode: 'XPF',
    currencyNameEn: 'CFP franc (franc Pacifique)',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '681',
    region: 'Asia & Pacific',
    flag: '🇼🇫',
  },
  {
    countryNameEn: 'Samoa',
    countryNameLocal: 'Samoa',
    countryCode: 'WS',
    currencyCode: 'WST',
    currencyNameEn: 'Samoan tala',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'sm',
    officialLanguageNameEn: 'Samoan',
    officialLanguageNameLocal: "gagana fa'a Samoa",
    countryCallingCode: '685',
    region: 'Asia & Pacific',
    flag: '🇼🇸',
  },
  {
    countryNameEn: 'Yemen',
    countryNameLocal: 'اليَمَن',
    countryCode: 'YE',
    currencyCode: 'YER',
    currencyNameEn: 'Yemeni rial',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '967',
    region: 'Arab States',
    flag: '🇾🇪',
  },
  {
    countryNameEn: 'Mayotte',
    countryNameLocal: 'Mayotte',
    countryCode: 'YT',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '262',
    region: 'Africa',
    flag: '🇾🇹',
  },
  {
    countryNameEn: 'South Africa',
    countryNameLocal: 'South Africa',
    countryCode: 'ZA',
    currencyCode: 'ZAR',
    currencyNameEn: 'South African rand',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '27',
    region: 'Africa',
    flag: '🇿🇦',
  },
  {
    countryNameEn: 'Zambia',
    countryNameLocal: 'Zambia',
    countryCode: 'ZM',
    currencyCode: 'ZMW',
    currencyNameEn: 'Zambian kwacha',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '260',
    region: 'Africa',
    flag: '🇿🇲',
  },
  {
    countryNameEn: 'Zimbabwe',
    countryNameLocal: 'Zimbabwe',
    countryCode: 'ZW',
    currencyCode: 'ZWL',
    currencyNameEn: 'Zimbabwean dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '263',
    region: 'Africa',
    flag: '🇿🇼',
  },
  {
    countryNameEn: 'Eswatini',
    countryNameLocal: 'Swaziland',
    countryCode: 'SZ',
    currencyCode: 'SZL',
    currencyNameEn: 'Swazi lilangeni',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '268',
    region: 'Africa',
    flag: '🇸🇿',
  },
  {
    countryNameEn: 'North Macedonia',
    countryNameLocal: 'Македонија',
    countryCode: 'MK',
    currencyCode: 'MKD',
    currencyNameEn: 'Macedonian denar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'mk',
    officialLanguageNameEn: 'Macedonian',
    officialLanguageNameLocal: 'македонски јазик',
    countryCallingCode: '389',
    region: 'Europe',
    flag: '🇲🇰',
  },
  {
    countryNameEn: 'Philippines',
    countryNameLocal: 'Philippines',
    countryCode: 'PH',
    currencyCode: 'PHP',
    currencyNameEn: 'Philippine peso',
    tinType: 'TIN',
    tinName: 'Tax Identification Number',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '63',
    region: 'Asia & Pacific',
    flag: '🇵🇭',
  },
  {
    countryNameEn: 'Netherlands',
    countryNameLocal: 'Nederland',
    countryCode: 'NL',
    currencyCode: 'EUR',
    currencyNameEn: 'Euro',
    tinType: 'Btw-nr.',
    tinName: 'Btw-nummer',
    officialLanguageCode: 'nl',
    officialLanguageNameEn: 'Dutch, Flemish',
    officialLanguageNameLocal: 'Nederlands, Vlaams',
    countryCallingCode: '31',
    region: 'Europe',
    flag: '🇳🇱',
  },
  {
    countryNameEn: 'United Arab Emirates',
    countryNameLocal: 'دولة الإمارات العربيّة المتّحدة',
    countryCode: 'AE',
    currencyCode: 'AED',
    currencyNameEn: 'United Arab Emirates dirham',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '971',
    region: 'Arab States',
    flag: '🇦🇪',
  },
  {
    countryNameEn: 'Republic of Moldova',
    countryNameLocal: 'Moldova, Молдавия',
    countryCode: 'MD',
    currencyCode: 'MDL',
    currencyNameEn: 'Moldovan leu',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ro',
    officialLanguageNameEn: 'Romanian, Moldavian, Moldovan',
    officialLanguageNameLocal: 'Română',
    countryCallingCode: '373',
    region: 'Europe',
    flag: '🇲🇩',
  },
  {
    countryNameEn: 'Gambia',
    countryNameLocal: 'The Gambia',
    countryCode: 'GM',
    currencyCode: 'GMD',
    currencyNameEn: 'Gambian dalasi',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '220',
    region: 'Africa',
    flag: '🇬🇲',
  },
  {
    countryNameEn: 'Dominican Republic',
    countryNameLocal: 'República Dominicana',
    countryCode: 'DO',
    currencyCode: 'DOP',
    currencyNameEn: 'Dominican peso',
    tinType: 'RNC',
    tinName: 'Registro Nacional del Contribuyente',
    officialLanguageCode: 'es',
    officialLanguageNameEn: 'Spanish, Castilian',
    officialLanguageNameLocal: 'Español',
    countryCallingCode: '1',
    region: 'South/Latin America',
    flag: '🇩🇴',
  },
  {
    countryNameEn: 'Sudan',
    countryNameLocal: 'السودان',
    countryCode: 'SD',
    currencyCode: 'SDG',
    currencyNameEn: 'Sudanese pound',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'ar',
    officialLanguageNameEn: 'Arabic',
    officialLanguageNameLocal: 'العربية',
    countryCallingCode: '249',
    region: 'Arab States',
    flag: '🇸🇩',
  },
  {
    countryNameEn: "Lao People's Democratic Republic",
    countryNameLocal: 'ປະຊາຊົນລາວ',
    countryCode: 'LA',
    currencyCode: 'LAK',
    currencyNameEn: 'Lao kip',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'lo',
    officialLanguageNameEn: 'Lao',
    officialLanguageNameLocal: 'ພາສາລາວ',
    countryCallingCode: '',
    region: 'Asia & Pacific',
    flag: '🇱🇦',
  },
  {
    countryNameEn: 'Taiwan',
    countryNameLocal: 'Taiwan',
    countryCode: 'TW',
    currencyCode: 'TWD',
    currencyNameEn: 'New Taiwan dollar',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'zh-hant',
    officialLanguageNameEn: '',
    officialLanguageNameLocal: '',
    countryCallingCode: '886',
    region: 'Asia & Pacific',
    flag: '🇹🇼',
  },
  {
    countryNameEn: 'Republic of the Congo',
    countryNameLocal: 'République du Congo',
    countryCode: 'CG',
    currencyCode: 'XAF',
    currencyNameEn: 'CFA franc BEAC',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '242',
    region: 'Africa',
    flag: '🇨🇬',
  },
  {
    countryNameEn: 'Czechia',
    countryNameLocal: 'Česká republika',
    countryCode: 'CZ',
    currencyCode: 'CZK',
    currencyNameEn: 'Czech koruna',
    tinType: 'DIČ',
    tinName: 'Daňové identifikační číslo',
    officialLanguageCode: 'cs',
    officialLanguageNameEn: 'Czech',
    officialLanguageNameLocal: 'Čeština',
    countryCallingCode: '420',
    region: 'Europe',
    flag: '🇨🇿',
  },
  {
    countryNameEn: 'United Kingdom',
    countryNameLocal: 'Great Britain',
    countryCode: 'GB',
    currencyCode: 'GBP',
    currencyNameEn: 'Pound sterling',
    tinType: 'VAT Reg No',
    tinName: 'Value added tax registration number',
    officialLanguageCode: 'en',
    officialLanguageNameEn: 'English',
    officialLanguageNameLocal: 'English',
    countryCallingCode: '44',
    region: 'Europe',
    flag: '🇬🇧',
  },
  {
    countryNameEn: 'Niger',
    countryNameLocal: 'Niger',
    countryCode: 'NE',
    currencyCode: 'XOF',
    currencyNameEn: 'CFA franc BCEAO',
    tinType: '',
    tinName: '',
    officialLanguageCode: 'fr',
    officialLanguageNameEn: 'French',
    officialLanguageNameLocal: 'Français',
    countryCallingCode: '227',
    region: 'Africa',
    flag: '🇳🇪',
  },
]
