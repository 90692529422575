import React from 'react'
import {useDispatch} from 'react-redux'
import {useHistory} from 'react-router-dom'
import PageContainer from '../../components/page-container'
import PeopleYouMayKnow from '../../components/people-you-may-know'
import CreatePost from '../../components/create-post'
import Post from '../../components/post'
import * as api from '../../lib/api'
import {PostSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'
import {useQueryParams} from '../../lib/use-query'

const Posts = () => {
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    isFetchingData: true,
    posts: [],
  })

  const history = useHistory()
  const queryParams = useQueryParams()
  const {error_message} = queryParams

  const {posts, isFetchingData} = state

  const catchErrorMessage = () => {
    if (error_message) {
      const errors = [{"detail": error_message}]

      dispatch({
        type: 'TOAST_RECEIVED',
        data: errors.map(e => ({...e, type: 'danger'})),
      })

      history.push('/')
    }
  }

  const fetchData = async () => {
    try {
      catchErrorMessage()

      const posts = await api.postsGet()

      setState({
        ...state,
        isFetchingData: false,
        posts,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })

      setState({
        ...state,
        isFetchingData: false,
      })
    }
  }

  React.useState(() => {
    fetchData()
  }, [])

  if (isFetchingData) return <PostSkeletonLoader />

  return (
    <div className="mt-2">
      {posts.map((post, index) => (
        <Post key={index} post={post} />
      ))}
    </div>
  )
}

const Home = () => {
  return (
    <PageContainer>
      <PeopleYouMayKnow />
      <CreatePost containerClassName="mt-5" />
      <Posts />
    </PageContainer>
  )
}

export default Home
