import React from 'react'
import {useParams} from 'react-router'
import {useSelector, useDispatch} from 'react-redux'
import Avatar from '../../../components/elements/avatar'
import * as util from '../../../lib/util'
import CustomIcon from '../../../components/elements/custom-icon'
import UploadAvatarModal from './upload-avatar-modal'
import * as api from '../../../lib/api'
import {Link} from 'react-router-dom'

const FriendActions = ({user, meId}) => {
  const dispatch = useDispatch()
  const {id, isFriend, requestStatus} = user
  const [isSubmitting, setIsSubmitting] = React.useState(false)

  const handleAddFriend = async friendId => {
    setIsSubmitting(true)
    try {
      await api.createFriendRequest({friendId})

      dispatch({
        type: 'USER_RECEIVED',
        data: {
          ...user,
          requestStatus: 'sent_pending',
        },
      })

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Friend request sent'}],
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const renderHoverOption = () => {
    return (
      <div className="opacity-0 group-hover:opacity-100 absolute top-9">
        <div className="bg-white w-full flex-1 rounded-md p-2 shadow-md">
          <Link to={`/users/${meId}/friends-list`}>
            <button
              type="button"
              className="focus:outline-none w-full flex items-center hover:bg-gray-100 p-1 rounded-md cursor-pointer"
            >
              <p className="text-base font-bold ml-3 mt-1">Review requests</p>
            </button>
          </Link>
        </div>
      </div>
    )
  }

  const renderActions = () => {
    if (isSubmitting)
      return (
        <div className="flex justify-center">
          <i className="fas fa-spinner fa-spin text-gray-300 text-right" />
        </div>
      )

    if (!isFriend && !requestStatus)
      return (
        <button
          type="button"
          className="ml-auto mr-3 focus:outline-none flex px-4 py-0.5 h-7 bg-primary rounded-md items-center hover:bg-primaryDark cursor-pointer"
          onClick={() => {
            handleAddFriend(id)
          }}
        >
          <i className="fas fa-user-plus mr-2 text-white" />
          <p className="text-white">Add friend</p>
        </button>
      )

    if (isFriend)
      return (
        <>
          <button
            type="button"
            className="ml-auto mr-3 focus:outline-none flex px-2 py-0.5 h-7 bg-green-400 rounded-md items-center cursor-pointer"
          >
            <i className="fas fa-user-check mr-1 text-white" />
            <p className="text-white">Friends</p>
          </button>
          <div className="opacity-0 group-hover:opacity-100 absolute top-8">
            <div className="bg-white w-full flex-1 rounded-md p-2 shadow-md">
              <button
                type="button"
                className="focus:outline-none w-full flex items-center hover:bg-gray-100 p-1 rounded-md cursor-pointer"
              >
                <i className="far fa-trash-alt" />
                <p className="text-base font-bold ml-1 mt-1">Remove as Friend</p>
              </button>
            </div>
          </div>
        </>
      )

    if (requestStatus === 'sent_pending')
      // Note for bea create constants for this
      return (
        <>
          <button
            type="button"
            className="ml-auto mr-3 focus:outline-none flex px-1 py-0.5 h-7 bg-blue-400 rounded-md items-center cursor-pointer"
          >
            <i className="far fa-clock mr-1 text-white" />
            <p className="text-white">Pending request</p>
          </button>
          {renderHoverOption()}
        </>
      )

    if (requestStatus === 'received_pending')
      return (
        <>
          <button
            type="button"
            className="ml-auto mr-3 focus:outline-none flex px-2 py-0.5 h-7 bg-lgreen rounded-md items-center cursor-pointer"
          >
            <i className="far fa-clock mr-1 text-white" />
            <p className="text-white">Accept Request</p>
          </button>
          {renderHoverOption()}
        </>
      )
  }

  return (
    <div className="h-full w-full flex items-end justify-end px-6">
      <div className="group relative w-40">{renderActions()}</div>
    </div>
  )
}

const UserDetails = () => {
  const {id} = useParams()
  const {me, user} = useSelector(state => ({
    me: state.me,
    user: state.user,
  }))
  const isOwnUser = me.id === id

  const dispatch = useDispatch()

  const {firstname, lastname, email, avatar, bio, firstName, lastName} = isOwnUser ? me : user

  const [isAvatarModalOpen, setIsAvatarModalOpen] = React.useState(false)
  const userName = `${firstname || firstName || ''} ${lastname || lastName || ''}` // Fix this

  const handleSetImageViewerData = () => {
    dispatch({type: 'IMAGE_VIEWER_DATA_RECEIVED', data: {isOpen: true, images: [avatar], viewIndex: 0}})
  }

  return (
    <div>
      <div className="w-full h-64 bg-primary rounded-t-md">
        <img
          src={require('./../../../assets/images/defaultbg.png')}
          className="h-64 w-full object-cover rounded-t-md"
        />
      </div>

      <div className="flex h-44">
        <div className="flex relative">
          <div className={`${avatar ? 'cursor-pointer' : 'pointer-events-none'}`} onClick={handleSetImageViewerData}>
            <Avatar
              imageUrl={avatar ? avatar : util.generateDummyAvatarUrl(userName)}
              size="56rem"
              className="border-8 border-white -mt-16 ml-10"
            />
          </div>
          {isOwnUser && (
            <button
              type="button"
              className="absolute top-24 right-4 h-12 w-12 focus:outline-none"
              onClick={() => {
                setIsAvatarModalOpen(true)
              }}
            >
              <CustomIcon
                className="hover:bg-gray-200"
                icon="fa fa-camera"
                size="4x"
                bgColor="gray-100"
                iconColor="secondarygray"
              />
            </button>
          )}
        </div>

        <div className="h-36 flex flex-col pt-6 ml-4 justify-between">
          <div>
            <div className="text-2xl font-bold">{`${firstname || firstName} ${lastname || lastName}`}</div>
            <div className="text-md">{email}</div>
          </div>
          {bio && <div className="text-xl font-bold">{`"${bio}"`}</div>}
        </div>

        {!isOwnUser && <FriendActions user={user} meId={me.id} />}
      </div>
      <div className="w-full- mx-5 border-b-2 mt-5" />
      {isAvatarModalOpen && isOwnUser && <UploadAvatarModal onCloseModal={() => setIsAvatarModalOpen(false)} />}
    </div>
  )
}

const Header = ({children}) => {
  return (
    <div>
      <div className="w-full flex flex-col bg-white rounded-md shadow-md">
        <UserDetails />
        {children}
      </div>
    </div>
  )
}

export default Header
