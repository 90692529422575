import React from 'react'
import {useDispatch} from 'react-redux'
import * as PropTypes from 'prop-types'
import {useDropzone} from 'react-dropzone'
import {humanFileSize} from '../../lib/util'
import * as constants from '../../lib/constants'

const FileUploader = ({acceptedFileTypes, acceptedMaxFileSize, onUpload}) => {
  const [imageFile, setImageFile] = React.useState(null)
  const dispatch = useDispatch()

  const processDropFiles = React.useCallback(acceptedFiles => {
    const image = acceptedFiles?.[0]

    if (image) {
      setImageFile(URL.createObjectURL(image))
      onUpload(image)
    }
  }, [])

  const processFileValidation = file => {
    const extension = file.name.split('.').pop()
    const allowedFile = acceptedFileTypes.find(f => f === `.${extension}`.toLowerCase())

    if (!allowedFile) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{detail: `${file.name} is invalid`, type: 'danger'}],
      })
      return {
        code: 'invalid-file-type',
        message: 'Invalid file type',
      }
    }

    if (file.size > acceptedMaxFileSize) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [
          {
            detail: `${file.name} is too large. Maximum allowed size is: ${humanFileSize(acceptedMaxFileSize)}`,
            type: 'danger',
          },
        ],
      })
      return {
        code: 'invalid-file-type',
        message: 'Invalid file type',
      }
    }

    return null
  }

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    onDrop: processDropFiles,
    accept: acceptedFileTypes,
    multiple: false,
    validator: processFileValidation,
  })

  const handleRemoveImage = () => {
    acceptedFiles.splice(0, 1)
    setImageFile(null)
    onUpload(null)
  }

  return (
    <div className="relative">
      <div className="flex items-center cursor-pointer" {...getRootProps()}>
        <input {...getInputProps()} />

        <i className="fas fa-image px-2" />
        <p className="text-base font-semibold">Add photo</p>
      </div>

      {imageFile && (
        <div className="h-28 relative">
          <div className="absolute border-2 border-white rounded-md bg-white mt-2 p-2">
            <img src={imageFile} className="w-20 h-20 object-cover" />
          </div>
          <i
            className="fas fa-times-circle text-red-900 absolute top-2 -right-1 cursor-pointer"
            onClick={handleRemoveImage}
          />
        </div>
      )}
    </div>
  )
}

FileUploader.defaultProps = {
  onUpload: () => {},
  acceptedFileTypes: [...constants.fileExtensions.mapping.IMAGE],
  acceptedMaxFileSize: 1024 * 10000,
}

FileUploader.propTypes = {
  acceptedFileTypes: PropTypes.array,
  acceptedMaxFileSize: PropTypes.number,
  onUpload: PropTypes.func,
}

export default FileUploader
