import React from 'react'
import * as Sentry from '@sentry/browser'
import PropTypes from 'prop-types'
import pkg from '../../package.json'

class ErrorBoundary extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
  }

  constructor(props) {
    super(props)
    this.state = {hasError: false}
  }

  static getDerivedStateFromError(error) {
    return {hasError: true}
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      for (const key of Object.keys(errorInfo)) {
        scope.setExtra(key, errorInfo[key])
      }

      scope.setExtra('version', pkg.version)
      Sentry.captureException(error)
    })
  }

  render() {
    const {children} = this.props

    if (this.state.hasError) {
      return (
        <div className="h-full flex flex-col items-center justify-center text-center">
          <img
            src="https://thankfully.me/img/favicon.png"
            alt="thankfully logo"
            className="w-26 h-20 object-contain "
          />
          <p className="text-3xl font-bold mt-2">
            <span className="text-gray-500">Oh no, something went wrong! 💥</span>
          </p>

          <p className="text-md mb-8 mt-2">
            <p className="text-gray-500">We are working on fixing the problem.</p>
            <p className="text-gray-500">Please try again</p>
          </p>

          <button
            type="submit"
            className="button is-primary"
            onClick={() => {
              window.location.reload()
            }}
          >
            <span className="icon text-primary mr-2">
              <i className="fas fa-sync" />
            </span>
            <span className="underline cursor-pointer font-semibold text-primary">Refresh Page</span>
          </button>
        </div>
      )
    }

    return children
  }
}

export default ErrorBoundary
