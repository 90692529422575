import config from './config'

export const init = () => {
  const noop = () => {}
  const liveConsole = console
  const consoleMethodNames = 'assert assert clear count debug dir dirxml error group groupCollapsed groupEnd info log markTimeline profile profileEnd table time timeEnd timeStamp timeline timelineEnd trace warn'.split(
    ' '
  )

  const deadConsole = {}

  for (const method of consoleMethodNames) {
    deadConsole[method] = noop
  }

  if (config.environment === 'production') {
    window.console = deadConsole
  } else {
    window.console = liveConsole
  }
}
