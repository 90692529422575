import React from 'react'
import * as PropTypes from 'prop-types'
import {createPopper} from '@popperjs/core'
import ReactDOM from 'react-dom'
import Option from './select/option'
import memorize from '../../lib/momorize'
import {version} from '../../../package.json'
let id = 0
const MAX_OPTION_LENGHT = 7

const uniqueId = () => {
  const PREFIX = `${version}${Math.ceil(Math.random() * 9999 + 1000).toString()}`
  return `${PREFIX}${++id}`
}

const defaultHighlight = (keyword, option) => {
  if (typeof option.text !== 'string') return option.text
  return option.text
}

const defaultIsValidNewOption = (keyword, options) => {
  return options.every(i => (typeof i.text === 'string' ? i.text.toLowerCase() : i.text) !== keyword.toLowerCase())
}

const findNextSelectableOption = (options, start) => {
  for (let i = start; i < options.length; i++) {
    if (isSelectable(options[i])) return i
  }

  return null
}

const CREATABLE_SELECT_KEY = uniqueId()

const findPrevSelectableOption = (options, start) => {
  for (let i = start; i >= 0; i -= 1) {
    if (isSelectable(options[i])) return i
  }

  return null
}

const defaultIsEqual = (a, b) => {
  return a.key === b.key
}

const defaultFilter = (keyword, option) => {
  if (typeof option.text !== 'string') return true
  return option.text.toLowerCase().includes(keyword.toLowerCase())
}

const isSelectable = item => {
  // Ignore type: header divider and disabled ones
  return item && !item.disabled && !item.type
}

const AutoCompleteInput = ({
  value,
  onTextChange,
  keyword,
  placehoder,
  onSelect,
  options,
  highlight,
  customRenderOptionContent,
  filter,
  disabled,
  maxOptionsLength,
  isEqual,
  creatableSymbols,
  isValidNewOption,
  searchSymbols,
}) => {
  const itemHeight = 30.25
  const [state, setState] = React.useState({
    keyword: keyword || '',
    isOpen: false,
    activeIndex: null,
  })

  const [searchSymbol, setSearchSymbol] = React.useState(null)
  const inputRef = React.useRef()
  const searchResultRef = React.useRef()
  const popperRef = React.useRef()

  React.useEffect(() => {
    if (state.isOpen) {
      if (!popperRef.current) {
        popperRef.current = createPopper(inputRef.current, searchResultRef.current, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({state}) => {
                state.styles.popper.width = `${state.rects.reference.width}px`
                state.styles.popper.top = '5px'
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              effect: ({state}) => {
                state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`
              },
            },
          ],
        })
      }
    } else if (popperRef.current) {
      popperRef.current.destroy()
      popperRef.current = null
    }

    return () => {
      if (popperRef.current) popperRef.current.destroy()
    }
  }, [state.isOpen])

  React.useEffect(() => {
    if (searchSymbol && !state.isOpen) {
      const _options = filterOptions(filter, state.keyword, searchSymbol)
      if (_options.length > 0 && !state.isOpen) setState({...state, isOpen: true})
    } else if (!searchSymbol) setState({...state, isOpen: false})
  }, [searchSymbol, state.keyword])

  const handleKeywordChange = event => {
    const value = event.target.value
    onTextChange(value)

    let keyword = ''

    const cursorPosition = inputRef.current.selectionStart
    const lastChar = value.charAt(cursorPosition - 1)

    const textBeforeCursorPosition = value.slice(0, Math.max(0, cursorPosition))

    if (lastChar === ' ' || value === '') {
      setSearchSymbol(null)
    }

    const wordsBeforeCursor = textBeforeCursorPosition.split(' ')
    const currentWord = wordsBeforeCursor.slice(-1)[0]

    if (currentWord && searchSymbols.includes(currentWord?.charAt(0))) keyword = currentWord

    if (keyword && !searchSymbol) setSearchSymbol(keyword.charAt(0))

    setState({...state, keyword})
  }

  const handleSelect = selected => {
    setSearchSymbol(null)

    const trimmedKeyword = state.keyword?.replace(searchSymbol, '')

    const cursorPosition = inputRef.current.selectionStart
    const textBeforeCursorPosition = value.slice(0, Math.max(0, cursorPosition))
    const textAfterCursorPosition = value.substring(cursorPosition, value?.length) // eslint-disable-line unicorn/prefer-string-slice

    const words = textBeforeCursorPosition.split(/(\s+)/)
    const appendedWords = words.map((w, index) => {
      if (words.length - 1 === index) return `${searchSymbol}${selected.text}`

      return w
    })
    const textToAppend = appendedWords.join('')

    const newValue = textToAppend + textAfterCursorPosition

    onTextChange(newValue)

    if (selected.key === CREATABLE_SELECT_KEY) {
      const defaultControlNameBySymbol = options.find(o => o.searchSymbol === searchSymbol)?.controlName
      onSelect({key: trimmedKeyword, text: trimmedKeyword, searchSymbol, controlName: defaultControlNameBySymbol})
      return
    }

    onSelect(selected)
    setState({...state, keyword: ''})
  }

  const selectCurrentIndex = e => {
    if (disabled || !state.isOpen) {
      return
    }

    e.preventDefault()

    const {activeIndex} = state

    if (activeIndex !== null) {
      const _options = filterOptions(filter, state.keyword, searchSymbol)
      handleSelect(_options[activeIndex])
    }
  }

  const renderOption = (option, index) => {
    const {value, activeIndex} = state

    const isSelected = value && isEqual(value, option)

    let optionContent = null
    if (customRenderOptionContent) {
      optionContent = customRenderOptionContent(option)
    } else {
      const keyword = state.keyword?.trim()
      optionContent = filter !== false && keyword?.length > 0 ? highlight(keyword, option) : option.text
    }

    return (
      <Option
        key={option.key}
        value={option}
        isSelected={isSelected}
        active={index === activeIndex}
        index={index}
        onMouseEnter={onOptionMouseEnter}
        onMouseLeave={onOptionMouseLeave}
        onSelect={handleSelect}
      >
        {optionContent}
      </Option>
    )
  }

  const onOptionMouseEnter = index => {
    if (disabled) {
      return
    }

    setState({
      ...state,
      activeIndex: index,
    })
  }

  const onOptionMouseLeave = index => {
    if (disabled) {
      return
    }

    setState({
      ...state,
      activeIndex: null,
    })
  }

  const renderSuggestions = () => {
    const filtered = filterOptions(filter, state.keyword, searchSymbol)

    if (filtered?.length === 0) return null

    return (
      <div
        className="no-scrollbar bg-white rounded-md p-2"
        style={{
          width: '100%',
          height: filtered.length > maxOptionsLength ? `${maxOptionsLength * itemHeight}px` : 'auto',
          margin: '0 auto',
          overflow: 'auto',
        }}
      >
        <div style={{width: '100%', height: filtered.length * itemHeight + 10}}>
          {filtered.map((o, i) => renderOption(o, i))}
        </div>
      </div>
    )
  }

  const filterOptions = memorize((filter, plainKeyword, searchSymbol) => {
    const keyword = plainKeyword?.replace(searchSymbol, '')
    const filtered =
      filter !== false && keyword ? options.filter(it => it.searchSymbol === searchSymbol && filter(keyword, it)) : []

    const pendingCreateOption =
      creatableSymbols.includes(searchSymbol) && keyword && isValidNewOption(keyword, options)
        ? [
            {
              key: CREATABLE_SELECT_KEY,
              text: keyword,
            },
          ]
        : []

    return [...pendingCreateOption, ...filtered]
  })

  const onIndexChange = (delta, e) => {
    if (disabled || !state.isOpen) {
      return
    }

    e.preventDefault()

    const _options = filterOptions(filter, state.keyword, searchSymbol)

    if (_options.length === 0) return

    let nextIndex
    if (state.activeIndex === null) {
      if (delta < 0) {
        nextIndex = _options.length - 1
      } else {
        nextIndex = 0
      }
    } else {
      nextIndex = (state.activeIndex + delta) % _options.length
    }

    if (nextIndex >= _options.length) {
      nextIndex = _options.length - 1
    }

    if (nextIndex < 0) {
      nextIndex = 0
    }

    if (!isSelectable(_options[nextIndex])) {
      let enabled
      if (delta > 0) {
        enabled = findNextSelectableOption(_options, nextIndex)
      } else {
        enabled = findPrevSelectableOption(_options, nextIndex)
      }

      if (!enabled) {
        nextIndex = null
      }

      nextIndex = enabled
    }

    if (state.activeIndex === nextIndex) {
      nextIndex = null
    }

    // Scroll to option
    if (state.activeIndex >= 0) {
      searchResultRef.current.scrollTop =
        (state.activeIndex + (delta < 0 ? 1 : 2)) * itemHeight - maxOptionsLength * itemHeight
    }

    setState({
      ...state,
      activeIndex: nextIndex,
    })
  }

  return (
    <>
      <textarea
        ref={inputRef}
        className="pt-1 form-textarea block w-full focus:outline-none w-full text-base no-scrollbar bg-gray-100 rounded-lg mt-1"
        placeholder="i.e +50 $PHP @tomjones Thank you tom for the funny dad joke #funny #dadjoke"
        value={value}
        placehoder={placehoder}
        onChange={handleKeywordChange}
        onKeyDown={e => {
          switch (e.key) {
            case 'ArrowUp':
              onIndexChange(-1, e)
              break
            case 'ArrowDown':
              onIndexChange(1, e)
              break
            case 'Enter':
              selectCurrentIndex(e)
              break
            default:
              break
          }
        }}
      />
      {state.isOpen &&
        ReactDOM.createPortal(
          <div ref={searchResultRef}>{renderSuggestions()}</div>,
          document.body // eslint-disable-line no-undef
        )}
    </>
  )
}

AutoCompleteInput.defaultProps = {
  isEqual: defaultIsEqual,
  highlight: defaultHighlight,
  filter: defaultFilter,
  maxOptionsLength: MAX_OPTION_LENGHT,
  creatableSymbols: [],
  isValidNewOption: defaultIsValidNewOption,
  searchSymbols: [],
}

AutoCompleteInput.propTypes = {
  isEqual: PropTypes.func,
  highlight: defaultHighlight,
  filter: PropTypes.func,
  maxOptionsLength: PropTypes.number,
  creatableSymbols: PropTypes.array,
  isValidNewOption: PropTypes.func,
  searchSymbols: PropTypes.array,
}

export default AutoCompleteInput
