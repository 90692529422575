// Adapted from https://github.com/alexreardon/memoize-one

const shallowEqual = (newValue, oldValue) => newValue === oldValue

const simpleIsEqual = (newArgs, lastArgs) =>
  newArgs.length === lastArgs.length && newArgs.every((newArg, index) => shallowEqual(newArg, lastArgs[index]))

export default (resultFn, isEqual = simpleIsEqual) => {
  let lastThis
  let lastArgs = []
  let lastResult
  let calledOnce = false

  const result = function (...newArgs) {
    if (calledOnce && lastThis === this && isEqual(newArgs, lastArgs)) {
      return lastResult
    }

    lastResult = resultFn.apply(this, newArgs)
    calledOnce = true
    lastThis = this
    lastArgs = newArgs
    return lastResult
  }

  return result
}
