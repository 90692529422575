import React from 'react'
import ReactDOM from 'react-dom'
import {PayPalScriptProvider, PayPalButtons} from '@paypal/react-paypal-js'
import Button from '../../elements/button'
import * as api from '../../../lib/api'
import config from '../../../config'

const PayPalModal = ({onToggleClose, paypalData}) => {
  const createOrder = (data, actions) => {
    return actions.order.create({
      intent: 'CAPTURE',
      // eslint-disable-next-line camelcase
      purchase_units: [
        {
          amount: {
            value: paypalData.coinAmount,
            currency_code: paypalData.currency, // eslint-disable-line camelcase
          },
          payee: {
            email_address: paypalData.receiver, // eslint-disable-line camelcase
          },
          description: `Payment received from Thankfully. ${paypalData.body || ''}`,
        },
      ],
    })
  }

  const onApprove = async (data, actions) => {
    try {
      await actions.order.capture()
      await api.finalizePost({orderId: data.orderID, transactionId: paypalData.transactionId})
      onToggleClose()
      window.location.reload()
    } catch {
      onToggleClose()
      window.location.reload()
    }
  }

  return ReactDOM.createPortal(
    <div className="absolute w-full h-full z-10 top-0 z-20 bg-gray-800 bg-opacity-70 flex items-center justify-center p-5">
      <div className="flex flex-col px-10 pt-4 items-center justify-center bg-white rounded-lg relative">
        <PayPalScriptProvider
          options={{
            'client-id': config.paypal.clientId,
            currency: paypalData.currency,
            intent: 'capture',
          }}
        >
          <PayPalButtons
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
          />
        </PayPalScriptProvider>

        <Button type="button" title="Cancel" className="mt-2" size="small" color="red-700" onClick={onToggleClose} />
      </div>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

export default PayPalModal
