import React from 'react'
import {Link} from 'react-router-dom'

const PageNotFound = () => {
  return (
    <div className="flex-grow flex-1 flex flex-col items-center justify-center text-center">
      <img src="https://thankfully.me/img/favicon.png" alt="thankfully logo" className="w-26 h-20 object-contain " />
      <p className="text-3xl font-bold mt-2">
        <span className="text-gray-500">Opps! You are looking in the wrong direction. 👀</span>
      </p>

      <p className="text-md mb-8 mt-2">
        <span className="text-gray-500">You may have mistyped the address or the page may have moved.</span>
      </p>

      <Link to="/">
        <p className="text-sm text-white">
          <span className="underline cursor-pointer font-semibold text-primary">Start from homepage.</span>
        </p>
      </Link>
    </div>
  )
}

export default PageNotFound
