import React from 'react'
import {Link, useParams} from 'react-router-dom'

const Tabs = ({currentTab}) => {
  const {id} = useParams()

  return (
    <div className="flex mt-2">
      <div className="flex justify-evenly w-96">
        <Link to={`/users/${id}/profile`}>
          <button className="flex flex-col focus:outline-none" type="button">
            <div className="text-md mb-2">Shout-outs</div>
            <div className={`w-full h-2 ${currentTab === 'shoutOut' ? 'bg-primary' : 'bg-white'}`} />
          </button>
        </Link>

        <Link to={`/users/${id}/about`}>
          <button type="button" className="flex flex-col focus:outline-none">
            <div className="text-md mb-2">About</div>
            <div className={`w-full h-2 ${currentTab === 'about' ? 'bg-primary' : 'bg-white'}`} />
          </button>
        </Link>

        <Link to={`/users/${id}/friends`}>
          <button className="flex flex-col focus:outline-none" type="button">
            <div className="text-md mb-2">Friends</div>
            <div className={`w-full h-2 ${currentTab === 'friends' ? 'bg-primary' : 'bg-white'}`} />
          </button>
        </Link>
      </div>
    </div>
  )
}

export default Tabs
