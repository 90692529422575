import React from 'react'
import * as PropTypes from 'prop-types'
import * as constants from '../../lib/constants'
import ShortTextForm from './form-elements/short-text-form'
import DatePicketForm from './form-elements/date-picker-form'
import PhoneNumberForm from './form-elements/phone-number-form'
import FileUploadForm from './form-elements/file-upload-form'
import HiddenForm from './form-elements/hidden-form'
import SelectForm from './form-elements/select-form'
import LongTextForm from './form-elements/long-text-form'

const FormItem = ({
  control,
  fieldType,
  errors,
  controlName,
  label,
  options,
  placeholder,
  rules,
  defaultValue,
  type,
  isDisabled,
  style,
}) => {
  const renderFieldTypes = () => {
    switch (fieldType) {
      case constants.fieldType.mapping.SHORT_TEXT:
        return (
          <ShortTextForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            type={type}
            style={style}
          />
        )
      case constants.fieldType.mapping.LONG_TEXT:
        return (
          <LongTextForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            type={type}
            style={style}
          />
        )
      case constants.fieldType.mapping.NUMBER:
        return (
          <ShortTextForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            type="number"
            style={style}
          />
        )
      case constants.fieldType.mapping.DATE_PICKER:
        return (
          <DatePicketForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
          />
        )
      case constants.fieldType.mapping.PHONE_NUMBER:
        return (
          <PhoneNumberForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
          />
        )
      case constants.fieldType.mapping.FILE_UPLOAD:
        return (
          <FileUploadForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
          />
        )
      case constants.fieldType.mapping.HIDDEN_FORM:
        return <HiddenForm control={control} controlName={controlName} defaultValue={defaultValue} />
      case constants.fieldType.mapping.SELECT:
        return (
          <SelectForm
            control={control}
            errors={errors}
            controlName={controlName}
            label={label}
            placeholder={placeholder}
            rules={rules}
            defaultValue={defaultValue}
            isDisabled={isDisabled}
            options={options}
            style={style}
          />
        )

      default:
        return null
    }
  }

  return <>{renderFieldTypes()}</>
}

FormItem.defaultProps = {
  errors: {},
  label: null,
  options: [],
  placeholder: '',
  rules: {},
  defaultValue: null,
  isDisabled: false,
  type: null,
  style: '',
}

FormItem.propTypes = {
  control: PropTypes.object.isRequired,
  fieldType: PropTypes.string.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.string,
}

export default FormItem
