import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'

const LongTextForm = ({
  control,
  errors,
  controlName,
  label,
  rules,
  placeholder,
  defaultValue,
  isDisabled,
  inputProps,
  type,
  style,
}) => {
  return (
    <div className="flex-grow flex-col relative">
      {label && <p className="text-base font-semibold">{label}</p>}
      <Controller
        name={controlName}
        control={control}
        rules={rules}
        defaultValue={defaultValue}
        render={({field: {onChange, value}}) => (
          <>
            {value && <p className="absolute text-xs text-gray-500 z-10 left-1 top-3">{placeholder}</p>}
            <textarea
              key={defaultValue}
              readOnly={isDisabled}
              value={value || ''}
              className={`pt-1 form-textarea block w-full focus:outline-none w-full text-base no-scrollbar bg-gray-100 rounded-lg mt-1 ${
                value && !isDisabled ? 'text-black' : 'text-gray-300'
              } rounded-lg h-12 px-3 border-2 border-gray-100 ${
                errors?.[controlName] ? 'border border-red-600' : ''
              } ${style}`}
              placeholder={placeholder}
              type={type ? type : 'text'}
              onChange={e => onChange(e.target.value)}
              {...inputProps}
            />
          </>
        )}
      />

      {errors?.[controlName] && <p className="text-xs text-red-600 pl-2">{errors?.[controlName]?.message}</p>}
    </div>
  )
}

LongTextForm.defaultProps = {
  errors: {},
  label: null,
  rules: {},
  placeholder: '',
  defaultValue: '',
  isDisabled: false,
  type: null,
  style: '',
}

LongTextForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  rules: PropTypes.object,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  style: PropTypes.string,
}

export default LongTextForm
