import React from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import PageContainer from '../../components/page-container'
import * as api from '../../lib/api'
import * as constants from '../../lib/constants'
import {useQueryParams, paramsToQueryString} from '../../lib/use-query'
import PaymayaForm from './partials/paymaya-form'
import GcashForm from './partials/gcash-form'
import PayPalForm from './partials/paypal-form'
import {walletBrands} from '../../lib/constants'
import Loader from '../../components/loader'

const RenderWalletForms = ({wallets, onRemoveWallet, onUpdateWallet}) => {
  const queryParams = useQueryParams()
  const {wallet: selectedWalletBrand} = queryParams

  const wallet = wallets.find(w => w.walletBrand === selectedWalletBrand)

  // If (wallets.length === 0) return null

  const renderWalletFields = () => {
    switch (selectedWalletBrand) {
      case constants.walletBrands.enumMapping.paymaya:
        return <PaymayaForm wallet={wallet} onRemoveWallet={onRemoveWallet} onUpdateWallet={onUpdateWallet} />
      case constants.walletBrands.enumMapping.gcash:
        return <GcashForm wallet={wallet} onRemoveWallet={onRemoveWallet} onUpdateWallet={onUpdateWallet} />
      case constants.walletBrands.enumMapping.paypal:
        return <PayPalForm wallet={wallet} onRemoveWallet={onRemoveWallet} onUpdateWallet={onUpdateWallet} />
      default:
        return null
    }
  }

  return renderWalletFields()
}

const WalletOverview = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = useQueryParams()
  const dispatch = useDispatch()
  const {wallet: selectedWalletBrand} = queryParams

  const [state, setState] = React.useState({
    isFetchingData: true,
    wallets: [],
  })

  const {isFetchingData, wallets} = state

  const fetchData = async () => {
    try {
      const wallets = await api.walletsGet()

      const newWallets = wallets.filter(w => w.walletBrand !== 'cardano') // Remove cardano for now

      setState({
        isFetchingData: false,
        wallets: newWallets,
      })

      if (newWallets.length > 0 && !selectedWalletBrand) handleSelectWallet(newWallets[0]?.walletBrand)
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })

      setState({wallets: [], isFetchingData: false})
    }
  }

  React.useState(() => {
    fetchData()
  }, [])

  const handleSelectWallet = walletBrand => {
    history.push(
      `${location.pathname}${paramsToQueryString({
        ...queryParams,
        wallet: walletBrand,
      })}`
    )
  }

  const handleRemoveWallet = walletBrand => {
    setState({
      ...state,
      wallets: wallets.filter(w => w.walletBrand !== walletBrand),
    })
  }

  const handleUpdateWallet = () => {
    fetchData()
  }

  if (isFetchingData) return <Loader />

  return (
    <PageContainer isFetchingData={isFetchingData}>
      <div className="w-full max-3/4 flex flex bg-white rounded-md shadow-md py-4 px-4 h-5/6 mt-8">
        <div className="pr-3 border-r border-gray-300 w-1/4">
          <p className="text-md font-semibold mb-8 mt-3 ml-3">Manage your wallets</p>

          {walletBrands.selectArrayOptions.map((wallet, index) => {
            const userWallet = wallets.find(w => w.walletBrand === wallet.key)
            return (
              <div
                key={index}
                className={`flex flex-col items-center rounded-md my-1 cursor-pointer ${
                  wallet.key === selectedWalletBrand ? 'bg-primary' : ''
                }`}
                onClick={() => handleSelectWallet(wallet.key)}
              >
                <div className="flex items-center">
                  <img src={wallet.imageUrl} className="w-30 h-16 object-scale-down my-2" />
                  <i className={`fas fa-check-circle ${userWallet ? 'text-green-500' : 'text-gray-300'} text-sm`} />
                </div>
                {userWallet ? (
                  <p className="text-xs">{userWallet?.accountNumber}</p>
                ) : (
                  <p className="text-xs">Not yet enabled</p>
                )}
              </div>
            )
          })}
        </div>
        <div className="flex-1 flex-col px-4 h-full">
          <RenderWalletForms
            wallets={wallets}
            onRemoveWallet={handleRemoveWallet}
            onUpdateWallet={handleUpdateWallet}
          />
        </div>
      </div>
    </PageContainer>
  )
}

export default WalletOverview
