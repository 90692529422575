import React from 'react'
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import * as util from '../../lib/util'
import Avatar from '../elements/avatar'

const SideBar = () => {
  const dispatch = useDispatch()
  const {isSidebarOpen, me} = useSelector(state => ({
    isSidebarOpen: state.isSidebarOpen,
    me: state.me,
  }))
  const {firstname, lastname, avatar} = me
  const userName = `${firstname || ''} ${lastname || ''}`

  const handleToggleSidebar = () => {
    dispatch({type: 'SIDEBAR_TOGGLE', data: !isSidebarOpen})
  }

  return (
    <div className="flex shadow-lg bg-white pt-16 rounded-br-4xl px-1 relative">
      <div className={` ${isSidebarOpen ? 'w-64' : 'w-16'} flex flex-1 flex-col`}>
        <Link to={`/users/${me.id}/profile`}>
          <div className="mt-14">
            <div className="px-4 flex items-center">
              <Avatar imageUrl={avatar ? avatar : util.generateDummyAvatarUrl(userName)} size="2x" />
              {isSidebarOpen && <p className="text-md font-semibold px-3">{userName}</p>}
            </div>
          </div>
        </Link>

        <div className="w-4/5 mx-auto my-4 border-t border-gray-200" />

        <div className="ml-5 mt-3">
          <Link to={`/users/${me.id}/friends-list`}>
            <button
              type="button"
              className={`w-full ${
                isSidebarOpen ? 'px-3' : 'px-2'
              } my-2 flex items-center text-gray-600 py-2 rounded-md focus:outline-none  hover:bg-primary hover:text-white`}
            >
              <i className="text-xl fas fa-user-friends" />
              {isSidebarOpen && <p className="text-base px-3">Friends</p>}
            </button>
          </Link>

          <Link to="/wallets/overview">
            <button
              type="button"
              className={`w-full ${
                isSidebarOpen ? 'px-3' : 'px-2'
              } my-2 flex items-center text-gray-600 py-2 rounded-md focus:outline-none  hover:bg-primary hover:text-white`}
            >
              <i className="text-xl fas fa-wallet" />
              {isSidebarOpen && <p className="text-base px-3">Wallets</p>}
            </button>
          </Link>

          <Link to="/transactions/overview">
            <button
              type="button"
              className={`w-full ${
                isSidebarOpen ? 'px-3' : 'px-2'
              } my-2 flex items-center text-gray-600 py-2 rounded-md focus:outline-none  hover:bg-primary hover:text-white`}
            >
              <i className="text-xl fas fa-exchange-alt" />
              {isSidebarOpen && <p className="text-base px-3">Transactions</p>}
            </button>
          </Link>

          <Link to="/help">
            <button
              type="button"
              className={`w-full ${
                isSidebarOpen ? 'px-3' : 'px-2'
              } my-2 flex items-center text-gray-600 py-2 rounded-md focus:outline-none  hover:bg-primary hover:text-white`}
            >
              <i className="text-xl fas fa-question-circle" />
              {isSidebarOpen && <p className="text-base px-3">How it works?</p>}
            </button>
          </Link>
        </div>

        <button
          type="button"
          className="rounded-md focus:outline-none text-gray-600 hover:bg-primary hover:text-white absolute bottom-5 p-2"
          onClick={handleToggleSidebar}
        >
          <i className={`fas fa-chevron-${isSidebarOpen ? 'left' : 'right'}`} />
        </button>
      </div>
    </div>
  )
}

export default SideBar
