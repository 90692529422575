import React from 'react'
import * as PropTypes from 'prop-types'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css' // eslint-disable-line import/no-unassigned-import

const DatePickerElement = ({value, onChange, style, label, placeholder}) => {
  const CustomInput = React.forwardRef(({value, onClick}, ref) => (
    <button
      ref={ref}
      type="button"
      className={`w-full bg-white text-base text-left ${
        value ? 'text-black' : 'text-gray-400'
      } rounded-lg h-12 px-3 my-3 shadow-sm ${style}`}
      onClick={onClick}
    >
      {value || placeholder}
    </button>
  ))

  return (
    <DatePicker
      selected={value}
      placeholder={placeholder}
      customInput={<CustomInput />}
      onChange={date => onChange(date)}
    />
  )
}

DatePickerElement.defaultProps = {
  style: null,
  onChange: () => {},
  value: new Date(),
  placeholder: '',
}

DatePickerElement.propTypes = {
  value: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  style: PropTypes.string,
  placeholder: PropTypes.string,
}

export default DatePickerElement
