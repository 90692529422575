import React from 'react'
import {useHistory} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import PageContainer from '../../components/page-container'

export default () => {
  const history = useHistory()
  const dispatch = useDispatch()

  React.useEffect(() => {
    const timer = setTimeout(() => {
      // eslint-disable-next-line no-undef
      localStorage.setItem('thankfully:context', JSON.stringify({accessToken: null, me: null}))
      dispatch({type: 'LOGOUT'})
      history.push('/login')
    }, 1500)

    return () => {
      clearTimeout(timer)
    }
  }, [])

  return (
    <PageContainer className="bg-primary">
      <div className="flex flex-1 flex-col items-center justify-center">
        <p className="text-3xl font-bold mt-2">
          <span className="text-white">Invalid user session.</span>
        </p>
        <p className="text-xl font-bold mt-2">
          <span className="text-white">We have to log you out for now 🥺</span>
        </p>
      </div>
    </PageContainer>
  )
}
