import React from 'react'

export const PostSkeletonLoader = () => {
  return (
    <div className="w-full max-3/4 flex bg-white rounded-md shadow-md py-4 px-4 mt-5 mb-5 animate-pulse">
      <div className="w-14 h-14 rounded-full bg-gray-200" />
      <div className="w-full ml-5 flex flex-col mb-5">
        <div className="mb-5">
          <div className="w-1/4 h-3 bg-gray-200 mt-2" />
          <div className="w-12 h-2 bg-gray-200 mt-2" />
        </div>
        <div className="w-full h-3 bg-gray-200 mt-2" />
        <div className="w-full h-3 bg-gray-200 mt-2" />
        <div className="w-full h-3 bg-gray-200 mt-2" />
        <div className="w-3/4 h-3 bg-gray-200 mt-2" />
      </div>
    </div>
  )
}

export const NetworkSkeletonLoader = () => {
  return (
    <div className="w-full max-3/4 flex flex-col bg-white rounded-md shadow-md pt-2 pb-4 px-4 mt-6 mb-3 animate-pulse">
      <div className="h-5 w-20 bg-gray-200" />
      <div className="flex w-full my-2 self-center items-center justify-center">
        <div className="flex p-1 mb-8">
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
          <div className="w-28 mx-3 flex flex-col items-center">
            <div className="w-14 h-14 bg-gray-200 rounded-full" />
            <div className="mt-3 h-5 w-20 bg-gray-200" />
          </div>
        </div>
      </div>
    </div>
  )
}

export const CreatePostSkeletonLoader = () => {
  return (
    <div className="w-full max-3/4 flex bg-white rounded-md shadow-md py-4 px-4 mt-4 mb-5 animate-pulse">
      <div className="w-9 h-9 rounded-full bg-gray-200" />
      <div className="w-full ml-5 flex flex-col mt-2 mb-5">
        <div className="w-full h-40 bg-gray-200 mb-10" />
        <div className="w-full h-10 bg-gray-200 mt-2" />
      </div>
    </div>
  )
}

export const FriendsListSkeletonLoader = () => {
  return (
    <div className="w-full flex flex-col justify-center py-20 bg-white rounded-md shadow-md mt-12 mb-24 animate-pulse">
      <div className="flex justify-center mb-16">
        <div className=" ml-4 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
      </div>
      <div className="flex justify-center mt-5 mb-20">
        <div className=" ml-4 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
        <div className=" ml-20 flex flex-col justify-center items-center">
          <div className="w-20 h-20 rounded-full bg-gray-200" />
          <div className="w-10 h-2 bg-gray-200 mt-3" />
        </div>
      </div>
    </div>
  )
}

export const HeaderSkeletonLoader = () => {
  return (
    <div className="w-full flex flex-col bg-white rounded-md shadow-md animate-pulse">
      <div className="w-full h-64 bg-gray-200 rounded-t-md" />
      <div className="flex h-44">
        <div className="h-56 w-56 border-8 border-white relative bottom-16 left-10 bg-gray-300 rounded-full" />
        <div className="h-36 flex flex-col pt-6 ml-12 justify-between">
          <div>
            <div className="h-5 w-40 bg-gray-200 mt-3" />
            <div className="h-4 w-36 bg-gray-200 mt-3" />
          </div>
        </div>
      </div>
      <div className="w-full- mx-5 border-b-2 border-white mt-5" />
    </div>
  )
}

export const AboutSkeletonLoader = () => {
  return (
    <div className="w-full bg-white rounded-md shadow-md mt-6 h-screen flex flex-col mb-10 animate-pulse">
      <div className="flex flex-col mt-7 px-8">
        <div className="h-4 w-24 bg-gray-200" />
        <div className="mt-10 ml-10">
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
          <div className="h-4 w-24 bg-gray-200 mb-8" />
        </div>
      </div>
    </div>
  )
}
