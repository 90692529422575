import * as redux from 'redux'
import thunkMiddleware from 'redux-thunk'
import {createLogger} from 'redux-logger'
import config from '../config'
import * as reducers from './reducers'

export const loadState = () => {
  try {
    // eslint-disable-next-line no-undef
    const serializedState = localStorage.getItem('thankfully:context')
    if (serializedState === null) return undefined
    return JSON.parse(serializedState)
  } catch {
    return undefined
  }
}

export const saveState = state => {
  try {
    const serializedState = JSON.stringify({
      accessToken: state.accessToken,
      me: state.me, // Temp
    })

    // eslint-disable-next-line no-undef
    localStorage.setItem('thankfully:context', serializedState)
  } catch {
    // Ignore errors for now
  }
}

function applyMiddleware() {
  const middlewares = [thunkMiddleware]
  if (config.environment === 'development') middlewares.push(createLogger())

  return redux.applyMiddleware(...middlewares)
}

export default function configureStore() {
  const initialState = {...reducers.initialState, ...loadState()}
  const store = redux.createStore(reducers.reducerComposition(), initialState, applyMiddleware())
  store.subscribe(() => saveState(store.getState()))
  return store
}
