import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import {useQueryParams} from '../../lib/use-query'

const Authorize = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const queryParams = useQueryParams()
  const {auth} = queryParams

  const {accessToken} = useSelector(state => ({
    accessToken: state.accessToken,
  }))

  React.useEffect(() => {
    handleUpdateUserAuth()
  }, [])

  React.useEffect(() => {
    if (accessToken) history.push('/')
  }, [accessToken])

  const handleUpdateUserAuth = async () => {
    try {
      dispatch({type: 'REFRESH_AUTH', data: auth})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  return <div className="flex flex-row w-full h-full justify-between bg-gray-100">Authorizing...</div>
}

export default Authorize
