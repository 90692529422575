import React from 'react'
import {Link} from 'react-router-dom'

const LoadingPage = () => {
  const [isLoadingLong, setIsLoadingLong] = React.useState(false)
  const [isLoadingLonger, setIsLoadingLonger] = React.useState(false)
  const [isLoadingLongest, setIsLoadingLongest] = React.useState(false)
  const [isLoadingUnexpectedLong, setIsLoadingUnexpectedLong] = React.useState(false)

  React.useEffect(() => {
    const timerLong = setTimeout(() => setIsLoadingLong(true), 1500)
    const timerLonger = setTimeout(() => setIsLoadingLonger(true), 5000)
    const timerLongest = setTimeout(() => setIsLoadingLongest(true), 18_000)
    const timerUnexpectedLong = setTimeout(() => setIsLoadingUnexpectedLong(true), 35_000)

    return () => {
      clearTimeout(timerLong)
      clearTimeout(timerLonger)
      clearTimeout(timerLongest)
      clearTimeout(timerUnexpectedLong)
    }
  }, [])

  if (isLoadingUnexpectedLong) {
    return (
      <div className="flex flex-col w-full h-full bg-gray-100 pt-14 justify-center items-center">
        <i className="text-5xl text-primary fas fa-spinner fa-pulse" />
        <p className="text-3xl font-bold mt-2 text-gray-500">Oh no, not sure what's happening 😵‍💫</p>
        <p className="text-sm mt-2 text-gray-500">Maybe something unexpected happened!</p>

        <Link to="/">
          <p className="text-sm mt-2">
            <span className="underline cursor-pointer font-semibold text-primary">Go back to homepage.</span>
          </p>
        </Link>
      </div>
    )
  }

  if (isLoadingLongest) {
    return (
      <div className="flex flex-col w-full h-full bg-gray-100 pt-14 justify-center items-center">
        <i className="text-5xl text-primary fas fa-spinner fa-pulse" />
        <p className="text-3xl font-bold mt-2 text-gray-500">Just a few more minutes!</p>
        <p className="text-sm mt-2 text-gray-500">While you wait️, why not grab a coffee ☕️</p>
      </div>
    )
  }

  if (isLoadingLonger) {
    return (
      <div className="flex flex-col w-full h-full bg-gray-100 pt-14 justify-center items-center">
        <i className="text-5xl text-primary fas fa-spinner fa-pulse" />
        <p className="text-3xl font-bold mt-2 text-gray-500">Still loading...</p>
        <p className="text-sm mt-2 text-gray-500">Please wait while we set things up for you! 😉</p>
      </div>
    )
  }

  if (isLoadingLong) {
    return (
      <div className="flex flex-col w-full h-full bg-gray-100 pt-14 justify-center items-center">
        <i className="text-5xl text-primary fas fa-spinner fa-pulse" />
        <p className="text-3xl font-bold mt-2 text-gray-500">Loading</p>
        <p className="text-sm mt-2 text-gray-500">it might take a few seconds</p>
      </div>
    )
  }

  return null
}

export default LoadingPage
