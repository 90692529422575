import React, {useEffect, useState} from 'react'
import PageContainer from '../../components/page-container'
import PeopleYouMayKnow from '../../components/people-you-may-know'
import FriendsList from '../../components/friends-list'
import * as api from '../../lib/api'
import * as util from '../../lib/util'
import Avatar from '../../components/elements/avatar'
import {Link} from 'react-router-dom'
import {useDispatch} from 'react-redux'
import {FriendsListSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'

const groupList = friend => {
  let index = 0
  const arrayLength = friend.length
  const rowItems = []
  const rowCount = 5

  for (index = 0; index < arrayLength; index += rowCount) {
    const group = friend.slice(index, index + rowCount)

    rowItems.push(group)
  }

  return rowItems
}

const FriendRequests = () => {
  const [state, setState] = useState({
    isFetchingData: true,
    friendRequestList: [],
    hasFriendsChanged: false,
  })
  const [buttonState, setButtonState] = useState({
    buttonId: '',
    isButtonLoading: false,
  })

  const {isFetchingData, friendRequestList, hasFriendsChanged} = state
  const {buttonId, isButtonLoading} = buttonState

  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const friendRequestList = await api.getFriendRequests()
    try {
      setState({
        ...state,
        friendRequestList,
        isFetchingData: false,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const handleAction = async (actionType, userId, frId) => {
    const user = {friendId: userId}

    const filteredFriendRequests = friendRequestList.filter(request => request.id !== frId)

    if (actionType === 'accept') {
      await api.acceptFriendRequest(user, frId)
    } else {
      await api.ignoreFriendRequest(user, frId)
    }

    try {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: `Friend request ${actionType === 'accept' ? 'accepted' : 'ignored'}`}],
      })
      setButtonState({
        ...buttonState,
        isButtonLoading: false,
      })
      setState({
        ...state,
        friendsChanged: true,
        friendRequestList: filteredFriendRequests,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
      setButtonState({
        ...buttonState,
        isButtonLoading: false,
      })
    }
  }

  if (isFetchingData) return <FriendsListSkeletonLoader />

  const renderUserDetails = user => {
    const {fromFriend, id} = user

    const userName = `${fromFriend.firstName || ''} ${fromFriend.lastName || ''}`
    return (
      <div key={id} className="flex flex-col w-28 items-center justify-between h-44 mx-2 mb-3">
        <div className="flex flex-col items-center">
          <Link to={`/users/${fromFriend.id}/profile`}>
            <Avatar
              imageUrl={fromFriend.avatar ? fromFriend.avatar : util.generateDummyAvatarUrl(userName)}
              size="4x"
              className="hover:opacity-70 cursor-pointer"
            />
          </Link>
          <Link to={`/users/${fromFriend.id}/profile`}>
            <div className="text-center w-full text-base mt-2 font-semibold hover:underline cursor-pointer">{`${fromFriend.firstName} ${fromFriend.lastName}`}</div>
          </Link>
        </div>

        <div className="w-full">
          <button
            id={`${id}-accept`}
            type="button"
            className={`${
              isButtonLoading ? 'pointer-events-none' : ''
            } focus:outline-none hover:bg-primaryDark cursor-pointer text-base text-white my-1 w-full px-2 pt-1 bg-primary rounded-md`}
            onClick={() => {
              handleAction('accept', fromFriend.id, id)
              setButtonState({
                buttonId: `${id}-accept`,
                isButtonLoading: true,
              })
            }}
          >
            <div className="text-white">
              {isButtonLoading && buttonId === `${id}-accept` && <i className="mr-2 fas fa-spinner fa-spin" />}
              Accept
            </div>
          </button>
          <button
            type="button"
            id={`${id}-ignore`}
            className={`${
              isButtonLoading ? 'pointer-events-none' : ''
            } focus:outline-none hover:bg-gray-400 cursor-pointer text-base text-white my-1 w-full px-2 pt-1 bg-gray-300 rounded-md`}
            onClick={() => {
              handleAction('ignore', fromFriend.id, id)
              setButtonState({
                buttonId: `${id}-ignore`,
                isButtonLoading: true,
              })
            }}
          >
            <div className="text-white">
              {isButtonLoading && buttonId === `${id}-ignore` && <i className="mr-2 fas fa-spinner fa-spin" />}
              Ignore
            </div>
          </button>
        </div>
      </div>
    )
  }

  const renderFriendRequests = () => {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-1">
          {groupList(friendRequestList).map((row, index) => {
            return (
              <div key={index} className="flex">
                {row.map((user, fIdx) => {
                  return <div key={fIdx}>{renderUserDetails(user)}</div>
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <>
      <FriendsList hasFriendsChanged={hasFriendsChanged} />
      <div className="w-full bg-white rounded-md shadow-md mb-10">
        <div className="text-base ml-5 mt-5 font-bold">{`Friend Requests (${friendRequestList.length})`}</div>
        {friendRequestList.length === 0 ? (
          <div className="flex justify-center my-5 text-base">No Friend Request</div>
        ) : (
          <div className="px-8 py-10">{renderFriendRequests()}</div>
        )}
      </div>
    </>
  )
}

const FriendRequestsSent = () => {
  const [state, setState] = useState({
    friendRequestSentList: [],
    isFetchingData: true,
  })

  const [buttonState, setButtonState] = useState({
    buttonId: '',
    isButtonLoading: false,
  })

  const {isFetchingData, friendRequestSentList} = state

  const dispatch = useDispatch()

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    const friendRequestSentList = await api.getFriendRequestsSent()
    try {
      setState({
        friendRequestSentList,
        isFetchingData: false,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const onRemoveFriendRequestSent = async (userId, frId) => {
    const user = {friendId: userId}

    const filteredSentList = friendRequestSentList.filter(request => request.id !== frId)

    await api.cancelFriendRequestSent(user, frId)
    try {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: `Friend request cancelled`}],
      })
      setButtonState({
        ...buttonState,
        isButtonLoading: false,
      })
      setState({
        ...state,
        friendRequestSentList: filteredSentList,
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
      setButtonState({
        ...buttonState,
        isButtonLoading: false,
      })
    }
  }

  const renderUserDetails = user => {
    const {sentToFriend, id} = user
    const {isButtonLoading, buttonId} = buttonState

    const userName = `${sentToFriend.firstName || ''} ${sentToFriend.lastName || ''}`
    return (
      <div key={id} className="flex flex-col w-28 items-center justify-between h-36 mx-2">
        <div className="flex flex-col items-center">
          <Link to={`/users/${sentToFriend.id}/profile`}>
            <Avatar
              imageUrl={sentToFriend.avatar ? sentToFriend.avatar : util.generateDummyAvatarUrl(userName)}
              size="4x"
              className="hover:opacity-70 cursor-pointer"
            />
          </Link>
          <Link to={`/users/${sentToFriend.id}/profile`}>
            <div className="text-center w-full text-base mt-2 font-semibold hover:underline cursor-pointer">{`${sentToFriend.firstName} ${sentToFriend.lastName}`}</div>
          </Link>
        </div>

        <div className="w-full flex">
          <button
            id={id}
            type="button"
            className={`focus:outline-none ${
              isButtonLoading ? 'pointer-events-none' : ''
            } cursor-pointer text-base text-white my-1 w-full px-2 pt-1 bg-primary rounded-md`}
            onClick={() => {
              onRemoveFriendRequestSent(sentToFriend.id, id)
              setButtonState({
                buttonId: id,
                isButtonLoading: true,
              })
            }}
          >
            {isButtonLoading && buttonId === id ? (
              <i className="mr-2 fas fa-spinner fa-spin" />
            ) : (
              <div className="text-white">Cancel</div>
            )}
          </button>
        </div>
      </div>
    )
  }

  if (isFetchingData) return <FriendsListSkeletonLoader />

  const renderFriendRequestSent = () => {
    return (
      <div className="flex w-full justify-center">
        <div className="mt-1">
          {groupList(friendRequestSentList).map((row, index) => {
            return (
              <div key={index} className="flex mb-8">
                {row.map((user, fIdx) => {
                  return <div key={fIdx}>{renderUserDetails(user)}</div>
                })}
              </div>
            )
          })}
        </div>
      </div>
    )
  }

  return (
    <div className="w-full bg-white rounded-md shadow-md mb-10">
      <div className="text-base ml-5 mt-5 font-bold">{`Friend Requests Sent (${friendRequestSentList.length})`}</div>
      {friendRequestSentList.length === 0 ? (
        <div className="flex justify-center my-5 text-base">No Friend Requests Sent</div>
      ) : (
        <div className="px-8 py-10">{renderFriendRequestSent()}</div>
      )}
    </div>
  )
}

const UserFriends = () => {
  return (
    <PageContainer>
      <PeopleYouMayKnow />
      <FriendRequests />
      <FriendRequestsSent />
    </PageContainer>
  )
}

export default UserFriends
