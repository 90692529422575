import React from 'react'
import * as PropTypes from 'prop-types'
import {createPopper} from '@popperjs/core'
import ReactDOM from 'react-dom'
import {version} from '../../../../package.json'
import memorize from '../../../lib/momorize'
import Option from './option'
import TagList from './tag-list'
import Search from './search'

let id = 0
const MAX_OPTION_LENGHT = 7

const uniqueId = () => {
  const PREFIX = `${version}${Math.ceil(Math.random() * 9999 + 1000).toString()}`
  return `${PREFIX}${++id}`
}

const defaultIsEqual = (a, b) => {
  return a.key === b.key
}

const defaultFilter = (keyword, option) => {
  if (typeof option.text !== 'string') return true
  return option.text.toLowerCase().includes(keyword.toLowerCase())
}

const isSelectable = item => {
  // Ignore type: header divider and disabled ones
  return item && !item.disabled && !item.type
}

const findNextSelectableOption = (options, start) => {
  for (let i = start; i < options.length; i++) {
    if (isSelectable(options[i])) return i
  }

  return null
}

const findPrevSelectableOption = (options, start) => {
  for (let i = start; i >= 0; i -= 1) {
    if (isSelectable(options[i])) return i
  }

  return null
}

const defaultHighlight = (keyword, option) => {
  if (typeof option.text !== 'string') return option.text
  return option.text
}

const DEFAULT_LOADING = (
  <div className="is-flex is-justify-content-center">
    <i className="fas fa-spinner fa-spin" />
  </div>
)

const defaultIsValidNewOption = (keyword, options) => {
  return options.every(i => (typeof i.text === 'string' ? i.text.toLowerCase() : i.text) !== keyword.toLowerCase())
}

const CREATABLE_SELECT_KEY = uniqueId()

const Select = ({
  controlName,
  value,
  isMultiple,
  onChange,
  disabled,
  keyword,
  onKeywordChange,
  options,
  isEqual,
  placeholder,
  notFoundContent,
  inline,
  width,
  popupWidth,
  optionHeight,
  maxOptionsLength,
  filter,
  highlight,
  customRenderValue,
  customRenderOptionContent,
  appendOptionsLength,
  triggerAppendOptions,
  isClearable,
  isLoading,
  isCreatable,
  onCreate,
  isValidNewOption,
  className,
  isError,
  isSelectionStateRemember,
  onDropdownOpenStateChange,
  customRenderCreateContent,
  customContainerClassName,
  inputType,
  isDropdownExpandable,
}) => {
  const [state, setState] = React.useState({
    keyword: keyword || '',
    value,
    open: false,
    activeIndex: null,
    prevOptions: options,
    creating: false,
  })

  const [itemHeight, setItemHeight] = React.useState(0)

  const popperRef = React.useRef()
  const triggerRef = React.useRef()
  const popupRef = React.useRef()

  const searchResultRef = React.useRef(null)

  React.useEffect(() => {
    setItemHeight(state.open ? optionHeight : 0)
    if (state.open) {
      if (!popperRef.current) {
        popperRef.current = createPopper(triggerRef.current, popupRef.current, {
          placement: 'bottom',
          modifiers: [
            {
              name: 'sameWidth',
              enabled: true,
              fn: ({state}) => {
                state.styles.popper.width = `${
                  state.rects.reference.width +
                  (state.elements.reference.clientWidth < 100 && isDropdownExpandable
                    ? state.elements.reference.clientWidth * 2
                    : 0)
                }px`
              },
              phase: 'beforeWrite',
              requires: ['computeStyles'],
              effect: ({state}) => {
                state.elements.popper.style.width = `${state.elements.reference.clientWidth}px`
              },
            },
          ],
        })
      }
    } else if (popperRef.current) {
      popperRef.current.destroy()
      popperRef.current = null
    }

    if (onDropdownOpenStateChange) onDropdownOpenStateChange(state.open)

    return () => {
      if (popperRef.current) popperRef.current.destroy()
    }
  }, [state.open])

  const inputRef = React.createRef()

  const resetKeyword = (keyword = '') => {
    if (onKeywordChange) {
      onKeywordChange(keyword)
    } else {
      setState({
        ...state,
        keyword,
      })
    }
  }

  const onSelect = item => {
    if (item.disabled || item.type || disabled) {
      return
    }

    if (item.key === CREATABLE_SELECT_KEY) {
      onCreateClick()
      return
    }

    if (isMultiple === false) {
      setState({
        ...state,
        open: false,
        activeIndex: null,
        keyword: '',
        value: isSelectionStateRemember ? item : null,
      })
      if (onChange) {
        onChange(item)
      }
    } else {
      const {value} = state
      const valueIndex = value.findIndex(it => isEqual(it, item))
      focusSearchInput()
      const nextValue = valueIndex >= 0 ? value.filter((_it, index) => index !== valueIndex) : [...value, item]

      setState({
        ...state,
        open: false,
        activeIndex: null,
        keyword: '',
        value: isSelectionStateRemember ? nextValue : null,
      })

      if (onChange) {
        onChange(nextValue)
      }
    }
  }

  const onRemove = item => {
    if (disabled) {
      return
    }

    const {value} = state
    const nextValue = value.filter(it => !isEqual(item, it))
    focusSearchInput()
    setState({
      ...state,
      value: nextValue,
    })

    if (onChange) {
      onChange(nextValue)
    }
  }

  const onOptionMouseEnter = index => {
    if (disabled) {
      return
    }

    setState({
      ...state,
      activeIndex: index,
    })
  }

  const onOptionMouseLeave = index => {
    if (disabled) {
      return
    }

    setState({
      ...state,
      activeIndex: null,
    })
  }

  const selectCurrentIndex = () => {
    if (disabled) {
      return
    }

    const {activeIndex} = state

    if (activeIndex !== null) {
      const _options = filterOptions(isCreatable, options, filter, state.keyword, isValidNewOption)
      onSelect(_options[activeIndex])
    }
  }

  const renderOption = (option, index) => {
    const {value, activeIndex, creating} = state

    const isSelected = value && (isMultiple ? value.findIndex(it => isEqual(it, option)) >= 0 : isEqual(value, option))

    let optionContent = null
    let isLoading = false
    if (option.key === CREATABLE_SELECT_KEY) {
      isLoading = creating
      if (customRenderCreateContent) optionContent = customRenderCreateContent(option)
      else optionContent = <span className="font-semibold">{`Create ${option.text}`}</span>
    } else if (customRenderOptionContent) {
      optionContent = customRenderOptionContent(option)
    } else {
      const keyword = state.keyword.trim()
      optionContent = filter !== false && keyword.length > 0 ? highlight(keyword, option) : option.text
    }

    return (
      <Option
        key={option.key}
        value={option}
        isSelected={isSelected}
        active={index === activeIndex}
        index={index}
        isMultiple={isMultiple}
        isLoading={isLoading}
        onMouseEnter={onOptionMouseEnter}
        onMouseLeave={onOptionMouseLeave}
        onSelect={onSelect}
      >
        {optionContent}
      </Option>
    )
  }

  const handleKeywordChange = e => {
    if (disabled) return

    resetKeyword(e.target.value)
  }

  const onIndexChange = delta => {
    if (disabled) {
      return
    }

    const _options = filterOptions(isCreatable, options, filter, state.keyword, isValidNewOption)

    let nextIndex
    if (state.activeIndex === null) {
      if (delta < 0) {
        nextIndex = _options.length - 1
      } else {
        nextIndex = 0
      }
    } else {
      nextIndex = (state.activeIndex + delta) % _options.length
    }

    if (nextIndex >= _options.length) {
      nextIndex = _options.length - 1
    }

    if (nextIndex < 0) {
      nextIndex = 0
    }

    if (!isSelectable(_options[nextIndex])) {
      let enabled
      if (delta > 0) {
        enabled = findNextSelectableOption(_options, nextIndex)
      } else {
        enabled = findPrevSelectableOption(_options, nextIndex)
      }

      if (!enabled) {
        nextIndex = null
      }

      nextIndex = enabled
    }

    if (state.activeIndex === nextIndex) {
      nextIndex = null
    }

    // Scroll to option
    if (state.activeIndex >= 0) {
      searchResultRef.current.scrollTop =
        (state.activeIndex + (delta < 0 ? 1 : 2)) * itemHeight - maxOptionsLength * itemHeight
    }

    setState({
      ...state,
      activeIndex: nextIndex,
    })
  }

  const renderValue = () => {
    if (isMultiple) {
      const {value} = state
      if (value?.length > 0) {
        return renderTagList(value)
      }
    } else {
      const {value} = state

      if (value) {
        return customRenderValue ? (
          customRenderValue(value)
        ) : (
          <span className="text-base ellipsis pr-2 text-black">{value.text}</span>
        )
      }
    }

    return <span className="text-base pr-2">{placeholder}</span>
  }

  const renderTagList = value => {
    return <TagList list={value} renderValue={customRenderValue} isClearable={isClearable} onRemove={onRemove} />
  }

  const onCreateClick = () => {
    const {keyword} = state

    if (onCreate) {
      setState({...state, creating: true})

      onCreate(keyword.trim())
        .then(() => {
          if (isMultiple) {
            focusSearchInput()
          }
        })
        .finally(() => {
          setState({...state, creating: false, keyword: '', open: false})
        })
    }
  }

  const filterOptions = memorize((isCreatable, options, filter, keyword, isValidNewOption) => {
    const filtered = filter !== false && keyword ? options.filter(it => filter(keyword, it)) : options

    const pendingCreateOption =
      isCreatable && keyword && isValidNewOption(keyword, options)
        ? [
            {
              key: CREATABLE_SELECT_KEY,
              text: keyword,
            },
          ]
        : []

    return [...pendingCreateOption, ...filtered]
  })

  const focusSearchInput = () => {
    inputRef?.current?.focus()
  }

  const renderSelectContent = () => {
    if (!state.open || disabled) return null
    const keyword = state.keyword.trim()

    if (isLoading) {
      return DEFAULT_LOADING
    }

    const filtered = filterOptions(isCreatable, options, filter, keyword, isValidNewOption)

    if (filtered?.length > 0) {
      return (
        <div
          ref={searchResultRef}
          className="no-scrollbar"
          style={{
            width: '100%',
            height:
              filtered.length + appendOptionsLength > maxOptionsLength ? `${maxOptionsLength * itemHeight}px` : 'auto',
            margin: '0 auto',
            overflow: 'auto',
          }}
        >
          <div style={{width: '100%', height: (filtered.length + appendOptionsLength) * itemHeight + 10}}>
            {filtered.map((o, i) => renderOption(o, i))}
            {appendOptionsLength > 0 &&
              triggerAppendOptions({
                onHideSelect: () => setState({...state, open: false}),
              })}
          </div>
        </div>
      )
    }

    return (
      <>
        <div className="text-sm text-gray-400">
          {notFoundContent ? notFoundContent({context: state, setItem: item => onSelect(item)}) : 'No result'}
        </div>
        {appendOptionsLength > 0 &&
          triggerAppendOptions({
            onHideSelect: () => setState({...state, open: false}),
          })}
      </>
    )
  }

  const getSearchPlaceholder = () => {
    if (isMultiple) {
      if (state.value.length > 0) {
        return ''
      }

      return placeholder
    }

    const value = state.value || null
    if (!value || typeof value.text !== 'string') {
      return placeholder
    }

    return value.text
  }

  const onClear = e => {
    e.stopPropagation()
    const {keyword} = state
    focusSearchInput()

    if (keyword) {
      resetKeyword()
      return
    }

    if (isMultiple) {
      const value = []
      setState({
        ...state,
        value,
      })
      if (onChange) {
        onChange(value)
      }
    } else {
      const value = null
      setState({
        ...state,
        value,
      })
      if (onChange) {
        onChange(value)
      }
    }
  }

  const notEmpty = isMultiple ? Array.isArray(state.value) && state.value.length > 0 : state.value
  const showClear = isClearable && !disabled && notEmpty

  return (
    <button
      ref={triggerRef}
      type="button"
      className={`flex overflow-hidden justify-between items-center focus:outline-none text-sm mt-3 mb-1 bg-white border-2 border-gray-100 ${
        state.open ? 'is-active' : ''
      } ${customContainerClassName?.length > 0 ? customContainerClassName : 'rounded-lg h-12 w-full px-3'}`}
      tabIndex={disabled ? undefined : 0}
      style={{width: popupWidth ?? width, maxWidth: popupWidth ?? width}}
      onClick={() => {
        setState({
          ...state,
          open: !state.open,
        })
      }}
      onKeyDown={e => {
        if (!disabled) {
          switch (e.key) {
            case 'ArrowDown': {
              if (!state.open) {
                setState({
                  ...state,
                  open: true,
                })
                focusSearchInput()
              }

              break
            }

            default:
              break
          }
        }
      }}
    >
      {renderValue()}
      <div className="items-center flex">
        {showClear && (
          <span className="px-1 mt-0 text-sm" onClick={onClear}>
            <strong>clear</strong>
          </span>
        )}

        <span className="text-2xs">
          <i className={`fas fa-angle-${state.open ? 'up' : 'down'}`} />
        </span>
      </div>
      {state.open &&
        ReactDOM.createPortal(
          <div className={`${state.open ? '' : 'hidden'}`}>
            <div
              ref={popupRef}
              className="bg-white w-full flex-1 rounded-md p-2"
              style={{pointerEvents: 'auto', display: state.open ? 'auto' : 'none', zIndex: 100}}
            >
              <Search
                ref={inputRef}
                controlName={controlName || uniqueId()}
                isOpen={state.open}
                placeholder={getSearchPlaceholder()}
                value={keyword}
                inputType={inputType}
                onChange={handleKeywordChange}
                onIndexChange={onIndexChange}
                onEnter={selectCurrentIndex}
                onLostFocus={() => setState({...state, open: false, keyword: ''})}
              />

              {state.open && renderSelectContent()}
            </div>
          </div>,
          document.body // eslint-disable-line no-undef
        )}
    </button>
  )
}

Select.defaultProps = {
  isEqual: defaultIsEqual,
  filter: defaultFilter,
  isValidNewOption: defaultIsValidNewOption,
  highlight: defaultHighlight,
  width: 300,
  isMultiple: false,
  isClearable: false,
  isLoading: false,
  optionHeight: 30.25,
  maxOptionsLength: MAX_OPTION_LENGHT,
  appendOptionsLength: 0,
  isSelectionStateRemember: true,
  className: '',
  customContainerClassName: '',
  inputType: 'text',
  isDropdownExpandable: true,
}

Select.propTypes = {
  customContainerClassName: PropTypes.string,
  inputType: PropTypes.string,
  className: PropTypes.string,
  controlName: PropTypes.string,
  onChange: PropTypes.func,
  onCreate: PropTypes.func,
  isEqual: PropTypes.func,
  filter: PropTypes.func,
  isValidNewOption: PropTypes.func,
  highlight: PropTypes.func,
  appendOptionsLength: PropTypes.number,
  isDropdownExpandable: PropTypes.bool,

  width: PropTypes.oneOfType([
    PropTypes.number, // 300 (px)
    PropTypes.string, // "100%"
  ]),

  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  isSelectionStateRemember: PropTypes.bool,
  placeholder: PropTypes.string,
  isCreatable: PropTypes.bool,
  isMultiple: PropTypes.bool,
  isClearable: PropTypes.bool,
  isLoading: PropTypes.bool,
  optionHeight: PropTypes.number,
  maxOptionsLength: PropTypes.number,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
}

export default Select
