import React from 'react'
import * as PropTypes from 'prop-types'

const iconSize = {
  '1x': {circleSize: 'w-6 h-6', iconSize: 'xs'}, // Temp
  '2x': {circleSize: 'w-9 h-9', iconSize: 'sm'}, // Temp
  '3x': {circleSize: 'w-56 h-56', iconSize: 'base'}, // Temp
  '4x': {circleSize: 'w-12 h-12', iconSize: 'md'},
}

const CustomIcon = ({icon, size, className, bgColor, iconColor}) => {
  return (
    <div
      className={`${className} ${iconSize[size].circleSize} rounded-full bg-${bgColor} flex justify-center items-center`}
    >
      <i className={`${icon} text-${iconColor} text-${iconSize[size].iconSize}`} />
    </div>
  )
}

CustomIcon.defaultProps = {
  size: '3x',
  bgColor: 'secondarygray',
  iconColor: 'white',
}

CustomIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['1x', '2x', '3x', '4x', '5x', '56rem']),
  className: PropTypes.string,
  bgColor: PropTypes.string,
  iconColor: PropTypes.string,
}

export default CustomIcon
