import countryList from './countries'

export const countries = countryList

export const allCountries = {}
allCountries.mapping = countries.reduce((acc, obj) => ({...acc, [obj.countryCode]: obj.countryNameEn}), {})
allCountries.selectArrayOptions = Object.keys(allCountries.mapping)
  .filter(k => k)
  .map(k => ({
    key: k,
    text: allCountries.mapping[k],
  }))

export const countryCallingCodesMapping = countries.reduce(
  (acc, obj) => ({...acc, [obj.countryCallingCode]: obj.countryCallingCode}),
  {}
)
export const countryCallingCodes = {}
countryCallingCodes.selectArrayOptions = Object.keys(countryCallingCodesMapping)
  .filter(k => k)
  .map(k => ({key: k, text: k}))
countryCallingCodes.mapping = countryCallingCodes

export const supportedCurrencies = {}
supportedCurrencies.raw = {
  AUD: {
    name: 'AUD',
    value: 'AUD',
    sign: '$',
    flag: '🇦🇺',
  },
  PHP: {
    name: 'PHP',
    value: 'PHP',
    sign: '₱',
    flag: '🇵🇭',
  },
}
supportedCurrencies.mapping = {...supportedCurrencies.raw}
supportedCurrencies.selectArrayOptions = Object.keys(supportedCurrencies.mapping)
  .map(k => ({
    text: supportedCurrencies.mapping[k].name,
    key: supportedCurrencies.mapping[k].value,
    flag: supportedCurrencies.mapping[k].flag,
  }))
  .sort((a, b) => a.text.localeCompare(b.text))
supportedCurrencies.enumMapping = supportedCurrencies.selectArrayOptions.reduce(
  (acc, obj) => ({...acc, [obj.key]: obj.text}),
  {}
)

export const fieldType = {}
fieldType.raw = {
  SELECT: 'SELECT',
  SHORT_TEXT: 'SHORT_TEXT',
  LONG_TEXT: 'LONG_TEXT',
  NUMBER: 'NUMBER',
  DATE_PICKER: 'DATE_PICKER',
  MULTIPLE_SELECT: 'MULTIPLE_SELECT',
  PHONE_NUMBER: 'PHONE_NUMBER',
  FILE_UPLOAD: 'FILE_UPLOAD',
  HIDDEN_FORM: 'HIDDEN_FORM',
}
fieldType.mapping = {...fieldType.raw}

export const fileExtensions = {}
fileExtensions.raw = {
  IMAGE: ['.jpg', '.bmp', '.jpeg', '.png', '.heic', '.svg', '.webp', '.tiff'],
}
fileExtensions.mapping = {...fileExtensions.raw}

export const walletBrands = {}
walletBrands.raw = {
  paymaya: {
    name: 'Paymaya',
    imageUrl: 'https://upload.wikimedia.org/wikipedia/commons/9/9a/PayMaya_Logo.png',
  },
  gcash: {
    name: 'Gcash',
    imageUrl: 'https://www.xendit.co/wp-content/uploads/2021/04/Gcash-banner-1.png',
  },
  paypal: {
    name: 'Paypal',
    imageUrl: 'https://sm.pcmag.com/pcmag_ap/review/p/paypal/paypal_g2tp.png',
  },
}
walletBrands.mapping = {...walletBrands.raw}
walletBrands.selectArrayOptions = Object.keys(walletBrands.mapping)
  .map(k => ({
    text: walletBrands.mapping[k]?.name,
    key: k,
    imageUrl: walletBrands.mapping[k]?.imageUrl,
  }))
  .sort((a, b) => a.text.localeCompare(b.text))

walletBrands.enumMapping = walletBrands.selectArrayOptions.reduce((acc, obj) => ({...acc, [obj.key]: obj.key}), {})
