import * as fakeData from './fake-data'
// This is a temp file for faking api calls

const fakeApiLoad = (url, data) =>
  new Promise((resolve, reject) => {
    if (url.endsWith('no-network')) {
      reject(new Error('no network'))
    }

    if (url.endsWith('error')) {
      resolve({status: 500, error: {message: 'Internal server error'}})
    }

    let response

    // eslint-disable-next-line default-case
    switch (url) {
      case '/people-you-may-know':
        response = fakeData.peopleYouMayKnow
        break
      case '/recipients':
        response = fakeData.friends
        break
      case '/user/notifications':
        response = fakeData.userNotification
        break
    }

    resolve(response)
  })

export function peopleYouMayKnowGet() {
  return fakeApiLoad('/people-you-may-know')
}

export function recipientsGet() {
  return fakeApiLoad('/recipients')
}

export function userNotification() {
  return fakeApiLoad('/user/notifications')
}
