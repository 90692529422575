import ReactDOM from 'react-dom'
import React from 'react'
import {useForm, Controller} from 'react-hook-form'
import Button from '../../../components/elements/button'
import {useSelector, useDispatch} from 'react-redux'
import * as util from '../../../lib/util'
import Avatar from '../../../components/elements/avatar'
import * as PropTypes from 'prop-types'
import {humanFileSize} from '../../../lib/util'
import {useDropzone} from 'react-dropzone'
import * as constants from '../../../lib/constants'
import * as api from '../../../lib/api'

const Uploader = ({acceptedFileTypes, acceptedMaxFileSize, onUpload, previewImageUrl, dummyImageUrl}) => {
  const [imageFile, setImageFile] = React.useState(previewImageUrl || dummyImageUrl)
  const dispatch = useDispatch()

  const processDropFiles = React.useCallback(acceptedFiles => {
    const image = acceptedFiles?.[0]

    if (image) {
      setImageFile(URL.createObjectURL(image))
      onUpload(image)
    }
  }, [])

  const processFileValidation = file => {
    const extension = file.name.split('.').pop()
    const allowedFile = acceptedFileTypes.find(f => f === `.${extension}`.toLowerCase())

    if (!allowedFile) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{detail: `${file.name} is invalid`, type: 'danger'}],
      })
      return {
        code: 'invalid-file-type',
        message: 'Invalid file type',
      }
    }

    if (file.size > acceptedMaxFileSize) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: [
          {
            detail: `${file.name} is too large. Maximum allowed size is: ${humanFileSize(acceptedMaxFileSize)}`,
            type: 'danger',
          },
        ],
      })
      return {
        code: 'invalid-file-type',
        message: 'Invalid file type',
      }
    }

    return null
  }

  const {getRootProps, getInputProps, acceptedFiles} = useDropzone({
    onDrop: processDropFiles,
    accept: acceptedFileTypes,
    multiple: false,
    validator: processFileValidation,
  })

  const handleRemoveImage = () => {
    acceptedFiles.splice(0, 1)
    setImageFile(dummyImageUrl)
    onUpload(null)
  }

  return (
    <>
      <div className="flex justify-center">
        <button
          type="button"
          className="w-52 cursor-pointer focus:outline-none h-10 bg-primary rounded-md"
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <p className="text-base text-white font-semibold ">+ Upload Photo</p>
        </button>
      </div>

      <div className="flex justify-center mt-5 relative w-max mx-auto">
        <img src={imageFile} className="w-56 h-56 bg-primary absolute object-cover filter brightness-50" />
        <Avatar imageUrl={imageFile} size="56rem" className="z-10" />
        {imageFile !== dummyImageUrl && (
          <div className="absolute -top-2 -right-1 cursor-pointer z-10  w-5 h-5 rounded-full bg-white flex items-center justify-center">
            <i className="fas fa-times-circle text-red-900" onClick={handleRemoveImage} />
          </div>
        )}
      </div>
    </>
  )
}

const UploadAvatarModal = ({onCloseModal, acceptedFileTypes, acceptedMaxFileSize}) => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))
  const {firstname, lastname, avatar} = me
  const userName = `${firstname || ''} ${lastname || ''}`
  const {formState, handleSubmit, control} = useForm()
  const {isSubmitting, isDirty} = formState

  const dispatch = useDispatch()

  const handleUpload = async ({avatar}) => {
    const data = avatar ? util.generateFormData({avatar}) : {avatar}

    try {
      const updatedMe = await api.mePost(data)

      dispatch({type: 'ME_RECEIVED', data: updatedMe})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: [{type: 'success', detail: 'Changes successully saved'}],
      })

      onCloseModal()
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  return ReactDOM.createPortal(
    <div className="z-30 flex justify-center items-center w-screen h-screen bg-black absolute top-0 bg-opacity-30">
      <div className="top-0 w-1/3 bg-white shadow-md rounded-md">
        <div className="my-3">
          <div className="mx-5 flex justify-between">
            <div className="text-md font-bold">Update Profile Picture</div>
            <button type="button" onClick={() => onCloseModal()}>
              <i className="text-xl far fa-times-circle" />
            </button>
          </div>
          <div className="w-full- mx-5 border-b-2 mt-2" />
        </div>

        <Controller
          name="avatar"
          control={control}
          defaultValue={avatar}
          render={({field: {onChange, value}}) => (
            <Uploader
              acceptedFileTypes={acceptedFileTypes}
              acceptedMaxFileSize={acceptedMaxFileSize}
              previewImageUrl={value}
              dummyImageUrl={util.generateDummyAvatarUrl(userName)}
              onUpload={file => {
                onChange(file)
              }}
            />
          )}
        />

        <div className="flex justify-end mx-5 mt-12">
          <div className="flex w-28">
            <Button
              isLoading={isSubmitting}
              isDisabled={!isDirty}
              type="button"
              title="Update avatar"
              className="mx-1"
              size="small"
              color="primary"
              onClick={handleSubmit(handleUpload)}
            />
          </div>
        </div>
      </div>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

UploadAvatarModal.defaultProps = {
  acceptedFileTypes: [...constants.fileExtensions.mapping.IMAGE],
  acceptedMaxFileSize: 1024 * 10000,
  onCloseModal: () => {},
}

UploadAvatarModal.propTypes = {
  onCloseModal: PropTypes.func,
}

export default UploadAvatarModal
