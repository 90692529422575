import React from 'react'
import * as PropTypes from 'prop-types'
import {useDispatch, useSelector} from 'react-redux'

const typeData = {
  danger: {
    color: 'red',
    icon: 'fas fa-exclamation-circle',
  },
  success: {
    color: 'green',
    icon: 'fas fa-check-circle',
  },
  warning: {
    color: 'yellow',
    icon: 'fas fa-exclamation-triangle',
  },
  info: {
    color: 'blue',
    icon: 'fas fa-question-circle',
  },
}

const Toast = ({type, detail, duration, count, id}) => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    const timer = setTimeout(clearToast, duration)
    return () => {
      clearTimeout(timer)
    }
  }, [id])

  const clearToast = () => {
    dispatch({
      type: 'TOAST_REMOVED',
      data: id,
    })
  }

  if (!type) return null

  const toastData = typeData[type]

  if (!toastData) return null

  return (
    <div
      className={`z-50 w-1/3 h-12 absolute top-0 left-9 right-0 mx-auto text-align-center bg-${toastData.color}-500 rounded-md py-3 px-3 flex justify-center items-center`}
      style={{top: 10 + count * 55}}
    >
      <i className={`${toastData.icon} text-${toastData.color}-900 mr-2 left-5`} />

      <div className="w-5/6">
        <p className={`text-${toastData.color}-900 text-base`}>{detail}</p>
      </div>

      <i
        className={`far fa-times-circle text-${toastData.color}-900 absolute top-2 right-2 cursor-pointer`}
        onClick={() => clearToast}
      />
    </div>
  )
}

Toast.defaultProps = {
  duration: 2500,
  type: null,
}

Toast.propTypes = {
  detail: PropTypes.string.isRequired,
  duration: PropTypes.number,
  type: PropTypes.oneOf(['danger', 'success', 'warning', 'info', 'link']),
}

const Toasts = () => {
  const toasts = useSelector(state => state.toasts)
  return toasts.map((toast, index) => <Toast key={index} count={index} {...toast} />)
}

export default Toasts
