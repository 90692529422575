import React from 'react'
import {useDispatch} from 'react-redux'
import {Link} from 'react-router-dom'
import * as api from '../../lib/api'

const FetchUserData = () => {
  const dispatch = useDispatch()

  React.useEffect(() => {
    fetchUser()
  }, [])

  const fetchUser = async () => {
    try {
      const me = await api.meGet()
      dispatch({type: 'ME_RECEIVED', data: me})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
      // eslint-disable-next-line no-undef
      localStorage.setItem('thankfully:context', JSON.stringify({accessToken: null, me: null}))
      dispatch({type: 'LOGOUT'})
    }
  }

  return (
    <div className="flex flex-col w-full h-full justify-center items-center bg-primary rounded-br-4xl">
      <i className="text-5xl text-white fas fa-spinner fa-pulse" />
      <p className="text-white text-4xl font-bold mt-2">Fetching your details</p>

      <p className="text-md mb-8 mt-2 text-white">Please hang tight! 🎣</p>

      <Link to="/">
        <p className="text-sm">
          <span className="underline cursor-pointer font-semibold text-primary">Start from homepage.</span>
        </p>
      </Link>
    </div>
  )
}

export default FetchUserData
