import React from 'react'
import {useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import PageContainer from '../../components/page-container'
import Header from './partials/header'
import Tabs from './partials/tabs'
import FriendsList from '../../components/friends-list'
import * as api from '../../lib/api'
import {HeaderSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'

const Friends = () => {
  const {me} = useSelector(state => ({
    me: state.me,
  }))
  const [isFetchingData, setIsFetchingData] = React.useState(true)
  const {id} = useParams()
  const dispatch = useDispatch()
  const isOwnUser = me.id === id

  React.useEffect(() => {
    fetchData()
  }, [])

  const fetchData = () => {
    if (isOwnUser) return fetchOwnUser()
    fetchOther()
  }

  const fetchOwnUser = async () => {
    try {
      const me = await api.meGet()
      dispatch({type: 'ME_RECEIVED', data: me})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    } finally {
      setIsFetchingData(false)
    }
  }

  const fetchOther = async () => {
    try {
      const user = await api.networkGetProfile(id)
      dispatch({
        type: 'USER_RECEIVED',
        data: {
          ...user,
          ...user.bio,
        },
      })
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    } finally {
      setIsFetchingData(false)
    }
  }

  if (isFetchingData)
    return (
      <PageContainer>
        <div className="w-full flex flex-col bg-white rounded-md shadow-md mt-7">
          <HeaderSkeletonLoader />
        </div>
      </PageContainer>
    )

  return (
    <PageContainer>
      <div className="w-full flex flex-col bg-white rounded-md shadow-md mt-6">
        <Header>
          <Tabs currentTab="friends" />
        </Header>
      </div>
      <FriendsList />
    </PageContainer>
  )
}

export default Friends
