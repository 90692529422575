import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'

const HiddenForm = ({control, controlName, defaultValue}) => {
  return <Controller name={controlName} control={control} defaultValue={defaultValue} render={() => null} />
}

HiddenForm.defaultProps = {
  defaultValue: '',
}

HiddenForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
}

export default HiddenForm
