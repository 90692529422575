import React from 'react'
import * as PropTypes from 'prop-types'
import {Controller} from 'react-hook-form'
import * as constants from '../../../lib/constants'
import Select from '../../elements/select'

const PhoneNumberForm = ({
  control,
  errors,
  controlName,
  label,
  options,
  placeholder,
  rules,
  defaultValue,
  isDisabled,
  style,
}) => {
  return (
    <div className="mb-3 w-full">
      {label && <p className="text-base font-semibold mb-2">{label}</p>}
      <div className="flex-row flex">
        <div className="w-14 mr-1 relative">
          <Controller
            name={`${controlName}.countryCode`}
            control={control}
            rules={rules}
            defaultValue={defaultValue?.countryCode}
            render={({field: {onChange, value}}) => (
              <Select
                key={value}
                width="100%"
                className={`${errors[controlName]?.countryCode ? 'border border-red-600' : ''}`}
                placeholder="+00"
                options={constants.countryCallingCodes.selectArrayOptions}
                value={
                  value
                    ? {
                        text: `+${value}`,
                        key: value,
                      }
                    : null
                }
                onChange={selectedOption => onChange(selectedOption.key)}
              />
            )}
          />
        </div>
        <div className="flex-1 ml-1 relative">
          <Controller
            name={`${controlName}.number`}
            control={control}
            rules={rules}
            defaultValue={defaultValue?.number}
            render={({field: {onChange, value}}) => (
              <>
                {value && <p className="absolute text-xs text-gray-500 top-2 z-10 left-1 top-1">{placeholder}</p>}
                <input
                  defaultValue={value}
                  readOnly={isDisabled}
                  className={`my-3 focus:outline-none  w-full text-base ${
                    value ? 'text-black' : 'text-gray-300'
                  } rounded-lg h-12 px-3 border-2 border-gray-100  ${
                    errors[controlName]?.number ? 'border border-red-600' : ''
                  } ${style}`}
                  type="number"
                  placeholder={placeholder}
                  onChange={e => onChange(e.target.value)}
                />
              </>
            )}
          />
          {errors?.[controlName]?.number && (
            <p className="text-xs text-red-600 pl-2">{errors?.[controlName]?.number?.message}</p>
          )}
        </div>
      </div>
    </div>
  )
}

PhoneNumberForm.defaultProps = {
  errors: {},
  label: null,
  options: [],
  placeholder: '',
  rules: {},
  defaultValue: null,
  isDisabled: false,
  style: '',
}

PhoneNumberForm.propTypes = {
  control: PropTypes.object.isRequired,
  controlName: PropTypes.string.isRequired,
  errors: PropTypes.object,
  label: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.any,
  isDisabled: PropTypes.bool,
  style: PropTypes.string,
}

export default PhoneNumberForm
