import React from 'react'
import ReactDOM from 'react-dom'
import {useDispatch, useSelector} from 'react-redux'

const ImageViewer = props => {
  const dispatch = useDispatch()
  const {imageViewerData} = useSelector(state => ({
    imageViewerData: state.imageViewerData,
  }))
  const {isOpen, viewIndex, images} = imageViewerData
  const [state, setState] = React.useState({
    viewingIndex: undefined,
    isImageLoading: true,
  })

  React.useEffect(() => {
    if (isOpen && images?.length > 0) setState({...state, viewingIndex: viewIndex})
  }, [isOpen, viewIndex])

  const onToggleClose = () => {
    setState({...state, isImageLoading: false, viewingIndex: undefined})
    dispatch({type: 'IMAGE_VIEWER_DATA_RECEIVED', data: {}})
  }

  const handleViewIndexChange = viewingIndex => {
    setState({...state, isImageLoading: true, viewingIndex})
  }

  if (!isOpen || images?.length === 0 || state.viewingIndex === undefined) return null

  return ReactDOM.createPortal(
    <div className="absolute w-full h-full z-10 top-0 z-20 bg-gray-800 bg-opacity-70 flex items-center justify-center p-5">
      <button type="button" className="cursor-pointer absolute top-10 right-10" onClick={onToggleClose}>
        <i className="text-2xl far fa-times-circle text-white" />
      </button>
      <button
        type="button"
        disabled={state.viewingIndex === 0}
        className="cursor-pointer"
        onClick={() => handleViewIndexChange(state.viewingIndex - 1)}
      >
        <i className="text-xl fas fa-arrow-circle-left text-white" />
      </button>
      <div className="flex flex-1 items-center justify-center">
        {state.isImageLoading && (
          <p className="icon text-white">
            <i className="fa-3x fas fa-spinner fa-spin" />
          </p>
        )}

        <img
          className={`w-26 h-26 object-contain ${state.isImageLoading ? 'hidden' : ''}`}
          src={images[state.viewingIndex]}
          onLoad={() => setState({...state, isImageLoading: false})}
        />
      </div>
      <button
        type="button"
        disabled={state.viewingIndex === images.length - 1}
        className="cursor-pointer"
        onClick={() => handleViewIndexChange(state.viewingIndex + 1)}
      >
        <i className="text-xl fas fa-arrow-circle-right text-white" />
      </button>
    </div>,
    document.body // eslint-disable-line no-undef
  )
}

export default ImageViewer
