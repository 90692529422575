import React from 'react'

const Tag = ({item, onRemove, renderValue, idx}) => {
  if (!item) return null

  return (
    <div className={`tags has-addons ${idx > 0 ? 'ml-1' : ''}`}>
      {renderValue ? renderValue(item) : <span className="tag">{item.text}</span>}
      <span
        className="tag is-delete is-small is-clickable"
        onClick={e => {
          e.stopPropagation()
          onRemove(item)
        }}
      />
    </div>
  )
}

export default React.memo(Tag)
