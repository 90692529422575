import React from 'react'
import {useForm} from 'react-hook-form'
import {useSelector, useDispatch} from 'react-redux'
import * as PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import * as util from '../../lib/util'
import Avatar from '../elements/avatar'
import * as api from '../../lib/api'
import FormItem from '../forms/form-item'
import * as constants from '../../lib/constants'

const Actions = ({post}) => {
  const dispatch = useDispatch()
  const [state, setState] = React.useState({
    isLiking: false,
    postData: post,
    comments: [],
    isFetchingComments: false,
  })

  const {isLiking, postData, isFetchingComments, comments} = state

  const handleLike = async () => {
    setState({...state, isLiking: true})

    try {
      const likeData = await api.postLike(post.id)
      setState({
        ...state,
        isLiking: false,
        likeData,
        postData: {
          ...postData,
          numberOfLikes: postData.numberOfLikes + 1,
          likeId: likeData.id,
        },
      })
    } catch (error) {
      setState({...state, isLiking: false})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  const handleUnlike = async () => {
    setState({...state, isLiking: true})

    try {
      await api.postUnlike(post.id, postData.likeId || post.likeId)
      setState({
        ...state,
        isLiking: false,
        postData: {
          ...postData,
          numberOfLikes: postData.numberOfLikes - 1,
          likeId: null,
        },
      })
    } catch (error) {
      setState({...state, isLiking: false})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  const handleGetComments = async () => {
    setState({...state, isFetchingComments: true})

    try {
      const comments = await api.commentsGet(post.id)

      setState({
        ...state,
        comments,
        isFetchingComments: false,
      })
    } catch (error) {
      setState({...state, isFetchingComments: false})

      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  const formatLikeText = () => {
    const numberOfLikes = postData.likeId ? postData.numberOfLikes - 1 : postData.numberOfLikes

    let text = null

    if (postData.likeId) {
      text = `You ${numberOfLikes > 0 ? 'and' : 'liked this'}`
    }

    if (numberOfLikes > 0) text = `${text ? text : ''} ${numberOfLikes} other${numberOfLikes > 1 ? 's' : ''} liked this`

    return text || 'Like'
  }

  const handleAddComment = comment => {
    setState({
      ...state,
      comments: [...comments, comment],
      postData: {
        ...postData,
        numberOfComments: postData.numberOfComments + 1,
      },
    })

    handleGetComments()
  }

  return (
    <>
      <div className="border-b border-t border-gray-300">
        <div className="flex my-1 mx-3">
          <button
            type="button"
            disabled={isLiking}
            className="flex p-2 hover:bg-gray-100 cursor-pointer rounded-md items-center focus:outline-none"
            onClick={postData.likeId ? handleUnlike : handleLike}
          >
            <i
              className={`${isLiking ? 'fas fa-spinner fa-pulse' : 'far fa-thumbs-up'} ${
                postData.likeId ? 'text-primary fas' : ''
              }`}
            />
            <div className="mx-2 text-base">{formatLikeText()}</div>
          </button>

          <button
            type="button"
            disabled={isFetchingComments}
            className="flex p-2 hover:bg-gray-100 cursor-pointer rounded-md items-center focus:outline-none"
            onClick={handleGetComments}
          >
            <i className="ml-4 far fa-comment-alt" />
            <div className="mx-2 text-base">
              {postData.numberOfComments > 0 && comments.length === 0
                ? `See ${postData.numberOfComments} comment${postData.numberOfComments > 1 ? 's' : ''}`
                : 'Comment'}
            </div>
          </button>

          <div className="flex p-2 hover:bg-gray-100 cursor-pointer rounded-md items-center">
            <i className="ml-4  fas fa-share-alt" />
            <div className="mx-2 text-base"> Share</div>
          </div>
        </div>
      </div>

      <div className="flex flex-1 items-center justify-center flex-col my-3">
        {comments.length > 0 && (
          <div className="flex flex-1 flex-col w-full max-h-64 overflow-y-scroll">
            {comments.map((comment, index) => (
              <div key={index} className="flex flex-row my-1">
                <Link to={`/users/${comment.sender.id}/profile`}>
                  <Avatar
                    className="mx-2 hover:opacity-70"
                    imageUrl={
                      comment.sender.avatar
                        ? comment.sender.avatar
                        : util.generateDummyAvatarUrl(
                            `${comment.sender.firstName || ''} ${comment.sender.lastName || ''}`
                          )
                    }
                    size="2x"
                  />
                </Link>
                <div className="flex-1">
                  <Link to={`/users/${comment.sender.id}/profile`}>
                    <p className="hover:underline text-sm font-semibold cursor-pointer">
                      {comment.sender.firstName} {comment.sender.lastName}
                    </p>
                  </Link>
                  <div className="flex-1 bg-gray-100 rounded-md p-2 items-center">
                    <p className="text-sm">{comment.body}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}

        {isFetchingComments && <i className="fas fa-spinner fa-pulse text-center mt-1" />}
      </div>

      <CommentForm post={postData} onAddComment={handleAddComment} />
    </>
  )
}

const CommentForm = ({post, onAddComment}) => {
  const dispatch = useDispatch()
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  const {formState, handleSubmit, control, reset} = useForm()

  const {isSubmitting, errors} = formState

  const handleFormSubmit = async values => {
    try {
      const comment = await api.commentCreate(post.id, {comment: {...values}})
      onAddComment(comment)
      reset()
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  return (
    <div className="flex">
      <Avatar
        className="mx-2"
        imageUrl={me.avatar ? me.avatar : util.generateDummyAvatarUrl(`${me.firstName || ''} ${me.lastName || ''}`)}
        size="2x"
      />
      <form className="flex items-center flex-1" onSubmit={handleSubmit(handleFormSubmit)}>
        <FormItem
          fieldType={constants.fieldType.mapping.LONG_TEXT}
          controlName="body"
          errors={errors}
          control={control}
          rules={{required: true}}
          defaultValue=""
        />
        <button
          type="submit"
          disabled={isSubmitting}
          className="flex justify-center items-center mx-2 transform rotate-45 h-10 focus:outline-none"
          onClick={handleSubmit(handleFormSubmit)}
        >
          <i
            className={`${isSubmitting ? 'fas fa-spinner fa-pulse' : 'fas fa-location-arrow'} text-primary text-2xl`}
          />
        </button>
      </form>
    </div>
  )
}

const Post = ({post}) => {
  const dispatch = useDispatch()

  if (!post) return

  const {coinAmount, coinType, receiver, sender, body, image} = post

  const handleSetImageViewerData = () => {
    dispatch({type: 'IMAGE_VIEWER_DATA_RECEIVED', data: {isOpen: true, images: [image], viewIndex: 0}})
  }

  return (
    <div className="w-full max-3/4 flex flex-col bg-white rounded-md shadow-md p-4 mt-8">
      <div className="flex">
        <Link to={`/users/${sender.id}/profile`}>
          <Avatar
            imageUrl={
              sender.avatar
                ? sender.avatar
                : util.generateDummyAvatarUrl(`${sender.firstName || ''} ${sender.lastName || ''}`)
            }
            size="3x"
            className="hover:opacity-70 cursor-pointer"
          />
        </Link>

        <div className="ml-2 flex-1 pt-2">
          <div className="ml-1">
            <Link to={`/users/${sender.id}/profile`}>
              <p className="hover:underline text-base font-semibold inline-table cursor-pointer">
                {sender.firstName} {sender.lastName}
              </p>
            </Link>

            <p className="text-sm text-gray-400">2h ago</p>
          </div>

          <p className="ml-3 text-base mt-5">{body}</p>

          {image && (
            <div className="hover:opacity-90 cursor-pointer" onClick={handleSetImageViewerData}>
              <img src={image} className="w-full h-26 object-cover mt-2" />{' '}
            </div>
          )}

          <div className="flex flex-col w-full border-t broder-gray-300 my-3 pt-3">
            <div className="flex">
              <div className="relative items-center justify-center flex">
                <Link to={`/users/${sender.id}/profile`}>
                  <Avatar
                    imageUrl={
                      sender.avatar
                        ? sender.avatar
                        : util.generateDummyAvatarUrl(`${sender.firstName || ''} ${sender.lastName || ''}`)
                    }
                    className="hover:opacity-70 cursor-pointer"
                    size="3x"
                  />
                </Link>

                <div className="absolute h-9 w-9 rounded-full bg-primary -right-6 flex justify-center items-center">
                  <i className="fas fa-dollar-sign text-2xl text-white" />
                </div>
              </div>
              <div className="flex items-center ml-9">
                <div className="mr-5 flex">
                  <div className="w-10 mb-3 border-b-4 border-primary" />
                  <i className="fas fa-caret-right text-4xl text-primary relative top-1" />
                </div>

                <Link to={`/users/${receiver.id}/profile`}>
                  <Avatar
                    imageUrl={
                      receiver.avatar
                        ? receiver.avatar
                        : util.generateDummyAvatarUrl(`${receiver.firstName || ''} ${receiver.lastName || ''}`)
                    }
                    className="hover:opacity-70 cursor-pointer"
                    size="3x"
                  />
                </Link>

                <div className="ml-2">
                  <Link to={`/users/${receiver.id}/profile`}>
                    <p className="hover:underline text-base font-semibold cursor-pointer">
                      {receiver.firstName} {receiver.lastName}
                    </p>
                  </Link>

                  <p className="text-sm text-gray-400">+{coinAmount}</p>
                </div>
              </div>
            </div>
            <p className="text-sm text-gray-400 mt-3">via {coinType}</p>
          </div>
          <Actions post={post} />
        </div>
      </div>
    </div>
  )
}

Post.propTypes = {
  post: PropTypes.object.isRequired,
}

export default Post
