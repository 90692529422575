export default {
  api: {
    host: process.env.REACT_APP_API_URL,
  },
  environment: process.env.REACT_APP_ENVIRONMENT,
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  },
  providers: {
    facebook: {
      clientId: process.env.FACEBOOK_CLIENT_ID,
      redirectUrl: process.env.FACEBOOK_REDIRECT_URL,
    },
  },
  paypal: {
    clientId: process.env.REACT_APP_PAYPAL_CLIENT_ID,
  },
}
