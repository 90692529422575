import React from 'react'
import PageContainer from '../../components/page-container'

const UserSettings = () => {
  return (
    <PageContainer>
      <div className="flex flex-1 flex-col items-center justify-center">
        <p className="text-3xl font-bold mt-2">
          <span className="text-gray-500">User settings</span>
        </p>
      </div>
    </PageContainer>
  )
}

export default UserSettings
