import React from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {useForm} from 'react-hook-form'
import {useHistory, Link} from 'react-router-dom'
import {yupResolver} from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import FormItem from '../../components/forms/form-item'
import * as constants from '../../lib/constants'
import Button from '../../components/elements/button'
import * as api from '../../lib/api'
import config from '../../config'
// Import {signWithFacebook} from '../../lib/api'
// import FacebookLogin from 'react-facebook-login'

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required.').email('Not a valid email.'),
  password: Yup.string().required('Password is required.'),
})

const LoginForm = () => {
  const dispatch = useDispatch()

  const {formState, handleSubmit, control} = useForm({
    resolver: yupResolver(validationSchema),
    reValidateMode: 'onChange',
  })

  const {isSubmitting, errors} = formState

  const onSubmit = async values => {
    try {
      const accessToken = await api.signIn({user: {...values}})
      dispatch({type: 'USER_AUTH', data: accessToken})
    } catch (error) {
      dispatch({
        type: 'TOAST_RECEIVED',
        data: error.errors.map(e => ({...e, type: 'danger'})),
      })
    }
  }

  const onFacebookSubmit = () => {
    window.location.href = `https://www.facebook.com/v11.0/dialog/oauth?client_id=${config.providers.facebook.clientId}&redirect_uri=${config.providers.facebook.redirectUrl}`
  }

  return (
    <div className="items-center justify-center w-1/2">
      <p className="text-3xl font-bold text-white text-center">Login</p>

      <button
        type="button"
        className="w-full my-3 shadow-lg bg-blue-700 h-11 rounded-md text-md font-extralight text-white"
        onClick={onFacebookSubmit}
      >
        Continue with Facebook
      </button>

      <div className="flex-auto h-px bg-white my-2" />

      <div className="w-full mt-4">
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem
            fieldType={constants.fieldType.mapping.SHORT_TEXT}
            controlName="email"
            errors={errors}
            control={control}
            placeholder="Email"
            rules={{required: true}}
            defaultValue=""
          />

          <FormItem
            fieldType={constants.fieldType.mapping.SHORT_TEXT}
            controlName="password"
            errors={errors}
            control={control}
            placeholder="Password"
            rules={{required: true}}
            type="password"
            defaultValue=""
          />
          <Link to="/forgot-password">
            <p className="text-sm text-white">
              <span className="text-right text-sm text-white cursor-pointer font-semibold underline">
                Forgot password?
              </span>
            </p>
          </Link>
          <Button
            isLoading={isSubmitting}
            type="submit"
            title="Login"
            className="mt-2"
            onClick={handleSubmit(onSubmit)}
          />

          <Link to="/sign-up">
            <p className="text-sm text-white">
              <span>Don't have an account? </span>
              <span className="text-white underline cursor-pointer font-semibold">Register here.</span>
            </p>
          </Link>
        </form>
      </div>
    </div>
  )
}

const Login = () => {
  const history = useHistory()
  const {accessToken, me} = useSelector(state => ({
    accessToken: state.accessToken,
    me: state.me,
  }))

  const isPossiblyAuthenticated = Boolean(accessToken) || Boolean(me?.id)

  React.useEffect(() => {
    if (isPossiblyAuthenticated) history.push('/')
  }, [isPossiblyAuthenticated])

  if (isPossiblyAuthenticated) return null

  return (
    <div className="flex flex-row w-full h-full justify-between bg-gray-100">
      <div className="w-7/12 h-full bg-primary rounded-br-4xl flex justify-center items-center">
        <LoginForm />
      </div>

      <div className="flex-grow flex-1 flex flex-col bg-gray-100 items-center justify-center p-10 text-center">
        <img src="https://thankfully.me/img/favicon.png" alt="thankfully logo" className="w-26 h-20 object-contain " />
        <p className="text-5xl font-bold mb-8 mt-2">
          <span className="text-gray-500">thankfully</span>
          <span className="text-primary">.me</span>
        </p>

        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">shout-outs.</span>
        </p>

        <p className="text-4xl font-bold">
          <span className="text-primary">send </span>
          <span className="text-gray-500">coins.</span>
        </p>
      </div>

      <p className="absolute bottom-10 left-10 text-xs text-gray-100">thankfully 2021</p>
    </div>
  )
}

export default Login
