import React, {useEffect, useState} from 'react'
import * as api from '../../lib/api'
import {useParams} from 'react-router'
import {useDispatch, useSelector} from 'react-redux'
import Avatar from '../../components/elements/avatar'
import * as util from '../../lib/util'
import {FriendsListSkeletonLoader} from '../../components/skeleton-loaders/skeleton-loaders'
import {Link} from 'react-router-dom'

const FriendsList = ({hasFriendsChanged}) => {
  const {id} = useParams()
  const dispatch = useDispatch()
  const {me} = useSelector(state => ({
    me: state.me,
  }))

  const isOwnUser = me.id === id

  const [state, setState] = useState({
    isFetchingData: true,
    friendsList: [],
  })
  const [allFriends, setAllFriends] = useState({
    isFetchingAllFriends: true,
    allFriendsList: [],
  })

  const {friendsList, isFetchingData} = state
  const {allFriendsList, isFetchingAllFriends} = allFriends

  const [pageNumber, setPageNumber] = useState(0)

  useEffect(() => {
    fetchAllFriends()
  }, [hasFriendsChanged])

  useEffect(() => {
    if (isOwnUser) fetchData()
    else fetchNetworkFriends()
  }, [pageNumber, hasFriendsChanged])

  const fetchData = async () => {
    try {
      const friendsList = await api.networksGet({page: pageNumber, friends: true})
      setState({
        isFetchingData: false,
        friendsList,
      })
    } catch (error) {
      setState({isFetchingData: false, friendsList: []})
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  const fetchAllFriends = async () => {
    try {
      const allFriendsList = await api.networksGet({page: 'all', friends: true})
      setAllFriends({
        isFetchingAllFriends: false,
        allFriendsList,
      })
    } catch (error) {
      setAllFriends({isFetchingAllFriends: false})
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  const fetchNetworkFriends = async () => {
    try {
      const network = await api.networkGetProfile(id)
      setState({
        isFetchingData: false,
        friendsList: network.friends,
      })
    } catch (error) {
      setState({...state, isFetchingData: false})
      dispatch({
        type: 'TOAST_RECEIVED',
        data: {error, type: 'danger'},
      })
    }
  }

  if (isFetchingData || isFetchingAllFriends) return <FriendsListSkeletonLoader />

  const groupList = friend => {
    let index = 0
    const arrayLength = friend.length
    const rowItems = []
    const rowCount = 5

    for (index = 0; index < arrayLength; index += rowCount) {
      const group = friend.slice(index, index + rowCount)

      rowItems.push(group)
    }

    return rowItems
  }

  const generatePageNumbers = friendsCount => {
    const numberOfPages = [...new Array(Math.ceil(friendsCount / 10)).keys()]

    return numberOfPages.map(page => {
      return (
        <div
          key={page}
          className={`flex justify-center items-center cursor-pointer ${
            pageNumber === page ? 'w-6 h-6' : 'w-4 h-4'
          } bg-primary rounded-full mx-1`}
          onClick={() => {
            setPageNumber(page)
          }}
        >
          <div className="text-white text-xs">{page + 1}</div>
        </div>
      )
    })
  }

  const onHandlePageNav = direction => {
    if (direction === 'inc') {
      setPageNumber(prevPage => {
        return prevPage + 1
      })
      setState({
        ...state,
        isFetchingData: true,
      })
    } else {
      setPageNumber(prevPage => {
        return prevPage - 1
      })
      setState({
        ...state,
        isFetchingData: true,
      })
    }
  }

  const renderUserDetails = friend => {
    const userName = `${friend.firstName || ''} ${friend.lastName || ''}` // Temp
    return (
      <Link to={`/users/${friend.id}/profile`}>
        <div className="flex flex-col items-center w-28 h-48 ml-7 mb-1 cursor-pointer p-4 rounded-lg">
          <Avatar
            className="hover:opacity-75 "
            imageUrl={friend.avatar ? friend.avatar : util.generateDummyAvatarUrl(userName)}
            size="4x"
          />
          <div className="mt-4 flex flex-col items-center">
            <div className="hover:underline leading-4 text-base font-bold text-center">{`${friend.firstName} ${friend.lastName}`}</div>
            <div className="hover:underline text-sm">{`@${friend.id}`}</div>
          </div>
        </div>
      </Link>
    )
  }

  return (
    <div className="w-full bg-white rounded-md shadow-md mt-12 mb-10">
      <div className="text-base ml-5 mt-5 font-bold">{`All Friends (${
        isOwnUser ? allFriendsList.length : friendsList.length
      })`}</div>
      {(!isOwnUser && friendsList.length === 0) || (isOwnUser && allFriendsList.length === 0) ? (
        <div className="w-full flex justify-center items-center h-28">No Friends to show</div>
      ) : (
        <div className="flex w-full justify-center">
          <div className="mt-1">
            {groupList(friendsList).map((row, index) => {
              return (
                <div key={index} className="flex">
                  {row.map((friend, fIdx) => {
                    return <div key={fIdx}>{renderUserDetails(friend)}</div>
                  })}
                </div>
              )
            })}
          </div>
        </div>
      )}

      {allFriendsList.length > 10 && (
        <div className="flex w-full- mb-10 mx-20 justify-between">
          <div
            className={`${
              pageNumber === 0 && `opacity-0 cursor-default pointer-events-none`
            } h-8 w-8 flex items-center rounded-full justify-center text-xl bg-primary cursor-pointer`}
            onClick={() => {
              onHandlePageNav('dec')
            }}
          >
            <i className="text-white fas fa-chevron-left" />
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="flex items-center">{generatePageNumbers(allFriendsList.length)}</div>
            <div className="text-xs mt-2">{`${
              (pageNumber + 1) * 10 > allFriendsList.length ? allFriendsList.length : (pageNumber + 1) * 10
            }  of ${allFriendsList.length}`}</div>
          </div>

          <div
            className={`${
              pageNumber + 1 === generatePageNumbers(allFriendsList.length).length &&
              'opacity-0 cursor-default pointer-events-none'
            } h-8 w-8 flex items-center rounded-full justify-center text-xl bg-primary cursor-pointer`}
            onClick={() => {
              onHandlePageNav('inc')
            }}
          >
            <i className="text-white fas fa-chevron-right" />
          </div>
        </div>
      )}
    </div>
  )
}

export default FriendsList
