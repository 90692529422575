import React from 'react'
import * as PropTypes from 'prop-types'

const avatarSize = {
  '1x': 'w-6 h-6',
  '2x': 'w-9 h-9',
  '3x': 'w-14 h-14', // Temp
  '4x': 'w-16 h-16', // Temp
  '5x': 'w-20 h-20', // Temp
  '56rem': 'w-56 h-56', // Temp
}

const Avatar = ({imageUrl, size, className}) => {
  const [isLoading, setIsloading] = React.useState(true)

  React.useEffect(() => {
    setIsloading(true)
  }, [imageUrl])

  React.useEffect(() => {
    if (isLoading) {
      cacheImage(imageUrl)
    }
  }, [isLoading])

  const cacheImage = async imageUrl => {
    if (!imageUrl) return null

    const promise = new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const img = new Image()
      img.src = imageUrl
      img.addEventListener('load', resolve())
      img.addEventListener('error', reject())
    })

    promise.finally(() => setIsloading(false))
  }

  return (
    <div className={`${avatarSize[size]} object-cover rounded-full bg-gray-100 ${className} relative`}>
      {imageUrl && !isLoading && <img src={imageUrl} className="w-full h-full object-cover rounded-full absolute" />}
    </div>
  )
}

Avatar.defaultProps = {
  size: '2x',
  className: '',
}

Avatar.propTypes = {
  imageUrl: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['1x', '2x', '3x', '4x', '5x', '56rem']),
  className: PropTypes.string,
}

export default Avatar
