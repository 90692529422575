import React from 'react'
import * as PropTypes from 'prop-types'

const buttonStyleBySize = {
  small: 'h-8 rounded-md border',
  normal: 'h-12 rounded-md border-2',
}

const textStyleBySize = {
  small: 'text-base font-semibold',
  normal: 'text-lg font-bold',
}

const Button = ({
  color,
  isDisabled,
  radius,
  opacity,
  isLoading,
  loadingSize,
  title,
  isPrimary,
  isOutlined,
  children,
  width,
  className,
  onClick,
  size,
  type,
}) => {
  let content = null

  if (isLoading) {
    content = (
      <span>
        <i className="fas fa-spinner fa-spin" />
      </span>
    )
  } else if (title) {
    content = (isLoading && (
      <span>
        <i className="fas fa-spinner fa-spin" />
      </span>
    )) || (
      <p
        className={`${textStyleBySize[size]} text-${
          color ? (isOutlined ? color : 'white') : isOutlined ? 'primary' : 'white'
        }`}
      >
        {title}
      </p>
    )
  } else {
    content =
      (isLoading && (
        <span>
          <i className="fas fa-spinner fa-spin" />
        </span>
      )) ||
      children
  }

  return (
    <button
      type={type} // eslint-disable-line react/button-has-type
      disabled={isDisabled || isLoading}
      className={`focus:outline-none ${isDisabled || isLoading ? 'cursor-not-allowed' : ''} w-full my-3 ${
        buttonStyleBySize[size]
      } items-center justify-center  bg-${
        isDisabled
          ? 'gray-400'
          : isOutlined
          ? 'white'
          : color
          ? color
          : 'primaryDark transform hover:scale-105 motion-reduce:transform-none'
      } border-${
        isDisabled ? 'gray-300' : isOutlined ? `${color ? color : 'primaryDark'}` : color ? color : 'primaryDark'
      } ${className ? className : ''}`}
      onClick={onClick}
    >
      {content}
    </button>
  )
}

Button.defaultProps = {
  color: null,
  isDisabled: false,
  opacity: 0.8,
  isLoading: false,
  loadingSize: 'small',
  isPrimary: false,
  isOutlined: false,
  size: 'normal',
  type: 'button',
}

Button.propTypes = {
  type: PropTypes.string,
  color: PropTypes.string,
  isDisabled: PropTypes.bool,
  radius: PropTypes.number,
  opacity: PropTypes.number,
  isLoading: PropTypes.bool,
  loadingSize: PropTypes.oneOf(['small', 'large']),
  isPrimary: PropTypes.bool,
  isOutlined: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
}

export default Button
